import {
  Configuration,
  FillBlankApi,
  FillblankPostAddPostRequest,
} from "../../../generated/exercises";
import { exercisesServiceBaseURL } from "../../static_data/backend-base-url";

const getClient = (token: string) => {
  return new FillBlankApi(
    new Configuration({
      apiKey: `Bearer ${token}`,
      accessToken: token,
      basePath: exercisesServiceBaseURL,
    })
  );
};

export const getAllFillBlanks = async (
  token: string,
  studyLanguage?: string
) => {
  const client = getClient(token);

  return await client.fillblankGetGetAllGet(studyLanguage);
};

export const getFillBlank = async (token: string, id: string) => {
  const client = getClient(token);

  return await client.fillblankGetGetGet(id);
};

export const addFillBlank = async (
  token: string,
  data: FillblankPostAddPostRequest
) => {
  const client = getClient(token);

  return await client.fillblankPostAddPost(data);
};

export const deleteFillBlank = async (token: string, id: string) => {
  const client = getClient(token);

  return await client.fillblankDeleteRemoveDelete(id);
};
