/* tslint:disable */
/* eslint-disable */
/**
 * courses-service
 * Specification JSONs: [v2](/api-spec/v2), [v3](/api-spec/v3).
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CoursesDeleteDeleteDelete200Response
 */
export interface CoursesDeleteDeleteDelete200Response {
    /**
     * 
     * @type {string}
     * @memberof CoursesDeleteDeleteDelete200Response
     */
    'message'?: string;
    /**
     * 
     * @type {CoursesDeleteDeleteDelete200ResponseResult}
     * @memberof CoursesDeleteDeleteDelete200Response
     */
    'result'?: CoursesDeleteDeleteDelete200ResponseResult;
}
/**
 * 
 * @export
 * @interface CoursesDeleteDeleteDelete200ResponseResult
 */
export interface CoursesDeleteDeleteDelete200ResponseResult {
    /**
     * 
     * @type {string}
     * @memberof CoursesDeleteDeleteDelete200ResponseResult
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof CoursesDeleteDeleteDelete200ResponseResult
     */
    'deletedCount'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CoursesDeleteDeleteDelete200ResponseResult
     */
    'courseIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CoursesDeleteDeleteDeleteRequest
 */
export interface CoursesDeleteDeleteDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof CoursesDeleteDeleteDeleteRequest
     */
    ''?: string;
}
/**
 * 
 * @export
 * @interface CoursesGetGetAllGet200Response
 */
export interface CoursesGetGetAllGet200Response {
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetAllGet200Response
     */
    'message'?: string;
    /**
     * 
     * @type {Array<CoursesGetGetAllGet200ResponseCoursesInner>}
     * @memberof CoursesGetGetAllGet200Response
     */
    'courses'?: Array<CoursesGetGetAllGet200ResponseCoursesInner>;
}
/**
 * 
 * @export
 * @interface CoursesGetGetAllGet200ResponseCoursesInner
 */
export interface CoursesGetGetAllGet200ResponseCoursesInner {
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetAllGet200ResponseCoursesInner
     */
    '_id': string;
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetAllGet200ResponseCoursesInner
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetAllGet200ResponseCoursesInner
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetAllGet200ResponseCoursesInner
     */
    'studyLanguage': string;
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetAllGet200ResponseCoursesInner
     */
    'knownLanguage': string;
    /**
     * 
     * @type {number}
     * @memberof CoursesGetGetAllGet200ResponseCoursesInner
     */
    'currentSubCourseNumber': number;
    /**
     * 
     * @type {number}
     * @memberof CoursesGetGetAllGet200ResponseCoursesInner
     */
    'courseNumber': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CoursesGetGetAllGet200ResponseCoursesInner
     */
    'sections': Array<string>;
    /**
     * 
     * @type {Array<any>}
     * @memberof CoursesGetGetAllGet200ResponseCoursesInner
     */
    'screens': Array<any>;
    /**
     * 
     * @type {number}
     * @memberof CoursesGetGetAllGet200ResponseCoursesInner
     */
    '__v': number;
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetAllGet200ResponseCoursesInner
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface CoursesGetGetAllGetRequest
 */
export interface CoursesGetGetAllGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetAllGetRequest
     */
    'studyLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetAllGetRequest
     */
    'knownLanguage'?: string;
}
/**
 * 
 * @export
 * @interface CoursesGetGetCourseMenuGet200Response
 */
export interface CoursesGetGetCourseMenuGet200Response {
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetCourseMenuGet200Response
     */
    'message'?: string;
    /**
     * 
     * @type {CoursesGetGetCourseMenuGet200ResponseCourse}
     * @memberof CoursesGetGetCourseMenuGet200Response
     */
    'course'?: CoursesGetGetCourseMenuGet200ResponseCourse;
}
/**
 * 
 * @export
 * @interface CoursesGetGetCourseMenuGet200ResponseCourse
 */
export interface CoursesGetGetCourseMenuGet200ResponseCourse {
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetCourseMenuGet200ResponseCourse
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetCourseMenuGet200ResponseCourse
     */
    'title'?: string;
    /**
     * 
     * @type {Array<CoursesGetGetCourseMenuGet200ResponseCourseSectionsInner>}
     * @memberof CoursesGetGetCourseMenuGet200ResponseCourse
     */
    'sections'?: Array<CoursesGetGetCourseMenuGet200ResponseCourseSectionsInner>;
}
/**
 * 
 * @export
 * @interface CoursesGetGetCourseMenuGet200ResponseCourseSectionsInner
 */
export interface CoursesGetGetCourseMenuGet200ResponseCourseSectionsInner {
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetCourseMenuGet200ResponseCourseSectionsInner
     */
    '_id': string;
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetCourseMenuGet200ResponseCourseSectionsInner
     */
    'title': string;
    /**
     * 
     * @type {Array<CoursesGetGetCourseMenuGet200ResponseCourseSectionsInnerSectionsInner>}
     * @memberof CoursesGetGetCourseMenuGet200ResponseCourseSectionsInner
     */
    'sections': Array<CoursesGetGetCourseMenuGet200ResponseCourseSectionsInnerSectionsInner>;
}
/**
 * 
 * @export
 * @interface CoursesGetGetCourseMenuGet200ResponseCourseSectionsInnerSectionsInner
 */
export interface CoursesGetGetCourseMenuGet200ResponseCourseSectionsInnerSectionsInner {
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetCourseMenuGet200ResponseCourseSectionsInnerSectionsInner
     */
    '_id': string;
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetCourseMenuGet200ResponseCourseSectionsInnerSectionsInner
     */
    'title': string;
    /**
     * 
     * @type {Array<any>}
     * @memberof CoursesGetGetCourseMenuGet200ResponseCourseSectionsInnerSectionsInner
     */
    'sections': Array<any>;
}
/**
 * 
 * @export
 * @interface CoursesGetGetCourseMenuGet400Response
 */
export interface CoursesGetGetCourseMenuGet400Response {
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetCourseMenuGet400Response
     */
    'message'?: string;
    /**
     * 
     * @type {CoursesGetGetCourseMenuGet400ResponseError}
     * @memberof CoursesGetGetCourseMenuGet400Response
     */
    'error'?: CoursesGetGetCourseMenuGet400ResponseError;
}
/**
 * 
 * @export
 * @interface CoursesGetGetCourseMenuGet400ResponseError
 */
export interface CoursesGetGetCourseMenuGet400ResponseError {
    /**
     * 
     * @type {number}
     * @memberof CoursesGetGetCourseMenuGet400ResponseError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetCourseMenuGet400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetCourseMenuGet400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface CoursesGetGetGet200Response
 */
export interface CoursesGetGetGet200Response {
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetGet200Response
     */
    'message'?: string;
    /**
     * 
     * @type {CoursesGetGetGet200ResponseCourse}
     * @memberof CoursesGetGetGet200Response
     */
    'course'?: CoursesGetGetGet200ResponseCourse;
}
/**
 * 
 * @export
 * @interface CoursesGetGetGet200ResponseCourse
 */
export interface CoursesGetGetGet200ResponseCourse {
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetGet200ResponseCourse
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetGet200ResponseCourse
     */
    'title'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof CoursesGetGetGet200ResponseCourse
     */
    'sections'?: Array<any>;
    /**
     * 
     * @type {Array<CoursesGetGetGet200ResponseCourseScreensInner>}
     * @memberof CoursesGetGetGet200ResponseCourse
     */
    'screens'?: Array<CoursesGetGetGet200ResponseCourseScreensInner>;
    /**
     * 
     * @type {number}
     * @memberof CoursesGetGetGet200ResponseCourse
     */
    '__v'?: number;
    /**
     * 
     * @type {number}
     * @memberof CoursesGetGetGet200ResponseCourse
     */
    'courseNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetGet200ResponseCourse
     */
    'parent'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetGet200ResponseCourse
     */
    'rootParent'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetGet200ResponseCourse
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface CoursesGetGetGet200ResponseCourseScreensInner
 */
export interface CoursesGetGetGet200ResponseCourseScreensInner {
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetGet200ResponseCourseScreensInner
     */
    '_id': string;
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetGet200ResponseCourseScreensInner
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetGet200ResponseCourseScreensInner
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof CoursesGetGetGet200ResponseCourseScreensInner
     */
    'screenNumber': number;
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetGet200ResponseCourseScreensInner
     */
    'parentCourse': string;
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetGet200ResponseCourseScreensInner
     */
    '__t': string;
    /**
     * 
     * @type {number}
     * @memberof CoursesGetGetGet200ResponseCourseScreensInner
     */
    '__v': number;
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetGet200ResponseCourseScreensInner
     */
    'readingItemId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetGet200ResponseCourseScreensInner
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface CoursesGetGetGet400Response
 */
export interface CoursesGetGetGet400Response {
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetGet400Response
     */
    'message'?: string;
    /**
     * 
     * @type {CoursesGetGetGet400ResponseError}
     * @memberof CoursesGetGetGet400Response
     */
    'error'?: CoursesGetGetGet400ResponseError;
}
/**
 * 
 * @export
 * @interface CoursesGetGetGet400ResponseError
 */
export interface CoursesGetGetGet400ResponseError {
    /**
     * 
     * @type {number}
     * @memberof CoursesGetGetGet400ResponseError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetGet400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesGetGetGet400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface CoursesPatchEditCoursePatch200Response
 */
export interface CoursesPatchEditCoursePatch200Response {
    /**
     * 
     * @type {string}
     * @memberof CoursesPatchEditCoursePatch200Response
     */
    'message'?: string;
    /**
     * 
     * @type {CoursesPatchEditCoursePatch200ResponseCourse}
     * @memberof CoursesPatchEditCoursePatch200Response
     */
    'course'?: CoursesPatchEditCoursePatch200ResponseCourse;
}
/**
 * 
 * @export
 * @interface CoursesPatchEditCoursePatch200ResponseCourse
 */
export interface CoursesPatchEditCoursePatch200ResponseCourse {
    /**
     * 
     * @type {string}
     * @memberof CoursesPatchEditCoursePatch200ResponseCourse
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPatchEditCoursePatch200ResponseCourse
     */
    'title'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof CoursesPatchEditCoursePatch200ResponseCourse
     */
    'sections'?: Array<any>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CoursesPatchEditCoursePatch200ResponseCourse
     */
    'screens'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CoursesPatchEditCoursePatch200ResponseCourse
     */
    '__v'?: number;
    /**
     * 
     * @type {number}
     * @memberof CoursesPatchEditCoursePatch200ResponseCourse
     */
    'courseNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof CoursesPatchEditCoursePatch200ResponseCourse
     */
    'parent'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPatchEditCoursePatch200ResponseCourse
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface CoursesPatchEditCoursePatchRequest
 */
export interface CoursesPatchEditCoursePatchRequest {
    /**
     * 
     * @type {string}
     * @memberof CoursesPatchEditCoursePatchRequest
     */
    'courseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPatchEditCoursePatchRequest
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface CoursesPatchMoveSectionPatch200Response
 */
export interface CoursesPatchMoveSectionPatch200Response {
    /**
     * 
     * @type {string}
     * @memberof CoursesPatchMoveSectionPatch200Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface CoursesPatchMoveSectionPatch400Response
 */
export interface CoursesPatchMoveSectionPatch400Response {
    /**
     * 
     * @type {string}
     * @memberof CoursesPatchMoveSectionPatch400Response
     */
    'message'?: string;
    /**
     * 
     * @type {CoursesPatchMoveSectionPatch400ResponseError}
     * @memberof CoursesPatchMoveSectionPatch400Response
     */
    'error'?: CoursesPatchMoveSectionPatch400ResponseError;
}
/**
 * 
 * @export
 * @interface CoursesPatchMoveSectionPatch400ResponseError
 */
export interface CoursesPatchMoveSectionPatch400ResponseError {
    /**
     * 
     * @type {number}
     * @memberof CoursesPatchMoveSectionPatch400ResponseError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof CoursesPatchMoveSectionPatch400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPatchMoveSectionPatch400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface CoursesPatchMoveSectionPatchRequest
 */
export interface CoursesPatchMoveSectionPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof CoursesPatchMoveSectionPatchRequest
     */
    'courseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPatchMoveSectionPatchRequest
     */
    'direction'?: string;
}
/**
 * 
 * @export
 * @interface CoursesPatchSetCurrentScreenPatch200Response
 */
export interface CoursesPatchSetCurrentScreenPatch200Response {
    /**
     * 
     * @type {string}
     * @memberof CoursesPatchSetCurrentScreenPatch200Response
     */
    'message'?: string;
    /**
     * 
     * @type {CoursesPatchSetCurrentScreenPatch200ResponseCourse}
     * @memberof CoursesPatchSetCurrentScreenPatch200Response
     */
    'course'?: CoursesPatchSetCurrentScreenPatch200ResponseCourse;
}
/**
 * 
 * @export
 * @interface CoursesPatchSetCurrentScreenPatch200ResponseCourse
 */
export interface CoursesPatchSetCurrentScreenPatch200ResponseCourse {
    /**
     * 
     * @type {string}
     * @memberof CoursesPatchSetCurrentScreenPatch200ResponseCourse
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPatchSetCurrentScreenPatch200ResponseCourse
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPatchSetCurrentScreenPatch200ResponseCourse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPatchSetCurrentScreenPatch200ResponseCourse
     */
    'studyLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPatchSetCurrentScreenPatch200ResponseCourse
     */
    'knownLanguage'?: string;
    /**
     * 
     * @type {number}
     * @memberof CoursesPatchSetCurrentScreenPatch200ResponseCourse
     */
    'currentSubCourseNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof CoursesPatchSetCurrentScreenPatch200ResponseCourse
     */
    'courseNumber'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CoursesPatchSetCurrentScreenPatch200ResponseCourse
     */
    'sections'?: Array<string>;
    /**
     * 
     * @type {Array<any>}
     * @memberof CoursesPatchSetCurrentScreenPatch200ResponseCourse
     */
    'screens'?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof CoursesPatchSetCurrentScreenPatch200ResponseCourse
     */
    'dictionary'?: string;
    /**
     * 
     * @type {number}
     * @memberof CoursesPatchSetCurrentScreenPatch200ResponseCourse
     */
    '__v'?: number;
    /**
     * 
     * @type {string}
     * @memberof CoursesPatchSetCurrentScreenPatch200ResponseCourse
     */
    'currentScreen'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPatchSetCurrentScreenPatch200ResponseCourse
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface CoursesPatchSetCurrentScreenPatch400Response
 */
export interface CoursesPatchSetCurrentScreenPatch400Response {
    /**
     * 
     * @type {string}
     * @memberof CoursesPatchSetCurrentScreenPatch400Response
     */
    'message'?: string;
    /**
     * 
     * @type {CoursesPatchSetCurrentScreenPatch400ResponseError}
     * @memberof CoursesPatchSetCurrentScreenPatch400Response
     */
    'error'?: CoursesPatchSetCurrentScreenPatch400ResponseError;
}
/**
 * 
 * @export
 * @interface CoursesPatchSetCurrentScreenPatch400ResponseError
 */
export interface CoursesPatchSetCurrentScreenPatch400ResponseError {
    /**
     * 
     * @type {number}
     * @memberof CoursesPatchSetCurrentScreenPatch400ResponseError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof CoursesPatchSetCurrentScreenPatch400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPatchSetCurrentScreenPatch400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface CoursesPatchSetCurrentScreenPatchRequest
 */
export interface CoursesPatchSetCurrentScreenPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof CoursesPatchSetCurrentScreenPatchRequest
     */
    'courseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPatchSetCurrentScreenPatchRequest
     */
    'screenId'?: string;
}
/**
 * 
 * @export
 * @interface CoursesPostNewCourseTOCPost201Response
 */
export interface CoursesPostNewCourseTOCPost201Response {
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewCourseTOCPost201Response
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewCourseTOCPost201Response
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewCourseTOCPost201Response
     */
    'description'?: string;
    /**
     * 
     * @type {Array<CoursesPostNewCourseTOCPost201ResponseSectionsInner>}
     * @memberof CoursesPostNewCourseTOCPost201Response
     */
    'sections'?: Array<CoursesPostNewCourseTOCPost201ResponseSectionsInner>;
}
/**
 * 
 * @export
 * @interface CoursesPostNewCourseTOCPost201ResponseSectionsInner
 */
export interface CoursesPostNewCourseTOCPost201ResponseSectionsInner {
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewCourseTOCPost201ResponseSectionsInner
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewCourseTOCPost201ResponseSectionsInner
     */
    'title'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof CoursesPostNewCourseTOCPost201ResponseSectionsInner
     */
    'sections'?: Array<any>;
}
/**
 * 
 * @export
 * @interface CoursesPostNewCourseTOCPost400Response
 */
export interface CoursesPostNewCourseTOCPost400Response {
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewCourseTOCPost400Response
     */
    'message'?: string;
    /**
     * 
     * @type {CoursesPostNewCourseTOCPost400ResponseError}
     * @memberof CoursesPostNewCourseTOCPost400Response
     */
    'error'?: CoursesPostNewCourseTOCPost400ResponseError;
}
/**
 * 
 * @export
 * @interface CoursesPostNewCourseTOCPost400ResponseError
 */
export interface CoursesPostNewCourseTOCPost400ResponseError {
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewCourseTOCPost400ResponseError
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewCourseTOCPost400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewCourseTOCPost400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface CoursesPostNewCourseTOCPostRequest
 */
export interface CoursesPostNewCourseTOCPostRequest {
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewCourseTOCPostRequest
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewCourseTOCPostRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewCourseTOCPostRequest
     */
    'toc'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewCourseTOCPostRequest
     */
    'knownLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewCourseTOCPostRequest
     */
    'studyLanguage'?: string;
}
/**
 * 
 * @export
 * @interface CoursesPostNewScreenPost201Response
 */
export interface CoursesPostNewScreenPost201Response {
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewScreenPost201Response
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewScreenPost201Response
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof CoursesPostNewScreenPost201Response
     */
    'screenNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewScreenPost201Response
     */
    'parentCourse'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewScreenPost201Response
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewScreenPost201Response
     */
    '__t'?: string;
    /**
     * 
     * @type {number}
     * @memberof CoursesPostNewScreenPost201Response
     */
    '__v'?: number;
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewScreenPost201Response
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface CoursesPostNewScreenPost400Response
 */
export interface CoursesPostNewScreenPost400Response {
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewScreenPost400Response
     */
    'message'?: string;
    /**
     * 
     * @type {CoursesPostNewScreenPost400ResponseError}
     * @memberof CoursesPostNewScreenPost400Response
     */
    'error'?: CoursesPostNewScreenPost400ResponseError;
}
/**
 * 
 * @export
 * @interface CoursesPostNewScreenPost400ResponseError
 */
export interface CoursesPostNewScreenPost400ResponseError {
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewScreenPost400ResponseError
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewScreenPost400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewScreenPost400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface CoursesPostNewScreenPostRequest
 */
export interface CoursesPostNewScreenPostRequest {
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewScreenPostRequest
     */
    'courseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewScreenPostRequest
     */
    'data'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewScreenPostRequest
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface CoursesPostNewSectionPost200Response
 */
export interface CoursesPostNewSectionPost200Response {
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewSectionPost200Response
     */
    'message'?: string;
    /**
     * 
     * @type {CoursesPostNewSectionPost200ResponseNewSection}
     * @memberof CoursesPostNewSectionPost200Response
     */
    'newSection'?: CoursesPostNewSectionPost200ResponseNewSection;
}
/**
 * 
 * @export
 * @interface CoursesPostNewSectionPost200ResponseNewSection
 */
export interface CoursesPostNewSectionPost200ResponseNewSection {
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewSectionPost200ResponseNewSection
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewSectionPost200ResponseNewSection
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewSectionPost200ResponseNewSection
     */
    'studyLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewSectionPost200ResponseNewSection
     */
    'knownLanguage'?: string;
    /**
     * 
     * @type {number}
     * @memberof CoursesPostNewSectionPost200ResponseNewSection
     */
    'currentSubCourseNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof CoursesPostNewSectionPost200ResponseNewSection
     */
    'courseNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewSectionPost200ResponseNewSection
     */
    'parentNestedLevelName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewSectionPost200ResponseNewSection
     */
    'parent'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof CoursesPostNewSectionPost200ResponseNewSection
     */
    'sections'?: Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof CoursesPostNewSectionPost200ResponseNewSection
     */
    'screens'?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewSectionPost200ResponseNewSection
     */
    '_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof CoursesPostNewSectionPost200ResponseNewSection
     */
    '__v'?: number;
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewSectionPost200ResponseNewSection
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface CoursesPostNewSectionPostRequest
 */
export interface CoursesPostNewSectionPostRequest {
    /**
     * 
     * @type {string}
     * @memberof CoursesPostNewSectionPostRequest
     */
    'courseId'?: string;
}
/**
 * 
 * @export
 * @interface ScreensGetGetGet200Response
 */
export interface ScreensGetGetGet200Response {
    /**
     * 
     * @type {string}
     * @memberof ScreensGetGetGet200Response
     */
    'message'?: string;
    /**
     * 
     * @type {ScreensGetGetGet200ResponseScreen}
     * @memberof ScreensGetGetGet200Response
     */
    'screen'?: ScreensGetGetGet200ResponseScreen;
}
/**
 * 
 * @export
 * @interface ScreensGetGetGet200ResponseScreen
 */
export interface ScreensGetGetGet200ResponseScreen {
    /**
     * 
     * @type {string}
     * @memberof ScreensGetGetGet200ResponseScreen
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScreensGetGetGet200ResponseScreen
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScreensGetGetGet200ResponseScreen
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof ScreensGetGetGet200ResponseScreen
     */
    'screenNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof ScreensGetGetGet200ResponseScreen
     */
    'parentCourse'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScreensGetGetGet200ResponseScreen
     */
    '__t'?: string;
    /**
     * 
     * @type {number}
     * @memberof ScreensGetGetGet200ResponseScreen
     */
    '__v'?: number;
    /**
     * 
     * @type {string}
     * @memberof ScreensGetGetGet200ResponseScreen
     */
    'readingItemId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScreensGetGetGet200ResponseScreen
     */
    'mainParentId'?: string;
}
/**
 * 
 * @export
 * @interface ScreensGetGetGet400Response
 */
export interface ScreensGetGetGet400Response {
    /**
     * 
     * @type {string}
     * @memberof ScreensGetGetGet400Response
     */
    'message'?: string;
    /**
     * 
     * @type {ScreensGetGetGet400ResponseError}
     * @memberof ScreensGetGetGet400Response
     */
    'error'?: ScreensGetGetGet400ResponseError;
}
/**
 * 
 * @export
 * @interface ScreensGetGetGet400ResponseError
 */
export interface ScreensGetGetGet400ResponseError {
    /**
     * 
     * @type {number}
     * @memberof ScreensGetGetGet400ResponseError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ScreensGetGetGet400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScreensGetGetGet400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ScreensPatchEditScreenPatch200Response
 */
export interface ScreensPatchEditScreenPatch200Response {
    /**
     * 
     * @type {string}
     * @memberof ScreensPatchEditScreenPatch200Response
     */
    'message'?: string;
    /**
     * 
     * @type {ScreensPatchEditScreenPatch200ResponseScreen}
     * @memberof ScreensPatchEditScreenPatch200Response
     */
    'screen'?: ScreensPatchEditScreenPatch200ResponseScreen;
}
/**
 * 
 * @export
 * @interface ScreensPatchEditScreenPatch200ResponseScreen
 */
export interface ScreensPatchEditScreenPatch200ResponseScreen {
    /**
     * 
     * @type {string}
     * @memberof ScreensPatchEditScreenPatch200ResponseScreen
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScreensPatchEditScreenPatch200ResponseScreen
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScreensPatchEditScreenPatch200ResponseScreen
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof ScreensPatchEditScreenPatch200ResponseScreen
     */
    'screenNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof ScreensPatchEditScreenPatch200ResponseScreen
     */
    'parentCourse'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScreensPatchEditScreenPatch200ResponseScreen
     */
    '__t'?: string;
    /**
     * 
     * @type {number}
     * @memberof ScreensPatchEditScreenPatch200ResponseScreen
     */
    '__v'?: number;
    /**
     * 
     * @type {string}
     * @memberof ScreensPatchEditScreenPatch200ResponseScreen
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface ScreensPatchEditScreenPatch400Response
 */
export interface ScreensPatchEditScreenPatch400Response {
    /**
     * 
     * @type {string}
     * @memberof ScreensPatchEditScreenPatch400Response
     */
    'message'?: string;
    /**
     * 
     * @type {ScreensPatchEditScreenPatch400ResponseError}
     * @memberof ScreensPatchEditScreenPatch400Response
     */
    'error'?: ScreensPatchEditScreenPatch400ResponseError;
}
/**
 * 
 * @export
 * @interface ScreensPatchEditScreenPatch400ResponseError
 */
export interface ScreensPatchEditScreenPatch400ResponseError {
    /**
     * 
     * @type {number}
     * @memberof ScreensPatchEditScreenPatch400ResponseError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ScreensPatchEditScreenPatch400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScreensPatchEditScreenPatch400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ScreensPatchEditScreenPatchRequest
 */
export interface ScreensPatchEditScreenPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof ScreensPatchEditScreenPatchRequest
     */
    'screenId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScreensPatchEditScreenPatchRequest
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScreensPatchEditScreenPatchRequest
     */
    'title'?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary /courses/delete/delete
         * @param {CoursesDeleteDeleteDeleteRequest} coursesDeleteDeleteDeleteRequest 
         * @param {string} [courseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesDeleteDeleteDelete: async (coursesDeleteDeleteDeleteRequest: CoursesDeleteDeleteDeleteRequest, courseId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'coursesDeleteDeleteDeleteRequest' is not null or undefined
            assertParamExists('coursesDeleteDeleteDelete', 'coursesDeleteDeleteDeleteRequest', coursesDeleteDeleteDeleteRequest)
            const localVarPath = `/courses/delete/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (courseId !== undefined) {
                localVarQueryParameter['courseId'] = courseId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(coursesDeleteDeleteDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /courses/get/getAll
         * @param {CoursesGetGetAllGetRequest} coursesGetGetAllGetRequest 
         * @param {string} [studyLanguage] 
         * @param {string} [knownLanguage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetGetAllGet: async (coursesGetGetAllGetRequest: CoursesGetGetAllGetRequest, studyLanguage?: string, knownLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'coursesGetGetAllGetRequest' is not null or undefined
            assertParamExists('coursesGetGetAllGet', 'coursesGetGetAllGetRequest', coursesGetGetAllGetRequest)
            const localVarPath = `/courses/get/getAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (studyLanguage !== undefined) {
                localVarQueryParameter['studyLanguage'] = studyLanguage;
            }

            if (knownLanguage !== undefined) {
                localVarQueryParameter['knownLanguage'] = knownLanguage;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(coursesGetGetAllGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /courses/get/getCourseMenu
         * @param {string} [courseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetGetCourseMenuGet: async (courseId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/courses/get/getCourseMenu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (courseId !== undefined) {
                localVarQueryParameter['courseId'] = courseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /courses/get/get
         * @param {string} [courseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetGetGet: async (courseId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/courses/get/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (courseId !== undefined) {
                localVarQueryParameter['courseId'] = courseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /courses/patch/editCourse
         * @param {CoursesPatchEditCoursePatchRequest} coursesPatchEditCoursePatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesPatchEditCoursePatch: async (coursesPatchEditCoursePatchRequest: CoursesPatchEditCoursePatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'coursesPatchEditCoursePatchRequest' is not null or undefined
            assertParamExists('coursesPatchEditCoursePatch', 'coursesPatchEditCoursePatchRequest', coursesPatchEditCoursePatchRequest)
            const localVarPath = `/courses/patch/editCourse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(coursesPatchEditCoursePatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /courses/patch/moveSection
         * @param {CoursesPatchMoveSectionPatchRequest} coursesPatchMoveSectionPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesPatchMoveSectionPatch: async (coursesPatchMoveSectionPatchRequest: CoursesPatchMoveSectionPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'coursesPatchMoveSectionPatchRequest' is not null or undefined
            assertParamExists('coursesPatchMoveSectionPatch', 'coursesPatchMoveSectionPatchRequest', coursesPatchMoveSectionPatchRequest)
            const localVarPath = `/courses/patch/moveSection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(coursesPatchMoveSectionPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /courses/patch/setCurrentScreen
         * @param {CoursesPatchSetCurrentScreenPatchRequest} coursesPatchSetCurrentScreenPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesPatchSetCurrentScreenPatch: async (coursesPatchSetCurrentScreenPatchRequest: CoursesPatchSetCurrentScreenPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'coursesPatchSetCurrentScreenPatchRequest' is not null or undefined
            assertParamExists('coursesPatchSetCurrentScreenPatch', 'coursesPatchSetCurrentScreenPatchRequest', coursesPatchSetCurrentScreenPatchRequest)
            const localVarPath = `/courses/patch/setCurrentScreen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(coursesPatchSetCurrentScreenPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /courses/post/newCourse
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesPostNewCoursePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/courses/post/newCourse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /courses/post/newCourseTOC
         * @param {CoursesPostNewCourseTOCPostRequest} coursesPostNewCourseTOCPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesPostNewCourseTOCPost: async (coursesPostNewCourseTOCPostRequest: CoursesPostNewCourseTOCPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'coursesPostNewCourseTOCPostRequest' is not null or undefined
            assertParamExists('coursesPostNewCourseTOCPost', 'coursesPostNewCourseTOCPostRequest', coursesPostNewCourseTOCPostRequest)
            const localVarPath = `/courses/post/newCourseTOC`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(coursesPostNewCourseTOCPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /courses/post/newScreen
         * @param {CoursesPostNewScreenPostRequest} coursesPostNewScreenPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesPostNewScreenPost: async (coursesPostNewScreenPostRequest: CoursesPostNewScreenPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'coursesPostNewScreenPostRequest' is not null or undefined
            assertParamExists('coursesPostNewScreenPost', 'coursesPostNewScreenPostRequest', coursesPostNewScreenPostRequest)
            const localVarPath = `/courses/post/newScreen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(coursesPostNewScreenPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /courses/post/newSection
         * @param {CoursesPostNewSectionPostRequest} coursesPostNewSectionPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesPostNewSectionPost: async (coursesPostNewSectionPostRequest: CoursesPostNewSectionPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'coursesPostNewSectionPostRequest' is not null or undefined
            assertParamExists('coursesPostNewSectionPost', 'coursesPostNewSectionPostRequest', coursesPostNewSectionPostRequest)
            const localVarPath = `/courses/post/newSection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(coursesPostNewSectionPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /screens/get/get
         * @param {string} [screenId] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        screensGetGetGet: async (screenId?: string, type?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/screens/get/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (screenId !== undefined) {
                localVarQueryParameter['screenId'] = screenId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /screens/patch/editScreen
         * @param {ScreensPatchEditScreenPatchRequest} screensPatchEditScreenPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        screensPatchEditScreenPatch: async (screensPatchEditScreenPatchRequest: ScreensPatchEditScreenPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'screensPatchEditScreenPatchRequest' is not null or undefined
            assertParamExists('screensPatchEditScreenPatch', 'screensPatchEditScreenPatchRequest', screensPatchEditScreenPatchRequest)
            const localVarPath = `/screens/patch/editScreen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(screensPatchEditScreenPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary /courses/delete/delete
         * @param {CoursesDeleteDeleteDeleteRequest} coursesDeleteDeleteDeleteRequest 
         * @param {string} [courseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesDeleteDeleteDelete(coursesDeleteDeleteDeleteRequest: CoursesDeleteDeleteDeleteRequest, courseId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoursesDeleteDeleteDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesDeleteDeleteDelete(coursesDeleteDeleteDeleteRequest, courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /courses/get/getAll
         * @param {CoursesGetGetAllGetRequest} coursesGetGetAllGetRequest 
         * @param {string} [studyLanguage] 
         * @param {string} [knownLanguage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesGetGetAllGet(coursesGetGetAllGetRequest: CoursesGetGetAllGetRequest, studyLanguage?: string, knownLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoursesGetGetAllGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesGetGetAllGet(coursesGetGetAllGetRequest, studyLanguage, knownLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /courses/get/getCourseMenu
         * @param {string} [courseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesGetGetCourseMenuGet(courseId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoursesGetGetCourseMenuGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesGetGetCourseMenuGet(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /courses/get/get
         * @param {string} [courseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesGetGetGet(courseId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoursesGetGetGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesGetGetGet(courseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /courses/patch/editCourse
         * @param {CoursesPatchEditCoursePatchRequest} coursesPatchEditCoursePatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesPatchEditCoursePatch(coursesPatchEditCoursePatchRequest: CoursesPatchEditCoursePatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoursesPatchEditCoursePatch200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesPatchEditCoursePatch(coursesPatchEditCoursePatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /courses/patch/moveSection
         * @param {CoursesPatchMoveSectionPatchRequest} coursesPatchMoveSectionPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesPatchMoveSectionPatch(coursesPatchMoveSectionPatchRequest: CoursesPatchMoveSectionPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoursesPatchMoveSectionPatch200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesPatchMoveSectionPatch(coursesPatchMoveSectionPatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /courses/patch/setCurrentScreen
         * @param {CoursesPatchSetCurrentScreenPatchRequest} coursesPatchSetCurrentScreenPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesPatchSetCurrentScreenPatch(coursesPatchSetCurrentScreenPatchRequest: CoursesPatchSetCurrentScreenPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoursesPatchSetCurrentScreenPatch200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesPatchSetCurrentScreenPatch(coursesPatchSetCurrentScreenPatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /courses/post/newCourse
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesPostNewCoursePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesPostNewCoursePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /courses/post/newCourseTOC
         * @param {CoursesPostNewCourseTOCPostRequest} coursesPostNewCourseTOCPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesPostNewCourseTOCPost(coursesPostNewCourseTOCPostRequest: CoursesPostNewCourseTOCPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoursesPostNewCourseTOCPost201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesPostNewCourseTOCPost(coursesPostNewCourseTOCPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /courses/post/newScreen
         * @param {CoursesPostNewScreenPostRequest} coursesPostNewScreenPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesPostNewScreenPost(coursesPostNewScreenPostRequest: CoursesPostNewScreenPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoursesPostNewScreenPost201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesPostNewScreenPost(coursesPostNewScreenPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /courses/post/newSection
         * @param {CoursesPostNewSectionPostRequest} coursesPostNewSectionPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursesPostNewSectionPost(coursesPostNewSectionPostRequest: CoursesPostNewSectionPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoursesPostNewSectionPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursesPostNewSectionPost(coursesPostNewSectionPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /screens/get/get
         * @param {string} [screenId] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async screensGetGetGet(screenId?: string, type?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScreensGetGetGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.screensGetGetGet(screenId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /screens/patch/editScreen
         * @param {ScreensPatchEditScreenPatchRequest} screensPatchEditScreenPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async screensPatchEditScreenPatch(screensPatchEditScreenPatchRequest: ScreensPatchEditScreenPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScreensPatchEditScreenPatch200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.screensPatchEditScreenPatch(screensPatchEditScreenPatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary /courses/delete/delete
         * @param {CoursesDeleteDeleteDeleteRequest} coursesDeleteDeleteDeleteRequest 
         * @param {string} [courseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesDeleteDeleteDelete(coursesDeleteDeleteDeleteRequest: CoursesDeleteDeleteDeleteRequest, courseId?: string, options?: any): AxiosPromise<CoursesDeleteDeleteDelete200Response> {
            return localVarFp.coursesDeleteDeleteDelete(coursesDeleteDeleteDeleteRequest, courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /courses/get/getAll
         * @param {CoursesGetGetAllGetRequest} coursesGetGetAllGetRequest 
         * @param {string} [studyLanguage] 
         * @param {string} [knownLanguage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetGetAllGet(coursesGetGetAllGetRequest: CoursesGetGetAllGetRequest, studyLanguage?: string, knownLanguage?: string, options?: any): AxiosPromise<CoursesGetGetAllGet200Response> {
            return localVarFp.coursesGetGetAllGet(coursesGetGetAllGetRequest, studyLanguage, knownLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /courses/get/getCourseMenu
         * @param {string} [courseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetGetCourseMenuGet(courseId?: string, options?: any): AxiosPromise<CoursesGetGetCourseMenuGet200Response> {
            return localVarFp.coursesGetGetCourseMenuGet(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /courses/get/get
         * @param {string} [courseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetGetGet(courseId?: string, options?: any): AxiosPromise<CoursesGetGetGet200Response> {
            return localVarFp.coursesGetGetGet(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /courses/patch/editCourse
         * @param {CoursesPatchEditCoursePatchRequest} coursesPatchEditCoursePatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesPatchEditCoursePatch(coursesPatchEditCoursePatchRequest: CoursesPatchEditCoursePatchRequest, options?: any): AxiosPromise<CoursesPatchEditCoursePatch200Response> {
            return localVarFp.coursesPatchEditCoursePatch(coursesPatchEditCoursePatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /courses/patch/moveSection
         * @param {CoursesPatchMoveSectionPatchRequest} coursesPatchMoveSectionPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesPatchMoveSectionPatch(coursesPatchMoveSectionPatchRequest: CoursesPatchMoveSectionPatchRequest, options?: any): AxiosPromise<CoursesPatchMoveSectionPatch200Response> {
            return localVarFp.coursesPatchMoveSectionPatch(coursesPatchMoveSectionPatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /courses/patch/setCurrentScreen
         * @param {CoursesPatchSetCurrentScreenPatchRequest} coursesPatchSetCurrentScreenPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesPatchSetCurrentScreenPatch(coursesPatchSetCurrentScreenPatchRequest: CoursesPatchSetCurrentScreenPatchRequest, options?: any): AxiosPromise<CoursesPatchSetCurrentScreenPatch200Response> {
            return localVarFp.coursesPatchSetCurrentScreenPatch(coursesPatchSetCurrentScreenPatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /courses/post/newCourse
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesPostNewCoursePost(options?: any): AxiosPromise<void> {
            return localVarFp.coursesPostNewCoursePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /courses/post/newCourseTOC
         * @param {CoursesPostNewCourseTOCPostRequest} coursesPostNewCourseTOCPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesPostNewCourseTOCPost(coursesPostNewCourseTOCPostRequest: CoursesPostNewCourseTOCPostRequest, options?: any): AxiosPromise<CoursesPostNewCourseTOCPost201Response> {
            return localVarFp.coursesPostNewCourseTOCPost(coursesPostNewCourseTOCPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /courses/post/newScreen
         * @param {CoursesPostNewScreenPostRequest} coursesPostNewScreenPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesPostNewScreenPost(coursesPostNewScreenPostRequest: CoursesPostNewScreenPostRequest, options?: any): AxiosPromise<CoursesPostNewScreenPost201Response> {
            return localVarFp.coursesPostNewScreenPost(coursesPostNewScreenPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /courses/post/newSection
         * @param {CoursesPostNewSectionPostRequest} coursesPostNewSectionPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesPostNewSectionPost(coursesPostNewSectionPostRequest: CoursesPostNewSectionPostRequest, options?: any): AxiosPromise<CoursesPostNewSectionPost200Response> {
            return localVarFp.coursesPostNewSectionPost(coursesPostNewSectionPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /screens/get/get
         * @param {string} [screenId] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        screensGetGetGet(screenId?: string, type?: string, options?: any): AxiosPromise<ScreensGetGetGet200Response> {
            return localVarFp.screensGetGetGet(screenId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /screens/patch/editScreen
         * @param {ScreensPatchEditScreenPatchRequest} screensPatchEditScreenPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        screensPatchEditScreenPatch(screensPatchEditScreenPatchRequest: ScreensPatchEditScreenPatchRequest, options?: any): AxiosPromise<ScreensPatchEditScreenPatch200Response> {
            return localVarFp.screensPatchEditScreenPatch(screensPatchEditScreenPatchRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary /courses/delete/delete
     * @param {CoursesDeleteDeleteDeleteRequest} coursesDeleteDeleteDeleteRequest 
     * @param {string} [courseId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public coursesDeleteDeleteDelete(coursesDeleteDeleteDeleteRequest: CoursesDeleteDeleteDeleteRequest, courseId?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).coursesDeleteDeleteDelete(coursesDeleteDeleteDeleteRequest, courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /courses/get/getAll
     * @param {CoursesGetGetAllGetRequest} coursesGetGetAllGetRequest 
     * @param {string} [studyLanguage] 
     * @param {string} [knownLanguage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public coursesGetGetAllGet(coursesGetGetAllGetRequest: CoursesGetGetAllGetRequest, studyLanguage?: string, knownLanguage?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).coursesGetGetAllGet(coursesGetGetAllGetRequest, studyLanguage, knownLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /courses/get/getCourseMenu
     * @param {string} [courseId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public coursesGetGetCourseMenuGet(courseId?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).coursesGetGetCourseMenuGet(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /courses/get/get
     * @param {string} [courseId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public coursesGetGetGet(courseId?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).coursesGetGetGet(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /courses/patch/editCourse
     * @param {CoursesPatchEditCoursePatchRequest} coursesPatchEditCoursePatchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public coursesPatchEditCoursePatch(coursesPatchEditCoursePatchRequest: CoursesPatchEditCoursePatchRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).coursesPatchEditCoursePatch(coursesPatchEditCoursePatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /courses/patch/moveSection
     * @param {CoursesPatchMoveSectionPatchRequest} coursesPatchMoveSectionPatchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public coursesPatchMoveSectionPatch(coursesPatchMoveSectionPatchRequest: CoursesPatchMoveSectionPatchRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).coursesPatchMoveSectionPatch(coursesPatchMoveSectionPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /courses/patch/setCurrentScreen
     * @param {CoursesPatchSetCurrentScreenPatchRequest} coursesPatchSetCurrentScreenPatchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public coursesPatchSetCurrentScreenPatch(coursesPatchSetCurrentScreenPatchRequest: CoursesPatchSetCurrentScreenPatchRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).coursesPatchSetCurrentScreenPatch(coursesPatchSetCurrentScreenPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /courses/post/newCourse
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public coursesPostNewCoursePost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).coursesPostNewCoursePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /courses/post/newCourseTOC
     * @param {CoursesPostNewCourseTOCPostRequest} coursesPostNewCourseTOCPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public coursesPostNewCourseTOCPost(coursesPostNewCourseTOCPostRequest: CoursesPostNewCourseTOCPostRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).coursesPostNewCourseTOCPost(coursesPostNewCourseTOCPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /courses/post/newScreen
     * @param {CoursesPostNewScreenPostRequest} coursesPostNewScreenPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public coursesPostNewScreenPost(coursesPostNewScreenPostRequest: CoursesPostNewScreenPostRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).coursesPostNewScreenPost(coursesPostNewScreenPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /courses/post/newSection
     * @param {CoursesPostNewSectionPostRequest} coursesPostNewSectionPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public coursesPostNewSectionPost(coursesPostNewSectionPostRequest: CoursesPostNewSectionPostRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).coursesPostNewSectionPost(coursesPostNewSectionPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /screens/get/get
     * @param {string} [screenId] 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public screensGetGetGet(screenId?: string, type?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).screensGetGetGet(screenId, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /screens/patch/editScreen
     * @param {ScreensPatchEditScreenPatchRequest} screensPatchEditScreenPatchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public screensPatchEditScreenPatch(screensPatchEditScreenPatchRequest: ScreensPatchEditScreenPatchRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).screensPatchEditScreenPatch(screensPatchEditScreenPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



