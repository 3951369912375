import axios from "axios";
import { grammarServiceBaseURL } from "../../static_data/backend-base-url";

export async function translate(text: string, source: string, target: string) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: grammarServiceBaseURL + "/translate",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ text, source, target }),
  };

  let response = await axios.request(config);
  return response.data;
}

export async function lemmatize(text: string, locale): Promise<any> {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    // url: grammarServiceBaseURL + "/fr/lemmatize",
    url: grammarServiceBaseURL + `/${locale}/lemmatize`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ text }),
  };

  let response = await axios.request(config);
  return response.data;
}

export async function searchText(
  texts: string[][],
  words: string[],
  locale
): Promise<any> {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    // url: grammarServiceBaseURL + "/fr/search-text",
    url: grammarServiceBaseURL + `/${locale}/search-text`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      texts: texts,
      words: words,
    }),
  };

  let response = await axios.request(config);

  return response.data.results
    .map((result) => result.matches)
    .map((matches) => matches.map((match) => match.matches))
    .map((matches) => matches.flat())
    .map((matches) => matches.map((match) => match.textForm))
    .flat();
}

export async function searchTextSingle(
  text: string,
  words: string[],
  locale
): Promise<any> {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    // url: grammarServiceBaseURL + "/fr/search-text",
    url: grammarServiceBaseURL + `/${locale}/search-text`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      text: text,
      words: words,
    }),
  };

  let response = await axios.request(config);

  return response.data.map((match) => match.textForm).flat();
}

/* export async function readTextOutLoud(
  text: string,
  lang: string
){
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: grammarServiceBaseURL + "/read-text-out-loud",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ text, lang }),
  };

  let response = await axios.request(config);
  return response.data;
} */

export async function splitTextIntoTokens(text: string, locale) {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    // url: grammarServiceBaseURL + "/fr/split-into-tokens",
    url: grammarServiceBaseURL + `/${locale}/split-into-tokens`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({ text }),
  };

  let response = await axios.request(config);
  return response.data;
}
