import { Configuration, DefaultApi } from "../../../generated/courses";

import { coursesServiceBaseURL } from "../../static_data/backend-base-url";

const getClient = (token: string) => {
  return new DefaultApi(
    new Configuration({
      apiKey: `Bearer ${token}`,
      accessToken: token,
      basePath: coursesServiceBaseURL,
    })
  );
};

export const getAllCourses = async (
  token: string,
  studyLanguage: string,
  knownLanguage: string
) => {
  const client = getClient(token);

  return await client.coursesGetGetAllGet({}, studyLanguage, knownLanguage);
};

export const getCourse = async (token: string, courseId: string) => {
  const client = getClient(token);

  return await client.coursesGetGetGet(courseId);
};

export const addCourseTOC = async (
  token: string,
  title,
  description,
  toc,
  knownLanguage,
  studyLanguage
) => {
  const client = getClient(token);

  return await client.coursesPostNewCourseTOCPost({
    title,
    // @ts-ignore
    description,
    toc,
    knownLanguage,
    studyLanguage,
  });
};

export const updateCourse = async (
  token: string,
  courseId: string,
  params: any
) => {
  const client = getClient(token);

  return await client.coursesPatchEditCoursePatch({
    courseId,
    ...params,
  });
};

export const deleteCourse = async (token: string, courseId: string) => {
  const client = getClient(token);

  return await client.coursesDeleteDeleteDelete({}, courseId);
};

export const getCourseMenu = async (token: string, courseId: string) => {
  const client = getClient(token);

  return await client.coursesGetGetCourseMenuGet(courseId);
};

export const addSection = async (token, courseId) => {
  const client = getClient(token);

  return await client.coursesPostNewSectionPost({ courseId });
};

export const getScreen = async (token, screenId) => {
  const client = getClient(token);

  return await client.screensGetGetGet(screenId);
};

export const updateScreen = async (token, screenId, type, params) => {
  const client = getClient(token);

  return await client.screensPatchEditScreenPatch({
    screenId,
    type,
    ...params,
  });
};

export const addScreen = async (token, courseId, type, data) => {
  const client = getClient(token);

  return await client.coursesPostNewScreenPost({ courseId, type, data });
};

export const moveCourse = async (token, courseId, direction) => {
  const client = getClient(token);

  return await client.coursesPatchMoveSectionPatch({ courseId, direction });
};

export const setCourseCurrentScreen = async (token, courseId, screenId) => {
  const client = getClient(token);

  return await client.coursesPatchSetCurrentScreenPatch({ courseId, screenId });
};
