import { DefaultApi, Configuration } from "../../../generated/reading";
import { readingServiceBaseURL } from "../../static_data/backend-base-url";

const getClient = (token: string) => {
  return new DefaultApi(
    new Configuration({
      apiKey: `Bearer ${token}`,
      accessToken: token,
      basePath: readingServiceBaseURL,
    })
  );
};

export const getAllReadingItemCategories = async (
  token: string,
  studyLanguage: string,
  knownLanguage: string
) => {
  const client = getClient(token);

  return await client.categoriesGetGetAllGet(studyLanguage, knownLanguage);
};
