export const readingServiceBaseURL = "https://api.projectdiction.com/reading";
export const vocabularyServiceBaseURL = "https://api.projectdiction.com/vocabulary";
export const exercisesServiceBaseURL = "https://api.projectdiction.com/exercises";
export const userServiceBaseURL = "https://api.projectdiction.com/user";
export const grammarServiceBaseURL = "https://api.projectdiction.com/grammar";
export const coursesServiceBaseURL = "https://api.projectdiction.com/courses";

// export const readingServiceBaseURL = "http://192.168.0.228:8000";
// export const vocabularyServiceBaseURL = "http://192.168.0.228:8001";
// export const exercisesServiceBaseURL = "http://192.168.0.228:8002";
// export const userServiceBaseURL = "http://192.168.0.228:8003";
// export const grammarServiceBaseURL = "http://192.168.0.228:8004";
// export const coursesServiceBaseURL = "http://192.168.0.228:8005";

// export const exercisesServiceBaseURL = "http://localhost:8002";
// export const readingServiceBaseURL = "http://localhost:8000";
// export const userServiceBaseURL = "http://localhost:8003";
// export const vocabularyServiceBaseURL = "http://localhost:8001";
// export const grammarServiceBaseURL = "http://localhost:8004";
// export const coursesServiceBaseURL = "http://localhost:8005";
