import {
  Configuration,
  UserApi,
  UserPostLoginPostRequest,
  UserPostRegisterPostRequest,
  UserPutSetUserPreferencesPutRequest,
} from "../../../generated/user";

import { userServiceBaseURL } from "../../static_data/backend-base-url";

const getClient = (token: string) => {
  return new UserApi(
    new Configuration({
      apiKey: `Bearer ${token}`,
      accessToken: token,
      basePath: userServiceBaseURL,
    })
  );
};

export const login = async (params: UserPostLoginPostRequest) => {
  const client = getClient("");

  return await client.userPostLoginPost(params);
};

export const register = async (params: UserPostRegisterPostRequest) => {
  const client = getClient("");

  return await client.userPostRegisterPost(params);
};

export const getUserPreferences = async (token: string) => {
  const client = getClient(token);

  return await client.userGetGetUserPreferencesGet();
};

export const getPersonalDictionaryId = async (
  token: string,
  language?: string
) => {
  const client = getClient(token);

  if (language) {
    return await client.userGetGetPersonalDictionaryIdGet(language);
  }

  return await client.userGetGetPersonalDictionaryIdGet();
};

export const setUserPreferences = async (
  token: string,
  params: UserPutSetUserPreferencesPutRequest
) => {
  const client = getClient(token);

  return await client.userPutSetUserPreferencesPut(params);
};

export const logOut = async (token: string) => {
  const client = getClient(token);

  return await client.userDeleteLogoutDelete();
};
