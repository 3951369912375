/* tslint:disable */
/* eslint-disable */
/**
 * exercises-service
 * Specification JSONs: [v2](/api-spec/v2), [v3](/api-spec/v3).
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface FillBlank
 */
export interface FillBlank {
    /**
     * 
     * @type {string}
     * @memberof FillBlank
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof FillBlank
     */
    'instructions'?: string;
    /**
     * 
     * @type {string}
     * @memberof FillBlank
     */
    'helpText'?: string;
    /**
     * 
     * @type {string}
     * @memberof FillBlank
     */
    'paragraph'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FillBlank
     */
    'paragraphTokens'?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof FillBlank
     */
    'blanksIndices'?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FillBlank
     */
    'blanksParanthesesWords'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof FillBlank
     */
    'useParanthesesWords'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof FillBlank
     */
    'helpWords'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof FillBlank
     */
    'showHelpWords'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FillBlank
     */
    'sourceLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof FillBlank
     */
    '_id'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof FillBlank
     */
    'blanks_indices'?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FillBlank
     */
    'help_words'?: Array<string>;
}
/**
 * 
 * @export
 * @interface FillblankDeleteRemoveDelete200Response
 */
export interface FillblankDeleteRemoveDelete200Response {
    /**
     * 
     * @type {string}
     * @memberof FillblankDeleteRemoveDelete200Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface FillblankGetGetAllGet200Response
 */
export interface FillblankGetGetAllGet200Response {
    /**
     * 
     * @type {string}
     * @memberof FillblankGetGetAllGet200Response
     */
    'message'?: string;
    /**
     * 
     * @type {Array<FillblankGetGetAllGet200ResponseFillBlanksInner>}
     * @memberof FillblankGetGetAllGet200Response
     */
    'fillBlanks'?: Array<FillblankGetGetAllGet200ResponseFillBlanksInner>;
}
/**
 * 
 * @export
 * @interface FillblankGetGetAllGet200ResponseFillBlanksInner
 */
export interface FillblankGetGetAllGet200ResponseFillBlanksInner {
    /**
     * 
     * @type {string}
     * @memberof FillblankGetGetAllGet200ResponseFillBlanksInner
     */
    '_id': string;
    /**
     * 
     * @type {string}
     * @memberof FillblankGetGetAllGet200ResponseFillBlanksInner
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof FillblankGetGetAllGet200ResponseFillBlanksInner
     */
    'paragraph': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof FillblankGetGetAllGet200ResponseFillBlanksInner
     */
    'blanks_indices': Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FillblankGetGetAllGet200ResponseFillBlanksInner
     */
    'help_words': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof FillblankGetGetAllGet200ResponseFillBlanksInner
     */
    '__v': number;
}
/**
 * 
 * @export
 * @interface FillblankGetGetGet200Response
 */
export interface FillblankGetGetGet200Response {
    /**
     * 
     * @type {string}
     * @memberof FillblankGetGetGet200Response
     */
    'message'?: string;
    /**
     * 
     * @type {FillblankGetGetGet200ResponseFillBlank}
     * @memberof FillblankGetGetGet200Response
     */
    'fillBlank'?: FillblankGetGetGet200ResponseFillBlank;
}
/**
 * 
 * @export
 * @interface FillblankGetGetGet200ResponseFillBlank
 */
export interface FillblankGetGetGet200ResponseFillBlank {
    /**
     * 
     * @type {string}
     * @memberof FillblankGetGetGet200ResponseFillBlank
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FillblankGetGetGet200ResponseFillBlank
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof FillblankGetGetGet200ResponseFillBlank
     */
    'paragraph'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof FillblankGetGetGet200ResponseFillBlank
     */
    'blanks_indices'?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FillblankGetGetGet200ResponseFillBlank
     */
    'help_words'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof FillblankGetGetGet200ResponseFillBlank
     */
    '__v'?: number;
}
/**
 * 
 * @export
 * @interface FillblankGetGetGet400Response
 */
export interface FillblankGetGetGet400Response {
    /**
     * 
     * @type {string}
     * @memberof FillblankGetGetGet400Response
     */
    'message'?: string;
    /**
     * 
     * @type {FillblankGetGetGet400ResponseError}
     * @memberof FillblankGetGetGet400Response
     */
    'error'?: FillblankGetGetGet400ResponseError;
}
/**
 * 
 * @export
 * @interface FillblankGetGetGet400ResponseError
 */
export interface FillblankGetGetGet400ResponseError {
    /**
     * 
     * @type {string}
     * @memberof FillblankGetGetGet400ResponseError
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof FillblankGetGetGet400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof FillblankGetGetGet400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface FillblankPostAddPost200Response
 */
export interface FillblankPostAddPost200Response {
    /**
     * 
     * @type {string}
     * @memberof FillblankPostAddPost200Response
     */
    'message'?: string;
    /**
     * 
     * @type {FillblankPostAddPost200ResponseFillBlank}
     * @memberof FillblankPostAddPost200Response
     */
    'fillBlank'?: FillblankPostAddPost200ResponseFillBlank;
}
/**
 * 
 * @export
 * @interface FillblankPostAddPost200ResponseFillBlank
 */
export interface FillblankPostAddPost200ResponseFillBlank {
    /**
     * 
     * @type {string}
     * @memberof FillblankPostAddPost200ResponseFillBlank
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof FillblankPostAddPost200ResponseFillBlank
     */
    'paragraph'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof FillblankPostAddPost200ResponseFillBlank
     */
    'blanks_indices'?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FillblankPostAddPost200ResponseFillBlank
     */
    'help_words'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FillblankPostAddPost200ResponseFillBlank
     */
    '_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof FillblankPostAddPost200ResponseFillBlank
     */
    '__v'?: number;
}
/**
 * 
 * @export
 * @interface FillblankPostAddPost400Response
 */
export interface FillblankPostAddPost400Response {
    /**
     * 
     * @type {string}
     * @memberof FillblankPostAddPost400Response
     */
    'message'?: string;
    /**
     * 
     * @type {FillblankPostAddPost400ResponseError}
     * @memberof FillblankPostAddPost400Response
     */
    'error'?: FillblankPostAddPost400ResponseError;
}
/**
 * 
 * @export
 * @interface FillblankPostAddPost400ResponseError
 */
export interface FillblankPostAddPost400ResponseError {
    /**
     * 
     * @type {number}
     * @memberof FillblankPostAddPost400ResponseError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof FillblankPostAddPost400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof FillblankPostAddPost400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface FillblankPostAddPostRequest
 */
export interface FillblankPostAddPostRequest {
    /**
     * 
     * @type {string}
     * @memberof FillblankPostAddPostRequest
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof FillblankPostAddPostRequest
     */
    'paragraph'?: string;
    /**
     * 
     * @type {string}
     * @memberof FillblankPostAddPostRequest
     */
    'blanks_indices'?: string;
    /**
     * 
     * @type {string}
     * @memberof FillblankPostAddPostRequest
     */
    'help_words'?: string;
}

/**
 * FillBlankApi - axios parameter creator
 * @export
 */
export const FillBlankApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary /fillblank/delete/remove
         * @param {string} [fillBlankId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fillblankDeleteRemoveDelete: async (fillBlankId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/fillblank/delete/remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (fillBlankId !== undefined) {
                localVarQueryParameter['fillBlankId'] = fillBlankId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /fillblank/get/getAll
         * @param {string} [sourceLanguage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fillblankGetGetAllGet: async (sourceLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/fillblank/get/getAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (sourceLanguage !== undefined) {
                localVarQueryParameter['sourceLanguage'] = sourceLanguage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /fillblank/get/get
         * @param {string} [fillBlankId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fillblankGetGetGet: async (fillBlankId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/fillblank/get/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (fillBlankId !== undefined) {
                localVarQueryParameter['fillBlankId'] = fillBlankId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /fillblank/post/add
         * @param {FillblankPostAddPostRequest} fillblankPostAddPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fillblankPostAddPost: async (fillblankPostAddPostRequest: FillblankPostAddPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fillblankPostAddPostRequest' is not null or undefined
            assertParamExists('fillblankPostAddPost', 'fillblankPostAddPostRequest', fillblankPostAddPostRequest)
            const localVarPath = `/fillblank/post/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fillblankPostAddPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FillBlankApi - functional programming interface
 * @export
 */
export const FillBlankApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FillBlankApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary /fillblank/delete/remove
         * @param {string} [fillBlankId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fillblankDeleteRemoveDelete(fillBlankId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FillblankDeleteRemoveDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fillblankDeleteRemoveDelete(fillBlankId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /fillblank/get/getAll
         * @param {string} [sourceLanguage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fillblankGetGetAllGet(sourceLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FillblankGetGetAllGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fillblankGetGetAllGet(sourceLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /fillblank/get/get
         * @param {string} [fillBlankId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fillblankGetGetGet(fillBlankId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FillblankGetGetGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fillblankGetGetGet(fillBlankId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /fillblank/post/add
         * @param {FillblankPostAddPostRequest} fillblankPostAddPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fillblankPostAddPost(fillblankPostAddPostRequest: FillblankPostAddPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FillblankPostAddPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fillblankPostAddPost(fillblankPostAddPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FillBlankApi - factory interface
 * @export
 */
export const FillBlankApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FillBlankApiFp(configuration)
    return {
        /**
         * 
         * @summary /fillblank/delete/remove
         * @param {string} [fillBlankId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fillblankDeleteRemoveDelete(fillBlankId?: string, options?: any): AxiosPromise<FillblankDeleteRemoveDelete200Response> {
            return localVarFp.fillblankDeleteRemoveDelete(fillBlankId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /fillblank/get/getAll
         * @param {string} [sourceLanguage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fillblankGetGetAllGet(sourceLanguage?: string, options?: any): AxiosPromise<FillblankGetGetAllGet200Response> {
            return localVarFp.fillblankGetGetAllGet(sourceLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /fillblank/get/get
         * @param {string} [fillBlankId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fillblankGetGetGet(fillBlankId?: string, options?: any): AxiosPromise<FillblankGetGetGet200Response> {
            return localVarFp.fillblankGetGetGet(fillBlankId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /fillblank/post/add
         * @param {FillblankPostAddPostRequest} fillblankPostAddPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fillblankPostAddPost(fillblankPostAddPostRequest: FillblankPostAddPostRequest, options?: any): AxiosPromise<FillblankPostAddPost200Response> {
            return localVarFp.fillblankPostAddPost(fillblankPostAddPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FillBlankApi - object-oriented interface
 * @export
 * @class FillBlankApi
 * @extends {BaseAPI}
 */
export class FillBlankApi extends BaseAPI {
    /**
     * 
     * @summary /fillblank/delete/remove
     * @param {string} [fillBlankId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FillBlankApi
     */
    public fillblankDeleteRemoveDelete(fillBlankId?: string, options?: AxiosRequestConfig) {
        return FillBlankApiFp(this.configuration).fillblankDeleteRemoveDelete(fillBlankId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /fillblank/get/getAll
     * @param {string} [sourceLanguage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FillBlankApi
     */
    public fillblankGetGetAllGet(sourceLanguage?: string, options?: AxiosRequestConfig) {
        return FillBlankApiFp(this.configuration).fillblankGetGetAllGet(sourceLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /fillblank/get/get
     * @param {string} [fillBlankId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FillBlankApi
     */
    public fillblankGetGetGet(fillBlankId?: string, options?: AxiosRequestConfig) {
        return FillBlankApiFp(this.configuration).fillblankGetGetGet(fillBlankId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /fillblank/post/add
     * @param {FillblankPostAddPostRequest} fillblankPostAddPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FillBlankApi
     */
    public fillblankPostAddPost(fillblankPostAddPostRequest: FillblankPostAddPostRequest, options?: AxiosRequestConfig) {
        return FillBlankApiFp(this.configuration).fillblankPostAddPost(fillblankPostAddPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



