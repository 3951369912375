/* tslint:disable */
/* eslint-disable */
/**
 * vocabulary-service
 * Specification JSONs: [v2](/api-spec/v2), [v3](/api-spec/v3).
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CategoriesGetGetAllGet200Response
 */
export interface CategoriesGetGetAllGet200Response {
    /**
     * 
     * @type {string}
     * @memberof CategoriesGetGetAllGet200Response
     */
    'message'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof CategoriesGetGetAllGet200Response
     */
    'categories'?: Array<any>;
}
/**
 * 
 * @export
 * @interface CategoriesGetGetAllGet400Response
 */
export interface CategoriesGetGetAllGet400Response {
    /**
     * 
     * @type {string}
     * @memberof CategoriesGetGetAllGet400Response
     */
    'message'?: string;
    /**
     * 
     * @type {CategoriesGetGetAllGet400ResponseError}
     * @memberof CategoriesGetGetAllGet400Response
     */
    'error'?: CategoriesGetGetAllGet400ResponseError;
}
/**
 * 
 * @export
 * @interface CategoriesGetGetAllGet400ResponseError
 */
export interface CategoriesGetGetAllGet400ResponseError {
    /**
     * 
     * @type {number}
     * @memberof CategoriesGetGetAllGet400ResponseError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoriesGetGetAllGet400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesGetGetAllGet400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface CategoriesPostCreatePost200Response
 */
export interface CategoriesPostCreatePost200Response {
    /**
     * 
     * @type {string}
     * @memberof CategoriesPostCreatePost200Response
     */
    'message'?: string;
    /**
     * 
     * @type {CategoriesPostCreatePost200ResponseCategory}
     * @memberof CategoriesPostCreatePost200Response
     */
    'category'?: CategoriesPostCreatePost200ResponseCategory;
}
/**
 * 
 * @export
 * @interface CategoriesPostCreatePost200ResponseCategory
 */
export interface CategoriesPostCreatePost200ResponseCategory {
    /**
     * 
     * @type {string}
     * @memberof CategoriesPostCreatePost200ResponseCategory
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPostCreatePost200ResponseCategory
     */
    'description'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof CategoriesPostCreatePost200ResponseCategory
     */
    'children'?: Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof CategoriesPostCreatePost200ResponseCategory
     */
    'readingItems'?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPostCreatePost200ResponseCategory
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPostCreatePost200ResponseCategory
     */
    'studyLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPostCreatePost200ResponseCategory
     */
    'knownLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPostCreatePost200ResponseCategory
     */
    'creator'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPostCreatePost200ResponseCategory
     */
    '_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof CategoriesPostCreatePost200ResponseCategory
     */
    '__v'?: number;
}
/**
 * 
 * @export
 * @interface CategoriesPostCreatePost400Response
 */
export interface CategoriesPostCreatePost400Response {
    /**
     * 
     * @type {string}
     * @memberof CategoriesPostCreatePost400Response
     */
    'message'?: string;
    /**
     * 
     * @type {CategoriesPostCreatePost400ResponseError}
     * @memberof CategoriesPostCreatePost400Response
     */
    'error'?: CategoriesPostCreatePost400ResponseError;
}
/**
 * 
 * @export
 * @interface CategoriesPostCreatePost400ResponseError
 */
export interface CategoriesPostCreatePost400ResponseError {
    /**
     * 
     * @type {number}
     * @memberof CategoriesPostCreatePost400ResponseError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPostCreatePost400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPostCreatePost400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface CategoriesPostCreatePostRequest
 */
export interface CategoriesPostCreatePostRequest {
    /**
     * 
     * @type {string}
     * @memberof CategoriesPostCreatePostRequest
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPostCreatePostRequest
     */
    'studyLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPostCreatePostRequest
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface CategoriesPutAddDictionaryToCategoryPut200Response
 */
export interface CategoriesPutAddDictionaryToCategoryPut200Response {
    /**
     * 
     * @type {string}
     * @memberof CategoriesPutAddDictionaryToCategoryPut200Response
     */
    'message'?: string;
    /**
     * 
     * @type {CategoriesPutAddDictionaryToCategoryPut200ResponseCategory}
     * @memberof CategoriesPutAddDictionaryToCategoryPut200Response
     */
    'category'?: CategoriesPutAddDictionaryToCategoryPut200ResponseCategory;
}
/**
 * 
 * @export
 * @interface CategoriesPutAddDictionaryToCategoryPut200ResponseCategory
 */
export interface CategoriesPutAddDictionaryToCategoryPut200ResponseCategory {
    /**
     * 
     * @type {string}
     * @memberof CategoriesPutAddDictionaryToCategoryPut200ResponseCategory
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPutAddDictionaryToCategoryPut200ResponseCategory
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPutAddDictionaryToCategoryPut200ResponseCategory
     */
    'description'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof CategoriesPutAddDictionaryToCategoryPut200ResponseCategory
     */
    'children'?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPutAddDictionaryToCategoryPut200ResponseCategory
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPutAddDictionaryToCategoryPut200ResponseCategory
     */
    'studyLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPutAddDictionaryToCategoryPut200ResponseCategory
     */
    'knownLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPutAddDictionaryToCategoryPut200ResponseCategory
     */
    'creator'?: string;
    /**
     * 
     * @type {number}
     * @memberof CategoriesPutAddDictionaryToCategoryPut200ResponseCategory
     */
    '__v'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CategoriesPutAddDictionaryToCategoryPut200ResponseCategory
     */
    'dictionaries'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CategoriesPutAddDictionaryToCategoryPutRequest
 */
export interface CategoriesPutAddDictionaryToCategoryPutRequest {
    /**
     * 
     * @type {string}
     * @memberof CategoriesPutAddDictionaryToCategoryPutRequest
     */
    'categoryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPutAddDictionaryToCategoryPutRequest
     */
    'dictionaryId'?: string;
}
/**
 * 
 * @export
 * @interface Dictionary
 */
export interface Dictionary {
    /**
     * 
     * @type {Array<string>}
     * @memberof Dictionary
     */
    'items'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Dictionary
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof Dictionary
     */
    'creator'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Dictionary
     */
    'piles'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Dictionary
     */
    'defaultPile'?: string;
    /**
     * 
     * @type {string}
     * @memberof Dictionary
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof Dictionary
     */
    'parent'?: string;
    /**
     * 
     * @type {string}
     * @memberof Dictionary
     */
    'type'?: DictionaryTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Dictionary
     */
    'studyLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof Dictionary
     */
    'knownLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof Dictionary
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Dictionary
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Dictionary
     */
    'targetLanguage'?: string;
}

export const DictionaryTypeEnum = {
    Default: 'default',
    DefaultPile: 'default_pile',
    Pile: 'pile',
    User: 'user',
    ReadingItem: 'readingItem',
    Frequency: 'frequency',
    Translation: 'translation',
    Category: 'category',
    Copy: 'copy'
} as const;

export type DictionaryTypeEnum = typeof DictionaryTypeEnum[keyof typeof DictionaryTypeEnum];

/**
 * 
 * @export
 * @interface DictionaryDeleteRemoveDelete200Response
 */
export interface DictionaryDeleteRemoveDelete200Response {
    /**
     * 
     * @type {string}
     * @memberof DictionaryDeleteRemoveDelete200Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryDeleteRemoveDeleteRequest
 */
export interface DictionaryDeleteRemoveDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof DictionaryDeleteRemoveDeleteRequest
     */
    'dictionaryId'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryDeleteRemovePileDelete200Response
 */
export interface DictionaryDeleteRemovePileDelete200Response {
    /**
     * 
     * @type {string}
     * @memberof DictionaryDeleteRemovePileDelete200Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryDeleteRemovePileDelete400Response
 */
export interface DictionaryDeleteRemovePileDelete400Response {
    /**
     * 
     * @type {string}
     * @memberof DictionaryDeleteRemovePileDelete400Response
     */
    'message'?: string;
    /**
     * 
     * @type {DictionaryDeleteRemovePileDelete400ResponseError}
     * @memberof DictionaryDeleteRemovePileDelete400Response
     */
    'error'?: DictionaryDeleteRemovePileDelete400ResponseError;
}
/**
 * 
 * @export
 * @interface DictionaryDeleteRemovePileDelete400ResponseError
 */
export interface DictionaryDeleteRemovePileDelete400ResponseError {
    /**
     * 
     * @type {number}
     * @memberof DictionaryDeleteRemovePileDelete400ResponseError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DictionaryDeleteRemovePileDelete400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryDeleteRemovePileDelete400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryDeleteRemovePileDeleteRequest
 */
export interface DictionaryDeleteRemovePileDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof DictionaryDeleteRemovePileDeleteRequest
     */
    'pileId'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryDeleteRemoveWordDelete200Response
 */
export interface DictionaryDeleteRemoveWordDelete200Response {
    /**
     * 
     * @type {string}
     * @memberof DictionaryDeleteRemoveWordDelete200Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryDeleteRemoveWordDelete400Response
 */
export interface DictionaryDeleteRemoveWordDelete400Response {
    /**
     * 
     * @type {string}
     * @memberof DictionaryDeleteRemoveWordDelete400Response
     */
    'message'?: string;
    /**
     * 
     * @type {DictionaryDeleteRemoveWordDelete400ResponseError}
     * @memberof DictionaryDeleteRemoveWordDelete400Response
     */
    'error'?: DictionaryDeleteRemoveWordDelete400ResponseError;
}
/**
 * 
 * @export
 * @interface DictionaryDeleteRemoveWordDelete400ResponseError
 */
export interface DictionaryDeleteRemoveWordDelete400ResponseError {
    /**
     * 
     * @type {number}
     * @memberof DictionaryDeleteRemoveWordDelete400ResponseError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DictionaryDeleteRemoveWordDelete400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryDeleteRemoveWordDelete400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryDeleteRemoveWordDeleteRequest
 */
export interface DictionaryDeleteRemoveWordDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof DictionaryDeleteRemoveWordDeleteRequest
     */
    'dictionaryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryDeleteRemoveWordDeleteRequest
     */
    'wordId'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryDeleteRemoveWordPileDelete200Response
 */
export interface DictionaryDeleteRemoveWordPileDelete200Response {
    /**
     * 
     * @type {string}
     * @memberof DictionaryDeleteRemoveWordPileDelete200Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryDeleteRemoveWordPileDelete400Response
 */
export interface DictionaryDeleteRemoveWordPileDelete400Response {
    /**
     * 
     * @type {string}
     * @memberof DictionaryDeleteRemoveWordPileDelete400Response
     */
    'message'?: string;
    /**
     * 
     * @type {DictionaryDeleteRemoveWordPileDelete400ResponseError}
     * @memberof DictionaryDeleteRemoveWordPileDelete400Response
     */
    'error'?: DictionaryDeleteRemoveWordPileDelete400ResponseError;
}
/**
 * 
 * @export
 * @interface DictionaryDeleteRemoveWordPileDelete400ResponseError
 */
export interface DictionaryDeleteRemoveWordPileDelete400ResponseError {
    /**
     * 
     * @type {number}
     * @memberof DictionaryDeleteRemoveWordPileDelete400ResponseError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DictionaryDeleteRemoveWordPileDelete400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryDeleteRemoveWordPileDelete400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryDeleteRemoveWordPileDeleteRequest
 */
export interface DictionaryDeleteRemoveWordPileDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof DictionaryDeleteRemoveWordPileDeleteRequest
     */
    'wordId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryDeleteRemoveWordPileDeleteRequest
     */
    'pileId'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryGetGetAllGet200Response
 */
export interface DictionaryGetGetAllGet200Response {
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetAllGet200Response
     */
    'message'?: string;
    /**
     * 
     * @type {Array<DictionaryGetGetAllGet200ResponseDictionariesInner>}
     * @memberof DictionaryGetGetAllGet200Response
     */
    'dictionaries'?: Array<DictionaryGetGetAllGet200ResponseDictionariesInner>;
}
/**
 * 
 * @export
 * @interface DictionaryGetGetAllGet200ResponseDictionariesInner
 */
export interface DictionaryGetGetAllGet200ResponseDictionariesInner {
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetAllGet200ResponseDictionariesInner
     */
    '_id': string;
    /**
     * 
     * @type {Array<any>}
     * @memberof DictionaryGetGetAllGet200ResponseDictionariesInner
     */
    'items': Array<any>;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetAllGet200ResponseDictionariesInner
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetAllGet200ResponseDictionariesInner
     */
    'creator': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DictionaryGetGetAllGet200ResponseDictionariesInner
     */
    'piles': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetAllGet200ResponseDictionariesInner
     */
    'color': string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetAllGet200ResponseDictionariesInner
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof DictionaryGetGetAllGet200ResponseDictionariesInner
     */
    '__v': number;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetAllGet200ResponseDictionariesInner
     */
    'defaultPile': string;
}
/**
 * 
 * @export
 * @interface DictionaryGetGetAllGet400Response
 */
export interface DictionaryGetGetAllGet400Response {
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetAllGet400Response
     */
    'message'?: string;
    /**
     * 
     * @type {DictionaryGetGetAllGet400ResponseError}
     * @memberof DictionaryGetGetAllGet400Response
     */
    'error'?: DictionaryGetGetAllGet400ResponseError;
}
/**
 * 
 * @export
 * @interface DictionaryGetGetAllGet400ResponseError
 */
export interface DictionaryGetGetAllGet400ResponseError {
    /**
     * 
     * @type {number}
     * @memberof DictionaryGetGetAllGet400ResponseError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetAllGet400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetAllGet400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryGetGetAllGetRequest
 */
export interface DictionaryGetGetAllGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetAllGetRequest
     */
    'label'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryGetGetGet200Response
 */
export interface DictionaryGetGetGet200Response {
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetGet200Response
     */
    'message'?: string;
    /**
     * 
     * @type {DictionaryGetGetGet200ResponseDictionary}
     * @memberof DictionaryGetGetGet200Response
     */
    'dictionary'?: DictionaryGetGetGet200ResponseDictionary;
}
/**
 * 
 * @export
 * @interface DictionaryGetGetGet200ResponseDictionary
 */
export interface DictionaryGetGetGet200ResponseDictionary {
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetGet200ResponseDictionary
     */
    '_id'?: string;
    /**
     * 
     * @type {Array<DictionaryGetGetGet200ResponseDictionaryItemsInner>}
     * @memberof DictionaryGetGetGet200ResponseDictionary
     */
    'items'?: Array<DictionaryGetGetGet200ResponseDictionaryItemsInner>;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetGet200ResponseDictionary
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetGet200ResponseDictionary
     */
    'creator'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DictionaryGetGetGet200ResponseDictionary
     */
    'piles'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetGet200ResponseDictionary
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetGet200ResponseDictionary
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DictionaryGetGetGet200ResponseDictionary
     */
    '__v'?: number;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetGet200ResponseDictionary
     */
    'defaultPile'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryGetGetGet200ResponseDictionaryItemsInner
 */
export interface DictionaryGetGetGet200ResponseDictionaryItemsInner {
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetGet200ResponseDictionaryItemsInner
     */
    '_id': string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetGet200ResponseDictionaryItemsInner
     */
    'word': string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetGet200ResponseDictionaryItemsInner
     */
    'meaning': string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetGet200ResponseDictionaryItemsInner
     */
    'creator': string;
    /**
     * 
     * @type {number}
     * @memberof DictionaryGetGetGet200ResponseDictionaryItemsInner
     */
    'times': number;
    /**
     * 
     * @type {number}
     * @memberof DictionaryGetGetGet200ResponseDictionaryItemsInner
     */
    '__v': number;
}
/**
 * 
 * @export
 * @interface DictionaryGetGetGet400Response
 */
export interface DictionaryGetGetGet400Response {
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetGet400Response
     */
    'message'?: string;
    /**
     * 
     * @type {DictionaryGetGetGet400ResponseError}
     * @memberof DictionaryGetGetGet400Response
     */
    'error'?: DictionaryGetGetGet400ResponseError;
}
/**
 * 
 * @export
 * @interface DictionaryGetGetGet400ResponseError
 */
export interface DictionaryGetGetGet400ResponseError {
    /**
     * 
     * @type {number}
     * @memberof DictionaryGetGetGet400ResponseError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetGet400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetGet400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryGetGetPilesGet200Response
 */
export interface DictionaryGetGetPilesGet200Response {
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetPilesGet200Response
     */
    'message'?: string;
    /**
     * 
     * @type {Array<DictionaryGetGetPilesGet200ResponsePilesInner>}
     * @memberof DictionaryGetGetPilesGet200Response
     */
    'piles'?: Array<DictionaryGetGetPilesGet200ResponsePilesInner>;
}
/**
 * 
 * @export
 * @interface DictionaryGetGetPilesGet200ResponsePilesInner
 */
export interface DictionaryGetGetPilesGet200ResponsePilesInner {
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetPilesGet200ResponsePilesInner
     */
    '_id'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof DictionaryGetGetPilesGet200ResponsePilesInner
     */
    'items'?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetPilesGet200ResponsePilesInner
     */
    'label'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof DictionaryGetGetPilesGet200ResponsePilesInner
     */
    'piles'?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetPilesGet200ResponsePilesInner
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetPilesGet200ResponsePilesInner
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DictionaryGetGetPilesGet200ResponsePilesInner
     */
    '__v'?: number;
}
/**
 * 
 * @export
 * @interface DictionaryGetGetSomeGet200Response
 */
export interface DictionaryGetGetSomeGet200Response {
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetSomeGet200Response
     */
    'message'?: string;
    /**
     * 
     * @type {Array<DictionaryGetGetSomeGet200ResponseDictionariesInner>}
     * @memberof DictionaryGetGetSomeGet200Response
     */
    'dictionaries'?: Array<DictionaryGetGetSomeGet200ResponseDictionariesInner>;
}
/**
 * 
 * @export
 * @interface DictionaryGetGetSomeGet200ResponseDictionariesInner
 */
export interface DictionaryGetGetSomeGet200ResponseDictionariesInner {
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetSomeGet200ResponseDictionariesInner
     */
    '_id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DictionaryGetGetSomeGet200ResponseDictionariesInner
     */
    'items'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetSomeGet200ResponseDictionariesInner
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetSomeGet200ResponseDictionariesInner
     */
    'creator'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DictionaryGetGetSomeGet200ResponseDictionariesInner
     */
    'piles'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetSomeGet200ResponseDictionariesInner
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetSomeGet200ResponseDictionariesInner
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetSomeGet200ResponseDictionariesInner
     */
    'studyLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetSomeGet200ResponseDictionariesInner
     */
    'knownLanguage'?: string;
    /**
     * 
     * @type {number}
     * @memberof DictionaryGetGetSomeGet200ResponseDictionariesInner
     */
    '__v'?: number;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetSomeGet200ResponseDictionariesInner
     */
    'defaultPile'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryGetGetSomeGet400Response
 */
export interface DictionaryGetGetSomeGet400Response {
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetSomeGet400Response
     */
    'message'?: string;
    /**
     * 
     * @type {DictionaryGetGetSomeGet400ResponseError}
     * @memberof DictionaryGetGetSomeGet400Response
     */
    'error'?: DictionaryGetGetSomeGet400ResponseError;
}
/**
 * 
 * @export
 * @interface DictionaryGetGetSomeGet400ResponseError
 */
export interface DictionaryGetGetSomeGet400ResponseError {
    /**
     * 
     * @type {number}
     * @memberof DictionaryGetGetSomeGet400ResponseError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetSomeGet400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetSomeGet400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryGetGetWordListGet200Response
 */
export interface DictionaryGetGetWordListGet200Response {
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetWordListGet200Response
     */
    'message'?: string;
    /**
     * 
     * @type {DictionaryGetGetWordListGet200ResponseDictionary}
     * @memberof DictionaryGetGetWordListGet200Response
     */
    'dictionary'?: DictionaryGetGetWordListGet200ResponseDictionary;
}
/**
 * 
 * @export
 * @interface DictionaryGetGetWordListGet200ResponseDictionary
 */
export interface DictionaryGetGetWordListGet200ResponseDictionary {
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetWordListGet200ResponseDictionary
     */
    '_id'?: string;
    /**
     * 
     * @type {Array<DictionaryGetGetWordListGet200ResponseDictionaryItemsInner>}
     * @memberof DictionaryGetGetWordListGet200ResponseDictionary
     */
    'items'?: Array<DictionaryGetGetWordListGet200ResponseDictionaryItemsInner>;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetWordListGet200ResponseDictionary
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetWordListGet200ResponseDictionary
     */
    'creator'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DictionaryGetGetWordListGet200ResponseDictionary
     */
    'piles'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetWordListGet200ResponseDictionary
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetWordListGet200ResponseDictionary
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DictionaryGetGetWordListGet200ResponseDictionary
     */
    '__v'?: number;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetWordListGet200ResponseDictionary
     */
    'defaultPile'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryGetGetWordListGet200ResponseDictionaryItemsInner
 */
export interface DictionaryGetGetWordListGet200ResponseDictionaryItemsInner {
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetWordListGet200ResponseDictionaryItemsInner
     */
    '_id': string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetWordListGet200ResponseDictionaryItemsInner
     */
    'dictionary': string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetWordListGet200ResponseDictionaryItemsInner
     */
    'word': string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetWordListGet200ResponseDictionaryItemsInner
     */
    'meaning': string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryGetGetWordListGet200ResponseDictionaryItemsInner
     */
    'creator': string;
    /**
     * 
     * @type {number}
     * @memberof DictionaryGetGetWordListGet200ResponseDictionaryItemsInner
     */
    'times': number;
    /**
     * 
     * @type {number}
     * @memberof DictionaryGetGetWordListGet200ResponseDictionaryItemsInner
     */
    '__v': number;
}
/**
 * 
 * @export
 * @interface DictionaryPostAddWordPost200Response
 */
export interface DictionaryPostAddWordPost200Response {
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostAddWordPost200Response
     */
    'message'?: string;
    /**
     * 
     * @type {DictionaryPostAddWordPost200ResponseDictionary}
     * @memberof DictionaryPostAddWordPost200Response
     */
    'dictionary'?: DictionaryPostAddWordPost200ResponseDictionary;
}
/**
 * 
 * @export
 * @interface DictionaryPostAddWordPost200ResponseDictionary
 */
export interface DictionaryPostAddWordPost200ResponseDictionary {
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostAddWordPost200ResponseDictionary
     */
    '_id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DictionaryPostAddWordPost200ResponseDictionary
     */
    'items'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostAddWordPost200ResponseDictionary
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostAddWordPost200ResponseDictionary
     */
    'creator'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DictionaryPostAddWordPost200ResponseDictionary
     */
    'piles'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostAddWordPost200ResponseDictionary
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostAddWordPost200ResponseDictionary
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DictionaryPostAddWordPost200ResponseDictionary
     */
    '__v'?: number;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostAddWordPost200ResponseDictionary
     */
    'defaultPile'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryPostAddWordPostRequest
 */
export interface DictionaryPostAddWordPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostAddWordPostRequest
     */
    'dictionaryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostAddWordPostRequest
     */
    'word'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostAddWordPostRequest
     */
    'meaning'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryPostAddWordWithDuplicateCheckPost200Response
 */
export interface DictionaryPostAddWordWithDuplicateCheckPost200Response {
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostAddWordWithDuplicateCheckPost200Response
     */
    'message'?: string;
    /**
     * 
     * @type {DictionaryPostAddWordWithDuplicateCheckPost200ResponseDictionary}
     * @memberof DictionaryPostAddWordWithDuplicateCheckPost200Response
     */
    'dictionary'?: DictionaryPostAddWordWithDuplicateCheckPost200ResponseDictionary;
}
/**
 * 
 * @export
 * @interface DictionaryPostAddWordWithDuplicateCheckPost200ResponseDictionary
 */
export interface DictionaryPostAddWordWithDuplicateCheckPost200ResponseDictionary {
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostAddWordWithDuplicateCheckPost200ResponseDictionary
     */
    '_id'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof DictionaryPostAddWordWithDuplicateCheckPost200ResponseDictionary
     */
    'items'?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostAddWordWithDuplicateCheckPost200ResponseDictionary
     */
    'label'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof DictionaryPostAddWordWithDuplicateCheckPost200ResponseDictionary
     */
    'piles'?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostAddWordWithDuplicateCheckPost200ResponseDictionary
     */
    'defaultPile'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostAddWordWithDuplicateCheckPost200ResponseDictionary
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostAddWordWithDuplicateCheckPost200ResponseDictionary
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DictionaryPostAddWordWithDuplicateCheckPost200ResponseDictionary
     */
    '__v'?: number;
}
/**
 * 
 * @export
 * @interface DictionaryPostAddWordWithDuplicateCheckPost400Response
 */
export interface DictionaryPostAddWordWithDuplicateCheckPost400Response {
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostAddWordWithDuplicateCheckPost400Response
     */
    'message'?: string;
    /**
     * 
     * @type {DictionaryPostAddWordWithDuplicateCheckPost400ResponseError}
     * @memberof DictionaryPostAddWordWithDuplicateCheckPost400Response
     */
    'error'?: DictionaryPostAddWordWithDuplicateCheckPost400ResponseError;
}
/**
 * 
 * @export
 * @interface DictionaryPostAddWordWithDuplicateCheckPost400ResponseError
 */
export interface DictionaryPostAddWordWithDuplicateCheckPost400ResponseError {
    /**
     * 
     * @type {number}
     * @memberof DictionaryPostAddWordWithDuplicateCheckPost400ResponseError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostAddWordWithDuplicateCheckPost400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostAddWordWithDuplicateCheckPost400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryPostAddWordWithDuplicateCheckPostRequest
 */
export interface DictionaryPostAddWordWithDuplicateCheckPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostAddWordWithDuplicateCheckPostRequest
     */
    'dictionaryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostAddWordWithDuplicateCheckPostRequest
     */
    'word'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostAddWordWithDuplicateCheckPostRequest
     */
    'meaning'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryPostCopyPost201Response
 */
export interface DictionaryPostCopyPost201Response {
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostCopyPost201Response
     */
    '_id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DictionaryPostCopyPost201Response
     */
    'items'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostCopyPost201Response
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostCopyPost201Response
     */
    'creator'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DictionaryPostCopyPost201Response
     */
    'piles'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostCopyPost201Response
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostCopyPost201Response
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostCopyPost201Response
     */
    'studyLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostCopyPost201Response
     */
    'knownLanguage'?: string;
    /**
     * 
     * @type {number}
     * @memberof DictionaryPostCopyPost201Response
     */
    '__v'?: number;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostCopyPost201Response
     */
    'defaultPile'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryPostCopyPost400Response
 */
export interface DictionaryPostCopyPost400Response {
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostCopyPost400Response
     */
    'message'?: string;
    /**
     * 
     * @type {DictionaryPostCopyPost400ResponseError}
     * @memberof DictionaryPostCopyPost400Response
     */
    'error'?: DictionaryPostCopyPost400ResponseError;
}
/**
 * 
 * @export
 * @interface DictionaryPostCopyPost400ResponseError
 */
export interface DictionaryPostCopyPost400ResponseError {
    /**
     * 
     * @type {number}
     * @memberof DictionaryPostCopyPost400ResponseError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostCopyPost400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostCopyPost400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryPostNewDictionaryPost200Response
 */
export interface DictionaryPostNewDictionaryPost200Response {
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostNewDictionaryPost200Response
     */
    'message'?: string;
    /**
     * 
     * @type {DictionaryPostNewDictionaryPost200ResponseDictionary}
     * @memberof DictionaryPostNewDictionaryPost200Response
     */
    'dictionary'?: DictionaryPostNewDictionaryPost200ResponseDictionary;
}
/**
 * 
 * @export
 * @interface DictionaryPostNewDictionaryPost200ResponseDictionary
 */
export interface DictionaryPostNewDictionaryPost200ResponseDictionary {
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostNewDictionaryPost200ResponseDictionary
     */
    '_id'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof DictionaryPostNewDictionaryPost200ResponseDictionary
     */
    'items'?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostNewDictionaryPost200ResponseDictionary
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostNewDictionaryPost200ResponseDictionary
     */
    'creator'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DictionaryPostNewDictionaryPost200ResponseDictionary
     */
    'piles'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostNewDictionaryPost200ResponseDictionary
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostNewDictionaryPost200ResponseDictionary
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DictionaryPostNewDictionaryPost200ResponseDictionary
     */
    '__v'?: number;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostNewDictionaryPost200ResponseDictionary
     */
    'defaultPile'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryPostNewDictionaryPost400Response
 */
export interface DictionaryPostNewDictionaryPost400Response {
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostNewDictionaryPost400Response
     */
    'message'?: string;
    /**
     * 
     * @type {DictionaryPostNewDictionaryPost400ResponseError}
     * @memberof DictionaryPostNewDictionaryPost400Response
     */
    'error'?: DictionaryPostNewDictionaryPost400ResponseError;
}
/**
 * 
 * @export
 * @interface DictionaryPostNewDictionaryPost400ResponseError
 */
export interface DictionaryPostNewDictionaryPost400ResponseError {
    /**
     * 
     * @type {number}
     * @memberof DictionaryPostNewDictionaryPost400ResponseError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostNewDictionaryPost400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostNewDictionaryPost400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryPostNewDictionaryPostRequest
 */
export interface DictionaryPostNewDictionaryPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostNewDictionaryPostRequest
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostNewDictionaryPostRequest
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryPostNewPilePost200Response
 */
export interface DictionaryPostNewPilePost200Response {
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostNewPilePost200Response
     */
    'message'?: string;
    /**
     * 
     * @type {DictionaryPostNewPilePost200ResponsePile}
     * @memberof DictionaryPostNewPilePost200Response
     */
    'pile'?: DictionaryPostNewPilePost200ResponsePile;
}
/**
 * 
 * @export
 * @interface DictionaryPostNewPilePost200ResponsePile
 */
export interface DictionaryPostNewPilePost200ResponsePile {
    /**
     * 
     * @type {Array<any>}
     * @memberof DictionaryPostNewPilePost200ResponsePile
     */
    'items'?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostNewPilePost200ResponsePile
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostNewPilePost200ResponsePile
     */
    'creator'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof DictionaryPostNewPilePost200ResponsePile
     */
    'piles'?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostNewPilePost200ResponsePile
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostNewPilePost200ResponsePile
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostNewPilePost200ResponsePile
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostNewPilePost200ResponsePile
     */
    'parent'?: string;
    /**
     * 
     * @type {number}
     * @memberof DictionaryPostNewPilePost200ResponsePile
     */
    '__v'?: number;
}
/**
 * 
 * @export
 * @interface DictionaryPostNewPilePostRequest
 */
export interface DictionaryPostNewPilePostRequest {
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostNewPilePostRequest
     */
    'dictionaryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPostNewPilePostRequest
     */
    'label'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryPutMovePileWordPut200Response
 */
export interface DictionaryPutMovePileWordPut200Response {
    /**
     * 
     * @type {string}
     * @memberof DictionaryPutMovePileWordPut200Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryPutMovePileWordPut400Response
 */
export interface DictionaryPutMovePileWordPut400Response {
    /**
     * 
     * @type {string}
     * @memberof DictionaryPutMovePileWordPut400Response
     */
    'message'?: string;
    /**
     * 
     * @type {DictionaryPutMovePileWordPut400ResponseError}
     * @memberof DictionaryPutMovePileWordPut400Response
     */
    'error'?: DictionaryPutMovePileWordPut400ResponseError;
}
/**
 * 
 * @export
 * @interface DictionaryPutMovePileWordPut400ResponseError
 */
export interface DictionaryPutMovePileWordPut400ResponseError {
    /**
     * 
     * @type {number}
     * @memberof DictionaryPutMovePileWordPut400ResponseError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPutMovePileWordPut400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPutMovePileWordPut400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryPutMovePileWordPutRequest
 */
export interface DictionaryPutMovePileWordPutRequest {
    /**
     * 
     * @type {string}
     * @memberof DictionaryPutMovePileWordPutRequest
     */
    'incomingPileId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPutMovePileWordPutRequest
     */
    'outgoingPileId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPutMovePileWordPutRequest
     */
    'wordId'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryPutUpdateDictionaryPut200Response
 */
export interface DictionaryPutUpdateDictionaryPut200Response {
    /**
     * 
     * @type {string}
     * @memberof DictionaryPutUpdateDictionaryPut200Response
     */
    'message'?: string;
    /**
     * 
     * @type {DictionaryPutUpdateDictionaryPut200ResponseDictionary}
     * @memberof DictionaryPutUpdateDictionaryPut200Response
     */
    'dictionary'?: DictionaryPutUpdateDictionaryPut200ResponseDictionary;
}
/**
 * 
 * @export
 * @interface DictionaryPutUpdateDictionaryPut200ResponseDictionary
 */
export interface DictionaryPutUpdateDictionaryPut200ResponseDictionary {
    /**
     * 
     * @type {string}
     * @memberof DictionaryPutUpdateDictionaryPut200ResponseDictionary
     */
    '_id'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof DictionaryPutUpdateDictionaryPut200ResponseDictionary
     */
    'items'?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPutUpdateDictionaryPut200ResponseDictionary
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPutUpdateDictionaryPut200ResponseDictionary
     */
    'creator'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DictionaryPutUpdateDictionaryPut200ResponseDictionary
     */
    'piles'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPutUpdateDictionaryPut200ResponseDictionary
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPutUpdateDictionaryPut200ResponseDictionary
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof DictionaryPutUpdateDictionaryPut200ResponseDictionary
     */
    '__v'?: number;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPutUpdateDictionaryPut200ResponseDictionary
     */
    'defaultPile'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryPutUpdateDictionaryPutRequest
 */
export interface DictionaryPutUpdateDictionaryPutRequest {
    /**
     * 
     * @type {string}
     * @memberof DictionaryPutUpdateDictionaryPutRequest
     */
    'dictionaryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPutUpdateDictionaryPutRequest
     */
    'label'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPutUpdateDictionaryPutRequest
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPutUpdateDictionaryPutRequest
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryPutUpdateWordPut200Response
 */
export interface DictionaryPutUpdateWordPut200Response {
    /**
     * 
     * @type {string}
     * @memberof DictionaryPutUpdateWordPut200Response
     */
    'message'?: string;
    /**
     * 
     * @type {DictionaryPutUpdateWordPut200ResponseWord}
     * @memberof DictionaryPutUpdateWordPut200Response
     */
    'word'?: DictionaryPutUpdateWordPut200ResponseWord;
}
/**
 * 
 * @export
 * @interface DictionaryPutUpdateWordPut200ResponseWord
 */
export interface DictionaryPutUpdateWordPut200ResponseWord {
    /**
     * 
     * @type {string}
     * @memberof DictionaryPutUpdateWordPut200ResponseWord
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPutUpdateWordPut200ResponseWord
     */
    'word'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPutUpdateWordPut200ResponseWord
     */
    'meaning'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPutUpdateWordPut200ResponseWord
     */
    'creator'?: string;
    /**
     * 
     * @type {number}
     * @memberof DictionaryPutUpdateWordPut200ResponseWord
     */
    'times'?: number;
    /**
     * 
     * @type {number}
     * @memberof DictionaryPutUpdateWordPut200ResponseWord
     */
    '__v'?: number;
}
/**
 * 
 * @export
 * @interface DictionaryPutUpdateWordPut400Response
 */
export interface DictionaryPutUpdateWordPut400Response {
    /**
     * 
     * @type {string}
     * @memberof DictionaryPutUpdateWordPut400Response
     */
    'message'?: string;
    /**
     * 
     * @type {DictionaryPutUpdateWordPut400ResponseError}
     * @memberof DictionaryPutUpdateWordPut400Response
     */
    'error'?: DictionaryPutUpdateWordPut400ResponseError;
}
/**
 * 
 * @export
 * @interface DictionaryPutUpdateWordPut400ResponseError
 */
export interface DictionaryPutUpdateWordPut400ResponseError {
    /**
     * 
     * @type {string}
     * @memberof DictionaryPutUpdateWordPut400ResponseError
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPutUpdateWordPut400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPutUpdateWordPut400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface DictionaryPutUpdateWordPutRequest
 */
export interface DictionaryPutUpdateWordPutRequest {
    /**
     * 
     * @type {string}
     * @memberof DictionaryPutUpdateWordPutRequest
     */
    'wordId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPutUpdateWordPutRequest
     */
    'word'?: string;
    /**
     * 
     * @type {string}
     * @memberof DictionaryPutUpdateWordPutRequest
     */
    'meaning'?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary /categories/get/getAll
         * @param {string} [studyLanguage] 
         * @param {string} [knownLanguage] 
         * @param {string} [type] 
         * @param {string} [types] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesGetGetAllGet: async (studyLanguage?: string, knownLanguage?: string, type?: string, types?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/categories/get/getAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (studyLanguage !== undefined) {
                localVarQueryParameter['studyLanguage'] = studyLanguage;
            }

            if (knownLanguage !== undefined) {
                localVarQueryParameter['knownLanguage'] = knownLanguage;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (types !== undefined) {
                localVarQueryParameter['types'] = types;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /categories/post/create
         * @param {CategoriesPostCreatePostRequest} categoriesPostCreatePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesPostCreatePost: async (categoriesPostCreatePostRequest: CategoriesPostCreatePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoriesPostCreatePostRequest' is not null or undefined
            assertParamExists('categoriesPostCreatePost', 'categoriesPostCreatePostRequest', categoriesPostCreatePostRequest)
            const localVarPath = `/categories/post/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoriesPostCreatePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary /categories/get/getAll
         * @param {string} [studyLanguage] 
         * @param {string} [knownLanguage] 
         * @param {string} [type] 
         * @param {string} [types] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesGetGetAllGet(studyLanguage?: string, knownLanguage?: string, type?: string, types?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoriesGetGetAllGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesGetGetAllGet(studyLanguage, knownLanguage, type, types, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /categories/post/create
         * @param {CategoriesPostCreatePostRequest} categoriesPostCreatePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesPostCreatePost(categoriesPostCreatePostRequest: CategoriesPostCreatePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoriesPostCreatePost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesPostCreatePost(categoriesPostCreatePostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary /categories/get/getAll
         * @param {string} [studyLanguage] 
         * @param {string} [knownLanguage] 
         * @param {string} [type] 
         * @param {string} [types] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesGetGetAllGet(studyLanguage?: string, knownLanguage?: string, type?: string, types?: string, options?: any): AxiosPromise<CategoriesGetGetAllGet200Response> {
            return localVarFp.categoriesGetGetAllGet(studyLanguage, knownLanguage, type, types, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /categories/post/create
         * @param {CategoriesPostCreatePostRequest} categoriesPostCreatePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesPostCreatePost(categoriesPostCreatePostRequest: CategoriesPostCreatePostRequest, options?: any): AxiosPromise<CategoriesPostCreatePost200Response> {
            return localVarFp.categoriesPostCreatePost(categoriesPostCreatePostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary /categories/get/getAll
     * @param {string} [studyLanguage] 
     * @param {string} [knownLanguage] 
     * @param {string} [type] 
     * @param {string} [types] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public categoriesGetGetAllGet(studyLanguage?: string, knownLanguage?: string, type?: string, types?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).categoriesGetGetAllGet(studyLanguage, knownLanguage, type, types, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /categories/post/create
     * @param {CategoriesPostCreatePostRequest} categoriesPostCreatePostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public categoriesPostCreatePost(categoriesPostCreatePostRequest: CategoriesPostCreatePostRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).categoriesPostCreatePost(categoriesPostCreatePostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DictionaryApi - axios parameter creator
 * @export
 */
export const DictionaryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary /categories/put/addDictionaryToCategory
         * @param {CategoriesPutAddDictionaryToCategoryPutRequest} categoriesPutAddDictionaryToCategoryPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesPutAddDictionaryToCategoryPut: async (categoriesPutAddDictionaryToCategoryPutRequest: CategoriesPutAddDictionaryToCategoryPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoriesPutAddDictionaryToCategoryPutRequest' is not null or undefined
            assertParamExists('categoriesPutAddDictionaryToCategoryPut', 'categoriesPutAddDictionaryToCategoryPutRequest', categoriesPutAddDictionaryToCategoryPutRequest)
            const localVarPath = `/categories/put/addDictionaryToCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(categoriesPutAddDictionaryToCategoryPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /dictionary/delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryDeleteDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dictionary/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /dictionary/delete/remove
         * @param {DictionaryDeleteRemoveDeleteRequest} dictionaryDeleteRemoveDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryDeleteRemoveDelete: async (dictionaryDeleteRemoveDeleteRequest: DictionaryDeleteRemoveDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dictionaryDeleteRemoveDeleteRequest' is not null or undefined
            assertParamExists('dictionaryDeleteRemoveDelete', 'dictionaryDeleteRemoveDeleteRequest', dictionaryDeleteRemoveDeleteRequest)
            const localVarPath = `/dictionary/delete/remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dictionaryDeleteRemoveDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /dictionary/delete/removePile
         * @param {DictionaryDeleteRemovePileDeleteRequest} dictionaryDeleteRemovePileDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryDeleteRemovePileDelete: async (dictionaryDeleteRemovePileDeleteRequest: DictionaryDeleteRemovePileDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dictionaryDeleteRemovePileDeleteRequest' is not null or undefined
            assertParamExists('dictionaryDeleteRemovePileDelete', 'dictionaryDeleteRemovePileDeleteRequest', dictionaryDeleteRemovePileDeleteRequest)
            const localVarPath = `/dictionary/delete/removePile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dictionaryDeleteRemovePileDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /dictionary/delete/removeWord
         * @param {DictionaryDeleteRemoveWordDeleteRequest} dictionaryDeleteRemoveWordDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryDeleteRemoveWordDelete: async (dictionaryDeleteRemoveWordDeleteRequest: DictionaryDeleteRemoveWordDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dictionaryDeleteRemoveWordDeleteRequest' is not null or undefined
            assertParamExists('dictionaryDeleteRemoveWordDelete', 'dictionaryDeleteRemoveWordDeleteRequest', dictionaryDeleteRemoveWordDeleteRequest)
            const localVarPath = `/dictionary/delete/removeWord`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dictionaryDeleteRemoveWordDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /dictionary/delete/removeWordPile
         * @param {DictionaryDeleteRemoveWordPileDeleteRequest} dictionaryDeleteRemoveWordPileDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryDeleteRemoveWordPileDelete: async (dictionaryDeleteRemoveWordPileDeleteRequest: DictionaryDeleteRemoveWordPileDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dictionaryDeleteRemoveWordPileDeleteRequest' is not null or undefined
            assertParamExists('dictionaryDeleteRemoveWordPileDelete', 'dictionaryDeleteRemoveWordPileDeleteRequest', dictionaryDeleteRemoveWordPileDeleteRequest)
            const localVarPath = `/dictionary/delete/removeWordPile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dictionaryDeleteRemoveWordPileDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /dictionary/get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryGetGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dictionary/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /dictionary/get/getAll
         * @param {DictionaryGetGetAllGetRequest} dictionaryGetGetAllGetRequest 
         * @param {string} [studyLanguage] 
         * @param {string} [knownLanguage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryGetGetAllGet: async (dictionaryGetGetAllGetRequest: DictionaryGetGetAllGetRequest, studyLanguage?: string, knownLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dictionaryGetGetAllGetRequest' is not null or undefined
            assertParamExists('dictionaryGetGetAllGet', 'dictionaryGetGetAllGetRequest', dictionaryGetGetAllGetRequest)
            const localVarPath = `/dictionary/get/getAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (studyLanguage !== undefined) {
                localVarQueryParameter['studyLanguage'] = studyLanguage;
            }

            if (knownLanguage !== undefined) {
                localVarQueryParameter['knownLanguage'] = knownLanguage;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dictionaryGetGetAllGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /dictionary/get/get
         * @param {string} [dictionaryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryGetGetGet: async (dictionaryId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dictionary/get/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (dictionaryId !== undefined) {
                localVarQueryParameter['dictionaryId'] = dictionaryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /dictionary/get/getPiles
         * @param {string} [dictionaryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryGetGetPilesGet: async (dictionaryId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dictionary/get/getPiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (dictionaryId !== undefined) {
                localVarQueryParameter['dictionaryId'] = dictionaryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /dictionary/get/getSome
         * @param {string} [dictionaryIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryGetGetSomeGet: async (dictionaryIds?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dictionary/get/getSome`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (dictionaryIds !== undefined) {
                localVarQueryParameter['dictionaryIds'] = dictionaryIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /dictionary/get/getWordList
         * @param {string} [dictionaryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryGetGetWordListGet: async (dictionaryId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dictionary/get/getWordList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (dictionaryId !== undefined) {
                localVarQueryParameter['dictionaryId'] = dictionaryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /dictionary/post/addWord
         * @param {DictionaryPostAddWordPostRequest} dictionaryPostAddWordPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryPostAddWordPost: async (dictionaryPostAddWordPostRequest: DictionaryPostAddWordPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dictionaryPostAddWordPostRequest' is not null or undefined
            assertParamExists('dictionaryPostAddWordPost', 'dictionaryPostAddWordPostRequest', dictionaryPostAddWordPostRequest)
            const localVarPath = `/dictionary/post/addWord`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dictionaryPostAddWordPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /dictionary/post/addWordWithDuplicateCheck
         * @param {DictionaryPostAddWordWithDuplicateCheckPostRequest} dictionaryPostAddWordWithDuplicateCheckPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryPostAddWordWithDuplicateCheckPost: async (dictionaryPostAddWordWithDuplicateCheckPostRequest: DictionaryPostAddWordWithDuplicateCheckPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dictionaryPostAddWordWithDuplicateCheckPostRequest' is not null or undefined
            assertParamExists('dictionaryPostAddWordWithDuplicateCheckPost', 'dictionaryPostAddWordWithDuplicateCheckPostRequest', dictionaryPostAddWordWithDuplicateCheckPostRequest)
            const localVarPath = `/dictionary/post/addWordWithDuplicateCheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dictionaryPostAddWordWithDuplicateCheckPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /dictionary/post/copy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryPostCopyPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dictionary/post/copy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /dictionary/post/newDictionary
         * @param {DictionaryPostNewDictionaryPostRequest} dictionaryPostNewDictionaryPostRequest 
         * @param {string} [studyLanguage] 
         * @param {string} [sourceLanguage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryPostNewDictionaryPost: async (dictionaryPostNewDictionaryPostRequest: DictionaryPostNewDictionaryPostRequest, studyLanguage?: string, sourceLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dictionaryPostNewDictionaryPostRequest' is not null or undefined
            assertParamExists('dictionaryPostNewDictionaryPost', 'dictionaryPostNewDictionaryPostRequest', dictionaryPostNewDictionaryPostRequest)
            const localVarPath = `/dictionary/post/newDictionary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (studyLanguage !== undefined) {
                localVarQueryParameter['studyLanguage'] = studyLanguage;
            }

            if (sourceLanguage !== undefined) {
                localVarQueryParameter['sourceLanguage'] = sourceLanguage;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dictionaryPostNewDictionaryPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /dictionary/post/newPile
         * @param {DictionaryPostNewPilePostRequest} dictionaryPostNewPilePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryPostNewPilePost: async (dictionaryPostNewPilePostRequest: DictionaryPostNewPilePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dictionaryPostNewPilePostRequest' is not null or undefined
            assertParamExists('dictionaryPostNewPilePost', 'dictionaryPostNewPilePostRequest', dictionaryPostNewPilePostRequest)
            const localVarPath = `/dictionary/post/newPile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dictionaryPostNewPilePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /dictionary/post
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryPostPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dictionary/post`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /dictionary/put/movePileWord
         * @param {DictionaryPutMovePileWordPutRequest} dictionaryPutMovePileWordPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryPutMovePileWordPut: async (dictionaryPutMovePileWordPutRequest: DictionaryPutMovePileWordPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dictionaryPutMovePileWordPutRequest' is not null or undefined
            assertParamExists('dictionaryPutMovePileWordPut', 'dictionaryPutMovePileWordPutRequest', dictionaryPutMovePileWordPutRequest)
            const localVarPath = `/dictionary/put/movePileWord`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dictionaryPutMovePileWordPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /dictionary/put
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryPutPut: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dictionary/put`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /dictionary/put/updateDictionary
         * @param {DictionaryPutUpdateDictionaryPutRequest} dictionaryPutUpdateDictionaryPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryPutUpdateDictionaryPut: async (dictionaryPutUpdateDictionaryPutRequest: DictionaryPutUpdateDictionaryPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dictionaryPutUpdateDictionaryPutRequest' is not null or undefined
            assertParamExists('dictionaryPutUpdateDictionaryPut', 'dictionaryPutUpdateDictionaryPutRequest', dictionaryPutUpdateDictionaryPutRequest)
            const localVarPath = `/dictionary/put/updateDictionary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dictionaryPutUpdateDictionaryPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /dictionary/put/updateWord
         * @param {DictionaryPutUpdateWordPutRequest} dictionaryPutUpdateWordPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryPutUpdateWordPut: async (dictionaryPutUpdateWordPutRequest: DictionaryPutUpdateWordPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dictionaryPutUpdateWordPutRequest' is not null or undefined
            assertParamExists('dictionaryPutUpdateWordPut', 'dictionaryPutUpdateWordPutRequest', dictionaryPutUpdateWordPutRequest)
            const localVarPath = `/dictionary/put/updateWord`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dictionaryPutUpdateWordPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DictionaryApi - functional programming interface
 * @export
 */
export const DictionaryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DictionaryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary /categories/put/addDictionaryToCategory
         * @param {CategoriesPutAddDictionaryToCategoryPutRequest} categoriesPutAddDictionaryToCategoryPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesPutAddDictionaryToCategoryPut(categoriesPutAddDictionaryToCategoryPutRequest: CategoriesPutAddDictionaryToCategoryPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoriesPutAddDictionaryToCategoryPut200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesPutAddDictionaryToCategoryPut(categoriesPutAddDictionaryToCategoryPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /dictionary/delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dictionaryDeleteDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dictionaryDeleteDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /dictionary/delete/remove
         * @param {DictionaryDeleteRemoveDeleteRequest} dictionaryDeleteRemoveDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dictionaryDeleteRemoveDelete(dictionaryDeleteRemoveDeleteRequest: DictionaryDeleteRemoveDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DictionaryDeleteRemoveDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dictionaryDeleteRemoveDelete(dictionaryDeleteRemoveDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /dictionary/delete/removePile
         * @param {DictionaryDeleteRemovePileDeleteRequest} dictionaryDeleteRemovePileDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dictionaryDeleteRemovePileDelete(dictionaryDeleteRemovePileDeleteRequest: DictionaryDeleteRemovePileDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DictionaryDeleteRemovePileDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dictionaryDeleteRemovePileDelete(dictionaryDeleteRemovePileDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /dictionary/delete/removeWord
         * @param {DictionaryDeleteRemoveWordDeleteRequest} dictionaryDeleteRemoveWordDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dictionaryDeleteRemoveWordDelete(dictionaryDeleteRemoveWordDeleteRequest: DictionaryDeleteRemoveWordDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DictionaryDeleteRemoveWordDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dictionaryDeleteRemoveWordDelete(dictionaryDeleteRemoveWordDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /dictionary/delete/removeWordPile
         * @param {DictionaryDeleteRemoveWordPileDeleteRequest} dictionaryDeleteRemoveWordPileDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dictionaryDeleteRemoveWordPileDelete(dictionaryDeleteRemoveWordPileDeleteRequest: DictionaryDeleteRemoveWordPileDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DictionaryDeleteRemoveWordPileDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dictionaryDeleteRemoveWordPileDelete(dictionaryDeleteRemoveWordPileDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /dictionary/get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dictionaryGetGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dictionaryGetGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /dictionary/get/getAll
         * @param {DictionaryGetGetAllGetRequest} dictionaryGetGetAllGetRequest 
         * @param {string} [studyLanguage] 
         * @param {string} [knownLanguage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dictionaryGetGetAllGet(dictionaryGetGetAllGetRequest: DictionaryGetGetAllGetRequest, studyLanguage?: string, knownLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DictionaryGetGetAllGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dictionaryGetGetAllGet(dictionaryGetGetAllGetRequest, studyLanguage, knownLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /dictionary/get/get
         * @param {string} [dictionaryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dictionaryGetGetGet(dictionaryId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DictionaryGetGetGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dictionaryGetGetGet(dictionaryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /dictionary/get/getPiles
         * @param {string} [dictionaryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dictionaryGetGetPilesGet(dictionaryId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DictionaryGetGetPilesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dictionaryGetGetPilesGet(dictionaryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /dictionary/get/getSome
         * @param {string} [dictionaryIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dictionaryGetGetSomeGet(dictionaryIds?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DictionaryGetGetSomeGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dictionaryGetGetSomeGet(dictionaryIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /dictionary/get/getWordList
         * @param {string} [dictionaryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dictionaryGetGetWordListGet(dictionaryId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DictionaryGetGetWordListGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dictionaryGetGetWordListGet(dictionaryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /dictionary/post/addWord
         * @param {DictionaryPostAddWordPostRequest} dictionaryPostAddWordPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dictionaryPostAddWordPost(dictionaryPostAddWordPostRequest: DictionaryPostAddWordPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DictionaryPostAddWordPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dictionaryPostAddWordPost(dictionaryPostAddWordPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /dictionary/post/addWordWithDuplicateCheck
         * @param {DictionaryPostAddWordWithDuplicateCheckPostRequest} dictionaryPostAddWordWithDuplicateCheckPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dictionaryPostAddWordWithDuplicateCheckPost(dictionaryPostAddWordWithDuplicateCheckPostRequest: DictionaryPostAddWordWithDuplicateCheckPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DictionaryPostAddWordWithDuplicateCheckPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dictionaryPostAddWordWithDuplicateCheckPost(dictionaryPostAddWordWithDuplicateCheckPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /dictionary/post/copy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dictionaryPostCopyPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DictionaryPostCopyPost201Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dictionaryPostCopyPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /dictionary/post/newDictionary
         * @param {DictionaryPostNewDictionaryPostRequest} dictionaryPostNewDictionaryPostRequest 
         * @param {string} [studyLanguage] 
         * @param {string} [sourceLanguage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dictionaryPostNewDictionaryPost(dictionaryPostNewDictionaryPostRequest: DictionaryPostNewDictionaryPostRequest, studyLanguage?: string, sourceLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DictionaryPostNewDictionaryPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dictionaryPostNewDictionaryPost(dictionaryPostNewDictionaryPostRequest, studyLanguage, sourceLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /dictionary/post/newPile
         * @param {DictionaryPostNewPilePostRequest} dictionaryPostNewPilePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dictionaryPostNewPilePost(dictionaryPostNewPilePostRequest: DictionaryPostNewPilePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DictionaryPostNewPilePost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dictionaryPostNewPilePost(dictionaryPostNewPilePostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /dictionary/post
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dictionaryPostPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dictionaryPostPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /dictionary/put/movePileWord
         * @param {DictionaryPutMovePileWordPutRequest} dictionaryPutMovePileWordPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dictionaryPutMovePileWordPut(dictionaryPutMovePileWordPutRequest: DictionaryPutMovePileWordPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DictionaryPutMovePileWordPut200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dictionaryPutMovePileWordPut(dictionaryPutMovePileWordPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /dictionary/put
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dictionaryPutPut(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dictionaryPutPut(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /dictionary/put/updateDictionary
         * @param {DictionaryPutUpdateDictionaryPutRequest} dictionaryPutUpdateDictionaryPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dictionaryPutUpdateDictionaryPut(dictionaryPutUpdateDictionaryPutRequest: DictionaryPutUpdateDictionaryPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DictionaryPutUpdateDictionaryPut200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dictionaryPutUpdateDictionaryPut(dictionaryPutUpdateDictionaryPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /dictionary/put/updateWord
         * @param {DictionaryPutUpdateWordPutRequest} dictionaryPutUpdateWordPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dictionaryPutUpdateWordPut(dictionaryPutUpdateWordPutRequest: DictionaryPutUpdateWordPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DictionaryPutUpdateWordPut200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dictionaryPutUpdateWordPut(dictionaryPutUpdateWordPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DictionaryApi - factory interface
 * @export
 */
export const DictionaryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DictionaryApiFp(configuration)
    return {
        /**
         * 
         * @summary /categories/put/addDictionaryToCategory
         * @param {CategoriesPutAddDictionaryToCategoryPutRequest} categoriesPutAddDictionaryToCategoryPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesPutAddDictionaryToCategoryPut(categoriesPutAddDictionaryToCategoryPutRequest: CategoriesPutAddDictionaryToCategoryPutRequest, options?: any): AxiosPromise<CategoriesPutAddDictionaryToCategoryPut200Response> {
            return localVarFp.categoriesPutAddDictionaryToCategoryPut(categoriesPutAddDictionaryToCategoryPutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /dictionary/delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryDeleteDelete(options?: any): AxiosPromise<void> {
            return localVarFp.dictionaryDeleteDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /dictionary/delete/remove
         * @param {DictionaryDeleteRemoveDeleteRequest} dictionaryDeleteRemoveDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryDeleteRemoveDelete(dictionaryDeleteRemoveDeleteRequest: DictionaryDeleteRemoveDeleteRequest, options?: any): AxiosPromise<DictionaryDeleteRemoveDelete200Response> {
            return localVarFp.dictionaryDeleteRemoveDelete(dictionaryDeleteRemoveDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /dictionary/delete/removePile
         * @param {DictionaryDeleteRemovePileDeleteRequest} dictionaryDeleteRemovePileDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryDeleteRemovePileDelete(dictionaryDeleteRemovePileDeleteRequest: DictionaryDeleteRemovePileDeleteRequest, options?: any): AxiosPromise<DictionaryDeleteRemovePileDelete200Response> {
            return localVarFp.dictionaryDeleteRemovePileDelete(dictionaryDeleteRemovePileDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /dictionary/delete/removeWord
         * @param {DictionaryDeleteRemoveWordDeleteRequest} dictionaryDeleteRemoveWordDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryDeleteRemoveWordDelete(dictionaryDeleteRemoveWordDeleteRequest: DictionaryDeleteRemoveWordDeleteRequest, options?: any): AxiosPromise<DictionaryDeleteRemoveWordDelete200Response> {
            return localVarFp.dictionaryDeleteRemoveWordDelete(dictionaryDeleteRemoveWordDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /dictionary/delete/removeWordPile
         * @param {DictionaryDeleteRemoveWordPileDeleteRequest} dictionaryDeleteRemoveWordPileDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryDeleteRemoveWordPileDelete(dictionaryDeleteRemoveWordPileDeleteRequest: DictionaryDeleteRemoveWordPileDeleteRequest, options?: any): AxiosPromise<DictionaryDeleteRemoveWordPileDelete200Response> {
            return localVarFp.dictionaryDeleteRemoveWordPileDelete(dictionaryDeleteRemoveWordPileDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /dictionary/get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryGetGet(options?: any): AxiosPromise<void> {
            return localVarFp.dictionaryGetGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /dictionary/get/getAll
         * @param {DictionaryGetGetAllGetRequest} dictionaryGetGetAllGetRequest 
         * @param {string} [studyLanguage] 
         * @param {string} [knownLanguage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryGetGetAllGet(dictionaryGetGetAllGetRequest: DictionaryGetGetAllGetRequest, studyLanguage?: string, knownLanguage?: string, options?: any): AxiosPromise<DictionaryGetGetAllGet200Response> {
            return localVarFp.dictionaryGetGetAllGet(dictionaryGetGetAllGetRequest, studyLanguage, knownLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /dictionary/get/get
         * @param {string} [dictionaryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryGetGetGet(dictionaryId?: string, options?: any): AxiosPromise<DictionaryGetGetGet200Response> {
            return localVarFp.dictionaryGetGetGet(dictionaryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /dictionary/get/getPiles
         * @param {string} [dictionaryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryGetGetPilesGet(dictionaryId?: string, options?: any): AxiosPromise<DictionaryGetGetPilesGet200Response> {
            return localVarFp.dictionaryGetGetPilesGet(dictionaryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /dictionary/get/getSome
         * @param {string} [dictionaryIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryGetGetSomeGet(dictionaryIds?: string, options?: any): AxiosPromise<DictionaryGetGetSomeGet200Response> {
            return localVarFp.dictionaryGetGetSomeGet(dictionaryIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /dictionary/get/getWordList
         * @param {string} [dictionaryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryGetGetWordListGet(dictionaryId?: string, options?: any): AxiosPromise<DictionaryGetGetWordListGet200Response> {
            return localVarFp.dictionaryGetGetWordListGet(dictionaryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /dictionary/post/addWord
         * @param {DictionaryPostAddWordPostRequest} dictionaryPostAddWordPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryPostAddWordPost(dictionaryPostAddWordPostRequest: DictionaryPostAddWordPostRequest, options?: any): AxiosPromise<DictionaryPostAddWordPost200Response> {
            return localVarFp.dictionaryPostAddWordPost(dictionaryPostAddWordPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /dictionary/post/addWordWithDuplicateCheck
         * @param {DictionaryPostAddWordWithDuplicateCheckPostRequest} dictionaryPostAddWordWithDuplicateCheckPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryPostAddWordWithDuplicateCheckPost(dictionaryPostAddWordWithDuplicateCheckPostRequest: DictionaryPostAddWordWithDuplicateCheckPostRequest, options?: any): AxiosPromise<DictionaryPostAddWordWithDuplicateCheckPost200Response> {
            return localVarFp.dictionaryPostAddWordWithDuplicateCheckPost(dictionaryPostAddWordWithDuplicateCheckPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /dictionary/post/copy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryPostCopyPost(options?: any): AxiosPromise<DictionaryPostCopyPost201Response> {
            return localVarFp.dictionaryPostCopyPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /dictionary/post/newDictionary
         * @param {DictionaryPostNewDictionaryPostRequest} dictionaryPostNewDictionaryPostRequest 
         * @param {string} [studyLanguage] 
         * @param {string} [sourceLanguage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryPostNewDictionaryPost(dictionaryPostNewDictionaryPostRequest: DictionaryPostNewDictionaryPostRequest, studyLanguage?: string, sourceLanguage?: string, options?: any): AxiosPromise<DictionaryPostNewDictionaryPost200Response> {
            return localVarFp.dictionaryPostNewDictionaryPost(dictionaryPostNewDictionaryPostRequest, studyLanguage, sourceLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /dictionary/post/newPile
         * @param {DictionaryPostNewPilePostRequest} dictionaryPostNewPilePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryPostNewPilePost(dictionaryPostNewPilePostRequest: DictionaryPostNewPilePostRequest, options?: any): AxiosPromise<DictionaryPostNewPilePost200Response> {
            return localVarFp.dictionaryPostNewPilePost(dictionaryPostNewPilePostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /dictionary/post
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryPostPost(options?: any): AxiosPromise<void> {
            return localVarFp.dictionaryPostPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /dictionary/put/movePileWord
         * @param {DictionaryPutMovePileWordPutRequest} dictionaryPutMovePileWordPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryPutMovePileWordPut(dictionaryPutMovePileWordPutRequest: DictionaryPutMovePileWordPutRequest, options?: any): AxiosPromise<DictionaryPutMovePileWordPut200Response> {
            return localVarFp.dictionaryPutMovePileWordPut(dictionaryPutMovePileWordPutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /dictionary/put
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryPutPut(options?: any): AxiosPromise<void> {
            return localVarFp.dictionaryPutPut(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /dictionary/put/updateDictionary
         * @param {DictionaryPutUpdateDictionaryPutRequest} dictionaryPutUpdateDictionaryPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryPutUpdateDictionaryPut(dictionaryPutUpdateDictionaryPutRequest: DictionaryPutUpdateDictionaryPutRequest, options?: any): AxiosPromise<DictionaryPutUpdateDictionaryPut200Response> {
            return localVarFp.dictionaryPutUpdateDictionaryPut(dictionaryPutUpdateDictionaryPutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /dictionary/put/updateWord
         * @param {DictionaryPutUpdateWordPutRequest} dictionaryPutUpdateWordPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dictionaryPutUpdateWordPut(dictionaryPutUpdateWordPutRequest: DictionaryPutUpdateWordPutRequest, options?: any): AxiosPromise<DictionaryPutUpdateWordPut200Response> {
            return localVarFp.dictionaryPutUpdateWordPut(dictionaryPutUpdateWordPutRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DictionaryApi - object-oriented interface
 * @export
 * @class DictionaryApi
 * @extends {BaseAPI}
 */
export class DictionaryApi extends BaseAPI {
    /**
     * 
     * @summary /categories/put/addDictionaryToCategory
     * @param {CategoriesPutAddDictionaryToCategoryPutRequest} categoriesPutAddDictionaryToCategoryPutRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public categoriesPutAddDictionaryToCategoryPut(categoriesPutAddDictionaryToCategoryPutRequest: CategoriesPutAddDictionaryToCategoryPutRequest, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).categoriesPutAddDictionaryToCategoryPut(categoriesPutAddDictionaryToCategoryPutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /dictionary/delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public dictionaryDeleteDelete(options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).dictionaryDeleteDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /dictionary/delete/remove
     * @param {DictionaryDeleteRemoveDeleteRequest} dictionaryDeleteRemoveDeleteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public dictionaryDeleteRemoveDelete(dictionaryDeleteRemoveDeleteRequest: DictionaryDeleteRemoveDeleteRequest, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).dictionaryDeleteRemoveDelete(dictionaryDeleteRemoveDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /dictionary/delete/removePile
     * @param {DictionaryDeleteRemovePileDeleteRequest} dictionaryDeleteRemovePileDeleteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public dictionaryDeleteRemovePileDelete(dictionaryDeleteRemovePileDeleteRequest: DictionaryDeleteRemovePileDeleteRequest, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).dictionaryDeleteRemovePileDelete(dictionaryDeleteRemovePileDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /dictionary/delete/removeWord
     * @param {DictionaryDeleteRemoveWordDeleteRequest} dictionaryDeleteRemoveWordDeleteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public dictionaryDeleteRemoveWordDelete(dictionaryDeleteRemoveWordDeleteRequest: DictionaryDeleteRemoveWordDeleteRequest, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).dictionaryDeleteRemoveWordDelete(dictionaryDeleteRemoveWordDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /dictionary/delete/removeWordPile
     * @param {DictionaryDeleteRemoveWordPileDeleteRequest} dictionaryDeleteRemoveWordPileDeleteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public dictionaryDeleteRemoveWordPileDelete(dictionaryDeleteRemoveWordPileDeleteRequest: DictionaryDeleteRemoveWordPileDeleteRequest, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).dictionaryDeleteRemoveWordPileDelete(dictionaryDeleteRemoveWordPileDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /dictionary/get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public dictionaryGetGet(options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).dictionaryGetGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /dictionary/get/getAll
     * @param {DictionaryGetGetAllGetRequest} dictionaryGetGetAllGetRequest 
     * @param {string} [studyLanguage] 
     * @param {string} [knownLanguage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public dictionaryGetGetAllGet(dictionaryGetGetAllGetRequest: DictionaryGetGetAllGetRequest, studyLanguage?: string, knownLanguage?: string, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).dictionaryGetGetAllGet(dictionaryGetGetAllGetRequest, studyLanguage, knownLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /dictionary/get/get
     * @param {string} [dictionaryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public dictionaryGetGetGet(dictionaryId?: string, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).dictionaryGetGetGet(dictionaryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /dictionary/get/getPiles
     * @param {string} [dictionaryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public dictionaryGetGetPilesGet(dictionaryId?: string, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).dictionaryGetGetPilesGet(dictionaryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /dictionary/get/getSome
     * @param {string} [dictionaryIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public dictionaryGetGetSomeGet(dictionaryIds?: string, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).dictionaryGetGetSomeGet(dictionaryIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /dictionary/get/getWordList
     * @param {string} [dictionaryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public dictionaryGetGetWordListGet(dictionaryId?: string, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).dictionaryGetGetWordListGet(dictionaryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /dictionary/post/addWord
     * @param {DictionaryPostAddWordPostRequest} dictionaryPostAddWordPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public dictionaryPostAddWordPost(dictionaryPostAddWordPostRequest: DictionaryPostAddWordPostRequest, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).dictionaryPostAddWordPost(dictionaryPostAddWordPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /dictionary/post/addWordWithDuplicateCheck
     * @param {DictionaryPostAddWordWithDuplicateCheckPostRequest} dictionaryPostAddWordWithDuplicateCheckPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public dictionaryPostAddWordWithDuplicateCheckPost(dictionaryPostAddWordWithDuplicateCheckPostRequest: DictionaryPostAddWordWithDuplicateCheckPostRequest, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).dictionaryPostAddWordWithDuplicateCheckPost(dictionaryPostAddWordWithDuplicateCheckPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /dictionary/post/copy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public dictionaryPostCopyPost(options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).dictionaryPostCopyPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /dictionary/post/newDictionary
     * @param {DictionaryPostNewDictionaryPostRequest} dictionaryPostNewDictionaryPostRequest 
     * @param {string} [studyLanguage] 
     * @param {string} [sourceLanguage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public dictionaryPostNewDictionaryPost(dictionaryPostNewDictionaryPostRequest: DictionaryPostNewDictionaryPostRequest, studyLanguage?: string, sourceLanguage?: string, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).dictionaryPostNewDictionaryPost(dictionaryPostNewDictionaryPostRequest, studyLanguage, sourceLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /dictionary/post/newPile
     * @param {DictionaryPostNewPilePostRequest} dictionaryPostNewPilePostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public dictionaryPostNewPilePost(dictionaryPostNewPilePostRequest: DictionaryPostNewPilePostRequest, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).dictionaryPostNewPilePost(dictionaryPostNewPilePostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /dictionary/post
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public dictionaryPostPost(options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).dictionaryPostPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /dictionary/put/movePileWord
     * @param {DictionaryPutMovePileWordPutRequest} dictionaryPutMovePileWordPutRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public dictionaryPutMovePileWordPut(dictionaryPutMovePileWordPutRequest: DictionaryPutMovePileWordPutRequest, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).dictionaryPutMovePileWordPut(dictionaryPutMovePileWordPutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /dictionary/put
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public dictionaryPutPut(options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).dictionaryPutPut(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /dictionary/put/updateDictionary
     * @param {DictionaryPutUpdateDictionaryPutRequest} dictionaryPutUpdateDictionaryPutRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public dictionaryPutUpdateDictionaryPut(dictionaryPutUpdateDictionaryPutRequest: DictionaryPutUpdateDictionaryPutRequest, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).dictionaryPutUpdateDictionaryPut(dictionaryPutUpdateDictionaryPutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /dictionary/put/updateWord
     * @param {DictionaryPutUpdateWordPutRequest} dictionaryPutUpdateWordPutRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApi
     */
    public dictionaryPutUpdateWordPut(dictionaryPutUpdateWordPutRequest: DictionaryPutUpdateWordPutRequest, options?: AxiosRequestConfig) {
        return DictionaryApiFp(this.configuration).dictionaryPutUpdateWordPut(dictionaryPutUpdateWordPutRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



