import {
  ReadingItemApi,
  Configuration,
  ReadingItemPostAddPostRequest,
  ReadingItemPutAddWordMeaningPutRequest,
  ReadingItemDeleteRemoveDeleteRequest,
} from "../../../generated/reading";

import { readingServiceBaseURL } from "../../static_data/backend-base-url";

const getClient = (token: string) => {
  return new ReadingItemApi(
    new Configuration({
      apiKey: `Bearer ${token}`,
      accessToken: token,
      basePath: readingServiceBaseURL,
    })
  );
};

export const getAllReadingItems = async (
  token: string,
  studyLanguage: string
) => {
  const client = getClient(token);

  return await client.readingItemGetGetAllGet(studyLanguage, {});
};

export const getReadingItem = async (token: string, readingItemId: string) => {
  const client = getClient(token);

  return await client.readingItemGetGetGet(readingItemId);
};

export const getSomeReadingItems = async (
  token: string,
  readingItemIds: string[]
) => {
  const client = getClient(token);

  return await client.readingItemGetGetSomeGet({}, readingItemIds.join(","));
};

export const searchReadingItems = async (
  token: string,
  categoryId: string,
  query: string
) => {
  const client = getClient(token);

  return await client.readingItemGetSearchGet(query, categoryId);
};

export const filterReadingItemsByAlphabet = async (
  token: string,
  categoryId: string,
  alphabet: string
) => {
  const client = getClient(token);

  return await client.readingItemGetFilterByAlphabetGet(alphabet, categoryId);
};

export const addReadingItem = async (
  token: string,
  params: ReadingItemPostAddPostRequest
) => {
  const client = getClient(token);

  return await client.readingItemPostAddPost(params);
};

export const addWordToReadingItemDictionary = async (
  token: string,
  params: ReadingItemPutAddWordMeaningPutRequest
) => {
  const client = getClient(token);

  return await client.readingItemPutAddWordMeaningPut(params);
};

export const deleteReadingItem = async (
  token: string,
  params: ReadingItemDeleteRemoveDeleteRequest
) => {
  const client = getClient(token);

  return await client.readingItemDeleteRemoveDelete(params);
};

export const copyReadingItem = async (
  token: string,
  readingItemId: string,
  checkDictionaryId: string
) => {
  const client = getClient(token);

  return await client.readingItemPostCopyPost({
    readingItemId,
    // @ts-ignore
    checkDictionaryId,
  });
};
