import {
  Configuration,
  DictionaryApi,
  DictionaryPostAddWordPostRequest,
  DictionaryPostNewDictionaryPostRequest,
  DictionaryPostNewPilePostRequest,
  DictionaryPutUpdateDictionaryPutRequest,
  DictionaryPutUpdateWordPutRequest,
  DictionaryPutMovePileWordPutRequest,
  DictionaryDeleteRemoveDeleteRequest,
  DictionaryDeleteRemovePileDeleteRequest,
  DictionaryDeleteRemoveWordDeleteRequest,
  DefaultApi,
} from "../../../generated/vocabulary";

import { vocabularyServiceBaseURL } from "../../static_data/backend-base-url";

export const getClient = (token: string, type = "dictionary") => {
  const config = new Configuration({
    apiKey: `Bearer ${token}`,
    accessToken: token,
    basePath: vocabularyServiceBaseURL,
  });

  if (type === "dictionary") {
    return new DictionaryApi(config);
  }

  return new DefaultApi(config);
};

export const getAllDictionaries = async (
  token: string,
  studyLanguage: string,
  knownLanguage: string
) => {
  const client = getClient(token) as DictionaryApi;

  return await client.dictionaryGetGetAllGet({}, studyLanguage, knownLanguage);
};

export const getSomeDictionaries = async (
  token: string,
  dictionaryIds: string[]
) => {
  const client = getClient(token) as DictionaryApi;

  return await client.dictionaryGetGetSomeGet(dictionaryIds.join(","));
};

export const getDictionary = async (
  token: string,
  params: { dictionaryId: string }
) => {
  const client = getClient(token) as DictionaryApi;

  return await client.dictionaryGetGetGet(params.dictionaryId);
};

export const getPiles = async (
  token: string,
  params: { dictionaryId: string }
) => {
  const client = getClient(token) as DictionaryApi;

  return await client.dictionaryGetGetPilesGet(params.dictionaryId);
};

export const getWordList = async (
  token: string,
  params: { dictionaryId: string }
) => {
  const client = getClient(token) as DictionaryApi;

  return await client.dictionaryGetGetWordListGet(params.dictionaryId);
};

export const newDictionary = async (
  token: string,
  params: DictionaryPostNewDictionaryPostRequest,
  studyLanguage: string,
  knownLanguage: string
) => {
  const client = getClient(token) as DictionaryApi;

  return await client.dictionaryPostNewDictionaryPost(
    params,
    studyLanguage,
    knownLanguage
  );
};

export const addWordToDictionary = async (
  token: string,
  params: DictionaryPostAddWordPostRequest
) => {
  const client = getClient(token) as DictionaryApi;

  return await client.dictionaryPostAddWordPost(params);
};

export const newPile = async (
  token: string,
  params: DictionaryPostNewPilePostRequest
) => {
  const client = getClient(token) as DictionaryApi;

  return await client.dictionaryPostNewPilePost(params);
};

export const updateDictionary = async (
  token: string,
  params: DictionaryPutUpdateDictionaryPutRequest
) => {
  const client = getClient(token) as DictionaryApi;

  return await client.dictionaryPutUpdateDictionaryPut(params);
};

export const updateWordInDictionary = async (
  token: string,
  params: DictionaryPutUpdateWordPutRequest
) => {
  const client = getClient(token) as DictionaryApi;

  return await client.dictionaryPutUpdateWordPut(params);
};

export const movePileWord = async (
  token: string,
  params: DictionaryPutMovePileWordPutRequest
) => {
  const client = getClient(token) as DictionaryApi;

  return await client.dictionaryPutMovePileWordPut(params);
};

export const deleteDictionary = async (
  token: string,
  params: DictionaryDeleteRemoveDeleteRequest
) => {
  const client = getClient(token) as DictionaryApi;

  return await client.dictionaryDeleteRemoveDelete(params);
};

export const deletePile = async (
  token: string,
  params: DictionaryDeleteRemovePileDeleteRequest
) => {
  const client = getClient(token) as DictionaryApi;

  return await client.dictionaryDeleteRemovePileDelete(params);
};

export const deleteWordFromDictionary = async (
  token: string,
  params: DictionaryDeleteRemoveWordDeleteRequest
) => {
  const client = getClient(token) as DictionaryApi;

  return await client.dictionaryDeleteRemoveWordDelete(params);
};

export const getAllDictionaryCategories = async (
  token: string,
  studyLanguage: string,
  knownLanguage: string,
  types = ["default"]
) => {
  const client = getClient(token, "default") as DefaultApi;

  return await client.categoriesGetGetAllGet(
    studyLanguage,
    knownLanguage,
    "",
    types.join(",")
  );
};

export const copyDictionary = async (
  token: string,
  dictionaryId: string,
  dictionaryItemIds: string[]
) => {
  const client = getClient(token) as DictionaryApi;

  return await client.dictionaryPostCopyPost({
    data: {
      dictionaryId,
      dictionaryItemsIds: dictionaryItemIds.join(","),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
