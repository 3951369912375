/* tslint:disable */
/* eslint-disable */
/**
 * user-sevice
 * Specification JSONs: [v2](/api-spec/v2), [v3](/api-spec/v3).
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'username'?: string;
    /**
     * 
     * @type {UserPreferences}
     * @memberof User
     */
    'preferences'?: UserPreferences;
    /**
     * 
     * @type {Array<UserPersonalDictionariesInner>}
     * @memberof User
     */
    'personalDictionaries'?: Array<UserPersonalDictionariesInner>;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'knownDictionary'?: string;
}
/**
 * 
 * @export
 * @interface UserDeleteLogoutDelete200Response
 */
export interface UserDeleteLogoutDelete200Response {
    /**
     * 
     * @type {string}
     * @memberof UserDeleteLogoutDelete200Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UserDeleteLogoutDelete400Response
 */
export interface UserDeleteLogoutDelete400Response {
    /**
     * 
     * @type {string}
     * @memberof UserDeleteLogoutDelete400Response
     */
    'message'?: string;
    /**
     * 
     * @type {UserDeleteLogoutDelete400ResponseError}
     * @memberof UserDeleteLogoutDelete400Response
     */
    'error'?: UserDeleteLogoutDelete400ResponseError;
}
/**
 * 
 * @export
 * @interface UserDeleteLogoutDelete400ResponseError
 */
export interface UserDeleteLogoutDelete400ResponseError {
    /**
     * 
     * @type {number}
     * @memberof UserDeleteLogoutDelete400ResponseError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDeleteLogoutDelete400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDeleteLogoutDelete400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UserGetGetPersonalDictionaryIdGet200Response
 */
export interface UserGetGetPersonalDictionaryIdGet200Response {
    /**
     * 
     * @type {string}
     * @memberof UserGetGetPersonalDictionaryIdGet200Response
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserGetGetPersonalDictionaryIdGet200Response
     */
    'personalDictionaryId'?: string;
}
/**
 * 
 * @export
 * @interface UserGetGetPersonalDictionaryIdGet400Response
 */
export interface UserGetGetPersonalDictionaryIdGet400Response {
    /**
     * 
     * @type {string}
     * @memberof UserGetGetPersonalDictionaryIdGet400Response
     */
    'message'?: string;
    /**
     * 
     * @type {UserGetGetPersonalDictionaryIdGet400ResponseError}
     * @memberof UserGetGetPersonalDictionaryIdGet400Response
     */
    'error'?: UserGetGetPersonalDictionaryIdGet400ResponseError;
}
/**
 * 
 * @export
 * @interface UserGetGetPersonalDictionaryIdGet400ResponseError
 */
export interface UserGetGetPersonalDictionaryIdGet400ResponseError {
    /**
     * 
     * @type {string}
     * @memberof UserGetGetPersonalDictionaryIdGet400ResponseError
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserGetGetPersonalDictionaryIdGet400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserGetGetPersonalDictionaryIdGet400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UserGetGetPersonalDictionaryIdGet404Response
 */
export interface UserGetGetPersonalDictionaryIdGet404Response {
    /**
     * 
     * @type {string}
     * @memberof UserGetGetPersonalDictionaryIdGet404Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UserGetGetUserPreferencesGet200Response
 */
export interface UserGetGetUserPreferencesGet200Response {
    /**
     * 
     * @type {string}
     * @memberof UserGetGetUserPreferencesGet200Response
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserGetGetUserPreferencesGet200Response
     */
    'status'?: number;
    /**
     * 
     * @type {UserGetGetUserPreferencesGet200ResponseUserPreferences}
     * @memberof UserGetGetUserPreferencesGet200Response
     */
    'userPreferences'?: UserGetGetUserPreferencesGet200ResponseUserPreferences;
}
/**
 * 
 * @export
 * @interface UserGetGetUserPreferencesGet200ResponseUserPreferences
 */
export interface UserGetGetUserPreferencesGet200ResponseUserPreferences {
    /**
     * 
     * @type {UserGetGetUserPreferencesGet200ResponseUserPreferencesLanguages}
     * @memberof UserGetGetUserPreferencesGet200ResponseUserPreferences
     */
    'languages'?: UserGetGetUserPreferencesGet200ResponseUserPreferencesLanguages;
    /**
     * 
     * @type {string}
     * @memberof UserGetGetUserPreferencesGet200ResponseUserPreferences
     */
    'defaultScreen'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserGetGetUserPreferencesGet200ResponseUserPreferences
     */
    '_id'?: string;
}
/**
 * 
 * @export
 * @interface UserGetGetUserPreferencesGet200ResponseUserPreferencesLanguages
 */
export interface UserGetGetUserPreferencesGet200ResponseUserPreferencesLanguages {
    /**
     * 
     * @type {string}
     * @memberof UserGetGetUserPreferencesGet200ResponseUserPreferencesLanguages
     */
    'displayLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserGetGetUserPreferencesGet200ResponseUserPreferencesLanguages
     */
    'knownLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserGetGetUserPreferencesGet200ResponseUserPreferencesLanguages
     */
    'studyLanguage'?: string;
}
/**
 * 
 * @export
 * @interface UserPersonalDictionariesInner
 */
export interface UserPersonalDictionariesInner {
    /**
     * 
     * @type {string}
     * @memberof UserPersonalDictionariesInner
     */
    'language'?: string;
}
/**
 * 
 * @export
 * @interface UserPostLoginPost200Response
 */
export interface UserPostLoginPost200Response {
    /**
     * 
     * @type {string}
     * @memberof UserPostLoginPost200Response
     */
    'message'?: string;
    /**
     * 
     * @type {UserPostLoginPost200ResponseToken}
     * @memberof UserPostLoginPost200Response
     */
    'token'?: UserPostLoginPost200ResponseToken;
}
/**
 * 
 * @export
 * @interface UserPostLoginPost200ResponseToken
 */
export interface UserPostLoginPost200ResponseToken {
    /**
     * 
     * @type {string}
     * @memberof UserPostLoginPost200ResponseToken
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPostLoginPost200ResponseToken
     */
    'accessToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPostLoginPost200ResponseToken
     */
    'refreshToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPostLoginPost200ResponseToken
     */
    '_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserPostLoginPost200ResponseToken
     */
    '__v'?: number;
}
/**
 * 
 * @export
 * @interface UserPostLoginPost400Response
 */
export interface UserPostLoginPost400Response {
    /**
     * 
     * @type {string}
     * @memberof UserPostLoginPost400Response
     */
    'message'?: string;
    /**
     * 
     * @type {UserPostLoginPost400ResponseError}
     * @memberof UserPostLoginPost400Response
     */
    'error'?: UserPostLoginPost400ResponseError;
}
/**
 * 
 * @export
 * @interface UserPostLoginPost400ResponseError
 */
export interface UserPostLoginPost400ResponseError {
    /**
     * 
     * @type {string}
     * @memberof UserPostLoginPost400ResponseError
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPostLoginPost400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPostLoginPost400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UserPostLoginPost404Response
 */
export interface UserPostLoginPost404Response {
    /**
     * 
     * @type {string}
     * @memberof UserPostLoginPost404Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface UserPostLoginPostRequest
 */
export interface UserPostLoginPostRequest {
    /**
     * 
     * @type {string}
     * @memberof UserPostLoginPostRequest
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPostLoginPostRequest
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface UserPostRegisterPost200Response
 */
export interface UserPostRegisterPost200Response {
    /**
     * 
     * @type {string}
     * @memberof UserPostRegisterPost200Response
     */
    'message'?: string;
    /**
     * 
     * @type {UserPostRegisterPost200ResponseUser}
     * @memberof UserPostRegisterPost200Response
     */
    'user'?: UserPostRegisterPost200ResponseUser;
}
/**
 * 
 * @export
 * @interface UserPostRegisterPost200ResponseUser
 */
export interface UserPostRegisterPost200ResponseUser {
    /**
     * 
     * @type {string}
     * @memberof UserPostRegisterPost200ResponseUser
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPostRegisterPost200ResponseUser
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPostRegisterPost200ResponseUser
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPostRegisterPost200ResponseUser
     */
    'username'?: string;
    /**
     * 
     * @type {UserGetGetUserPreferencesGet200ResponseUserPreferences}
     * @memberof UserPostRegisterPost200ResponseUser
     */
    'preferences'?: UserGetGetUserPreferencesGet200ResponseUserPreferences;
    /**
     * 
     * @type {string}
     * @memberof UserPostRegisterPost200ResponseUser
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPostRegisterPost200ResponseUser
     */
    'knownDictionary'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserPostRegisterPost200ResponseUser
     */
    '__v'?: number;
}
/**
 * 
 * @export
 * @interface UserPostRegisterPost400Response
 */
export interface UserPostRegisterPost400Response {
    /**
     * 
     * @type {string}
     * @memberof UserPostRegisterPost400Response
     */
    'message'?: string;
    /**
     * 
     * @type {UserPostRegisterPost400ResponseError}
     * @memberof UserPostRegisterPost400Response
     */
    'error'?: UserPostRegisterPost400ResponseError;
}
/**
 * 
 * @export
 * @interface UserPostRegisterPost400ResponseError
 */
export interface UserPostRegisterPost400ResponseError {
    /**
     * 
     * @type {number}
     * @memberof UserPostRegisterPost400ResponseError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserPostRegisterPost400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPostRegisterPost400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UserPostRegisterPostRequest
 */
export interface UserPostRegisterPostRequest {
    /**
     * 
     * @type {string}
     * @memberof UserPostRegisterPostRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPostRegisterPostRequest
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPostRegisterPostRequest
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPostRegisterPostRequest
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface UserPreferences
 */
export interface UserPreferences {
    /**
     * 
     * @type {UserPreferencesLanguages}
     * @memberof UserPreferences
     */
    'languages'?: UserPreferencesLanguages;
    /**
     * 
     * @type {string}
     * @memberof UserPreferences
     */
    'defaultScreen'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreferences
     */
    '_id'?: string;
}
/**
 * 
 * @export
 * @interface UserPreferencesLanguages
 */
export interface UserPreferencesLanguages {
    /**
     * 
     * @type {string}
     * @memberof UserPreferencesLanguages
     */
    'displayLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreferencesLanguages
     */
    'knownLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreferencesLanguages
     */
    'studyLanguage'?: string;
}
/**
 * 
 * @export
 * @interface UserPutSetUserPreferencesPut200Response
 */
export interface UserPutSetUserPreferencesPut200Response {
    /**
     * 
     * @type {string}
     * @memberof UserPutSetUserPreferencesPut200Response
     */
    'message'?: string;
    /**
     * 
     * @type {UserGetGetUserPreferencesGet200ResponseUserPreferences}
     * @memberof UserPutSetUserPreferencesPut200Response
     */
    'userPreferences'?: UserGetGetUserPreferencesGet200ResponseUserPreferences;
}
/**
 * 
 * @export
 * @interface UserPutSetUserPreferencesPutRequest
 */
export interface UserPutSetUserPreferencesPutRequest {
    /**
     * 
     * @type {string}
     * @memberof UserPutSetUserPreferencesPutRequest
     */
    'displayLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPutSetUserPreferencesPutRequest
     */
    'knownLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPutSetUserPreferencesPutRequest
     */
    'studyLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPutSetUserPreferencesPutRequest
     */
    'defaultScreen'?: string;
}

/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary /user/delete/logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDeleteLogoutDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/delete/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /user/get/getPersonalDictionaryId
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetGetPersonalDictionaryIdGet: async (language?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/get/getPersonalDictionaryId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /user/get/getUserPreferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetGetUserPreferencesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/get/getUserPreferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /user/post/login
         * @param {UserPostLoginPostRequest} userPostLoginPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPostLoginPost: async (userPostLoginPostRequest: UserPostLoginPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userPostLoginPostRequest' is not null or undefined
            assertParamExists('userPostLoginPost', 'userPostLoginPostRequest', userPostLoginPostRequest)
            const localVarPath = `/user/post/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPostLoginPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /user/post/register
         * @param {UserPostRegisterPostRequest} userPostRegisterPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPostRegisterPost: async (userPostRegisterPostRequest: UserPostRegisterPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userPostRegisterPostRequest' is not null or undefined
            assertParamExists('userPostRegisterPost', 'userPostRegisterPostRequest', userPostRegisterPostRequest)
            const localVarPath = `/user/post/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPostRegisterPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /user/put/setUserPreferences
         * @param {UserPutSetUserPreferencesPutRequest} userPutSetUserPreferencesPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPutSetUserPreferencesPut: async (userPutSetUserPreferencesPutRequest: UserPutSetUserPreferencesPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userPutSetUserPreferencesPutRequest' is not null or undefined
            assertParamExists('userPutSetUserPreferencesPut', 'userPutSetUserPreferencesPutRequest', userPutSetUserPreferencesPutRequest)
            const localVarPath = `/user/put/setUserPreferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPutSetUserPreferencesPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary /user/delete/logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userDeleteLogoutDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDeleteLogoutDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userDeleteLogoutDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /user/get/getPersonalDictionaryId
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetGetPersonalDictionaryIdGet(language?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGetGetPersonalDictionaryIdGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetGetPersonalDictionaryIdGet(language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /user/get/getUserPreferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetGetUserPreferencesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGetGetUserPreferencesGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetGetUserPreferencesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /user/post/login
         * @param {UserPostLoginPostRequest} userPostLoginPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPostLoginPost(userPostLoginPostRequest: UserPostLoginPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPostLoginPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPostLoginPost(userPostLoginPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /user/post/register
         * @param {UserPostRegisterPostRequest} userPostRegisterPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPostRegisterPost(userPostRegisterPostRequest: UserPostRegisterPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPostRegisterPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPostRegisterPost(userPostRegisterPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /user/put/setUserPreferences
         * @param {UserPutSetUserPreferencesPutRequest} userPutSetUserPreferencesPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPutSetUserPreferencesPut(userPutSetUserPreferencesPutRequest: UserPutSetUserPreferencesPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPutSetUserPreferencesPut200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPutSetUserPreferencesPut(userPutSetUserPreferencesPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary /user/delete/logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDeleteLogoutDelete(options?: any): AxiosPromise<UserDeleteLogoutDelete200Response> {
            return localVarFp.userDeleteLogoutDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /user/get/getPersonalDictionaryId
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetGetPersonalDictionaryIdGet(language?: string, options?: any): AxiosPromise<UserGetGetPersonalDictionaryIdGet200Response> {
            return localVarFp.userGetGetPersonalDictionaryIdGet(language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /user/get/getUserPreferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetGetUserPreferencesGet(options?: any): AxiosPromise<UserGetGetUserPreferencesGet200Response> {
            return localVarFp.userGetGetUserPreferencesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /user/post/login
         * @param {UserPostLoginPostRequest} userPostLoginPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPostLoginPost(userPostLoginPostRequest: UserPostLoginPostRequest, options?: any): AxiosPromise<UserPostLoginPost200Response> {
            return localVarFp.userPostLoginPost(userPostLoginPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /user/post/register
         * @param {UserPostRegisterPostRequest} userPostRegisterPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPostRegisterPost(userPostRegisterPostRequest: UserPostRegisterPostRequest, options?: any): AxiosPromise<UserPostRegisterPost200Response> {
            return localVarFp.userPostRegisterPost(userPostRegisterPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /user/put/setUserPreferences
         * @param {UserPutSetUserPreferencesPutRequest} userPutSetUserPreferencesPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPutSetUserPreferencesPut(userPutSetUserPreferencesPutRequest: UserPutSetUserPreferencesPutRequest, options?: any): AxiosPromise<UserPutSetUserPreferencesPut200Response> {
            return localVarFp.userPutSetUserPreferencesPut(userPutSetUserPreferencesPutRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary /user/delete/logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userDeleteLogoutDelete(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userDeleteLogoutDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /user/get/getPersonalDictionaryId
     * @param {string} [language] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetGetPersonalDictionaryIdGet(language?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetGetPersonalDictionaryIdGet(language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /user/get/getUserPreferences
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetGetUserPreferencesGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetGetUserPreferencesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /user/post/login
     * @param {UserPostLoginPostRequest} userPostLoginPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userPostLoginPost(userPostLoginPostRequest: UserPostLoginPostRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userPostLoginPost(userPostLoginPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /user/post/register
     * @param {UserPostRegisterPostRequest} userPostRegisterPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userPostRegisterPost(userPostRegisterPostRequest: UserPostRegisterPostRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userPostRegisterPost(userPostRegisterPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /user/put/setUserPreferences
     * @param {UserPutSetUserPreferencesPutRequest} userPutSetUserPreferencesPutRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userPutSetUserPreferencesPut(userPutSetUserPreferencesPutRequest: UserPutSetUserPreferencesPutRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userPutSetUserPreferencesPut(userPutSetUserPreferencesPutRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



