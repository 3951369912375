/* tslint:disable */
/* eslint-disable */
/**
 * reading-service
 * Specification JSONs: [v2](/api-spec/v2), [v3](/api-spec/v3).
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CategoriesGetGetAllGet200Response
 */
export interface CategoriesGetGetAllGet200Response {
    /**
     * 
     * @type {string}
     * @memberof CategoriesGetGetAllGet200Response
     */
    'message'?: string;
    /**
     * 
     * @type {Array<CategoriesGetGetAllGet200ResponseCategoriesInner>}
     * @memberof CategoriesGetGetAllGet200Response
     */
    'categories'?: Array<CategoriesGetGetAllGet200ResponseCategoriesInner>;
}
/**
 * 
 * @export
 * @interface CategoriesGetGetAllGet200ResponseCategoriesInner
 */
export interface CategoriesGetGetAllGet200ResponseCategoriesInner {
    /**
     * 
     * @type {string}
     * @memberof CategoriesGetGetAllGet200ResponseCategoriesInner
     */
    '_id': string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesGetGetAllGet200ResponseCategoriesInner
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesGetGetAllGet200ResponseCategoriesInner
     */
    'description': string;
    /**
     * 
     * @type {Array<any>}
     * @memberof CategoriesGetGetAllGet200ResponseCategoriesInner
     */
    'children': Array<any>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CategoriesGetGetAllGet200ResponseCategoriesInner
     */
    'readingItems': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CategoriesGetGetAllGet200ResponseCategoriesInner
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesGetGetAllGet200ResponseCategoriesInner
     */
    'studyLanguage': string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesGetGetAllGet200ResponseCategoriesInner
     */
    'knownLanguage': string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesGetGetAllGet200ResponseCategoriesInner
     */
    'creator': string;
    /**
     * 
     * @type {number}
     * @memberof CategoriesGetGetAllGet200ResponseCategoriesInner
     */
    '__v': number;
}
/**
 * 
 * @export
 * @interface CategoriesPostCreatePost200Response
 */
export interface CategoriesPostCreatePost200Response {
    /**
     * 
     * @type {string}
     * @memberof CategoriesPostCreatePost200Response
     */
    'message'?: string;
    /**
     * 
     * @type {CategoriesPostCreatePost200ResponseCategory}
     * @memberof CategoriesPostCreatePost200Response
     */
    'category'?: CategoriesPostCreatePost200ResponseCategory;
}
/**
 * 
 * @export
 * @interface CategoriesPostCreatePost200ResponseCategory
 */
export interface CategoriesPostCreatePost200ResponseCategory {
    /**
     * 
     * @type {string}
     * @memberof CategoriesPostCreatePost200ResponseCategory
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPostCreatePost200ResponseCategory
     */
    'description'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof CategoriesPostCreatePost200ResponseCategory
     */
    'children'?: Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof CategoriesPostCreatePost200ResponseCategory
     */
    'readingItems'?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPostCreatePost200ResponseCategory
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPostCreatePost200ResponseCategory
     */
    'studyLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPostCreatePost200ResponseCategory
     */
    'knownLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPostCreatePost200ResponseCategory
     */
    'creator'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPostCreatePost200ResponseCategory
     */
    '_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof CategoriesPostCreatePost200ResponseCategory
     */
    '__v'?: number;
}
/**
 * 
 * @export
 * @interface CategoriesPostCreatePost400Response
 */
export interface CategoriesPostCreatePost400Response {
    /**
     * 
     * @type {string}
     * @memberof CategoriesPostCreatePost400Response
     */
    'message'?: string;
    /**
     * 
     * @type {CategoriesPostCreatePost400ResponseError}
     * @memberof CategoriesPostCreatePost400Response
     */
    'error'?: CategoriesPostCreatePost400ResponseError;
}
/**
 * 
 * @export
 * @interface CategoriesPostCreatePost400ResponseError
 */
export interface CategoriesPostCreatePost400ResponseError {
    /**
     * 
     * @type {number}
     * @memberof CategoriesPostCreatePost400ResponseError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPostCreatePost400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPostCreatePost400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface CategoriesPostCreatePostRequest
 */
export interface CategoriesPostCreatePostRequest {
    /**
     * 
     * @type {string}
     * @memberof CategoriesPostCreatePostRequest
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface CategoriesPutAddReadingItemPut200Response
 */
export interface CategoriesPutAddReadingItemPut200Response {
    /**
     * 
     * @type {string}
     * @memberof CategoriesPutAddReadingItemPut200Response
     */
    'message'?: string;
    /**
     * 
     * @type {CategoriesPutAddReadingItemPut200ResponseCategory}
     * @memberof CategoriesPutAddReadingItemPut200Response
     */
    'category'?: CategoriesPutAddReadingItemPut200ResponseCategory;
}
/**
 * 
 * @export
 * @interface CategoriesPutAddReadingItemPut200ResponseCategory
 */
export interface CategoriesPutAddReadingItemPut200ResponseCategory {
    /**
     * 
     * @type {string}
     * @memberof CategoriesPutAddReadingItemPut200ResponseCategory
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPutAddReadingItemPut200ResponseCategory
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPutAddReadingItemPut200ResponseCategory
     */
    'description'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof CategoriesPutAddReadingItemPut200ResponseCategory
     */
    'children'?: Array<any>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CategoriesPutAddReadingItemPut200ResponseCategory
     */
    'readingItems'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPutAddReadingItemPut200ResponseCategory
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPutAddReadingItemPut200ResponseCategory
     */
    'studyLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPutAddReadingItemPut200ResponseCategory
     */
    'knownLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPutAddReadingItemPut200ResponseCategory
     */
    'creator'?: string;
    /**
     * 
     * @type {number}
     * @memberof CategoriesPutAddReadingItemPut200ResponseCategory
     */
    '__v'?: number;
}
/**
 * 
 * @export
 * @interface CategoriesPutAddReadingItemPut400Response
 */
export interface CategoriesPutAddReadingItemPut400Response {
    /**
     * 
     * @type {string}
     * @memberof CategoriesPutAddReadingItemPut400Response
     */
    'message'?: string;
    /**
     * 
     * @type {CategoriesPutAddReadingItemPut400ResponseError}
     * @memberof CategoriesPutAddReadingItemPut400Response
     */
    'error'?: CategoriesPutAddReadingItemPut400ResponseError;
}
/**
 * 
 * @export
 * @interface CategoriesPutAddReadingItemPut400ResponseError
 */
export interface CategoriesPutAddReadingItemPut400ResponseError {
    /**
     * 
     * @type {number}
     * @memberof CategoriesPutAddReadingItemPut400ResponseError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPutAddReadingItemPut400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPutAddReadingItemPut400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface CategoriesPutAddReadingItemPutRequest
 */
export interface CategoriesPutAddReadingItemPutRequest {
    /**
     * 
     * @type {string}
     * @memberof CategoriesPutAddReadingItemPutRequest
     */
    'categoryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoriesPutAddReadingItemPutRequest
     */
    'readingItemId'?: string;
}
/**
 * 
 * @export
 * @interface ReadingCategory
 */
export interface ReadingCategory {
    /**
     * 
     * @type {string}
     * @memberof ReadingCategory
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingCategory
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingCategory
     */
    'parent'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReadingCategory
     */
    'children'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReadingCategory
     */
    'readingItems'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ReadingCategory
     */
    'type'?: ReadingCategoryTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReadingCategory
     */
    'studyLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingCategory
     */
    'creator'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingCategory
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingCategory
     */
    'knownLanguage'?: string;
}

export const ReadingCategoryTypeEnum = {
    Default: 'default',
    Course: 'course',
    User: 'user',
    System: 'system',
    Translation: 'translation',
    Wikipedia: 'wikipedia'
} as const;

export type ReadingCategoryTypeEnum = typeof ReadingCategoryTypeEnum[keyof typeof ReadingCategoryTypeEnum];

/**
 * 
 * @export
 * @interface ReadingItem
 */
export interface ReadingItem {
    /**
     * 
     * @type {string}
     * @memberof ReadingItem
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItem
     */
    'subtitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItem
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItem
     */
    'author'?: string;
    /**
     * 
     * @type {Array<ReadingItemTextInner>}
     * @memberof ReadingItem
     */
    'text'?: Array<ReadingItemTextInner>;
    /**
     * 
     * @type {string}
     * @memberof ReadingItem
     */
    'dictionary'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItem
     */
    'checkDictionary'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItem
     */
    'creator'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItem
     */
    'studyLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItem
     */
    'type'?: ReadingItemTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReadingItem
     */
    '_id'?: string;
}

export const ReadingItemTypeEnum = {
    Default: 'default',
    Course: 'course',
    Category: 'category',
    Copy: 'copy',
    Translation: 'translation'
} as const;

export type ReadingItemTypeEnum = typeof ReadingItemTypeEnum[keyof typeof ReadingItemTypeEnum];

/**
 * 
 * @export
 * @interface ReadingItemDeleteRemoveDelete200Response
 */
export interface ReadingItemDeleteRemoveDelete200Response {
    /**
     * 
     * @type {string}
     * @memberof ReadingItemDeleteRemoveDelete200Response
     */
    'message'?: string;
    /**
     * 
     * @type {ReadingItemDeleteRemoveDelete200ResponseReadingItem}
     * @memberof ReadingItemDeleteRemoveDelete200Response
     */
    'readingItem'?: ReadingItemDeleteRemoveDelete200ResponseReadingItem;
}
/**
 * 
 * @export
 * @interface ReadingItemDeleteRemoveDelete200ResponseReadingItem
 */
export interface ReadingItemDeleteRemoveDelete200ResponseReadingItem {
    /**
     * 
     * @type {string}
     * @memberof ReadingItemDeleteRemoveDelete200ResponseReadingItem
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemDeleteRemoveDelete200ResponseReadingItem
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemDeleteRemoveDelete200ResponseReadingItem
     */
    'subtitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemDeleteRemoveDelete200ResponseReadingItem
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemDeleteRemoveDelete200ResponseReadingItem
     */
    'author'?: string;
    /**
     * 
     * @type {Array<ReadingItemGetGetGet200ResponseReadingItemTextInner>}
     * @memberof ReadingItemDeleteRemoveDelete200ResponseReadingItem
     */
    'text'?: Array<ReadingItemGetGetGet200ResponseReadingItemTextInner>;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemDeleteRemoveDelete200ResponseReadingItem
     */
    'dictionary'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemDeleteRemoveDelete200ResponseReadingItem
     */
    'creator'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemDeleteRemoveDelete200ResponseReadingItem
     */
    'studyLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemDeleteRemoveDelete200ResponseReadingItem
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReadingItemDeleteRemoveDelete200ResponseReadingItem
     */
    '__v'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemDeleteRemoveDelete200ResponseReadingItem
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface ReadingItemDeleteRemoveDelete400Response
 */
export interface ReadingItemDeleteRemoveDelete400Response {
    /**
     * 
     * @type {string}
     * @memberof ReadingItemDeleteRemoveDelete400Response
     */
    'message'?: string;
    /**
     * 
     * @type {ReadingItemDeleteRemoveDelete400ResponseError}
     * @memberof ReadingItemDeleteRemoveDelete400Response
     */
    'error'?: ReadingItemDeleteRemoveDelete400ResponseError;
}
/**
 * 
 * @export
 * @interface ReadingItemDeleteRemoveDelete400ResponseError
 */
export interface ReadingItemDeleteRemoveDelete400ResponseError {
    /**
     * 
     * @type {number}
     * @memberof ReadingItemDeleteRemoveDelete400ResponseError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemDeleteRemoveDelete400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemDeleteRemoveDelete400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ReadingItemDeleteRemoveDeleteRequest
 */
export interface ReadingItemDeleteRemoveDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof ReadingItemDeleteRemoveDeleteRequest
     */
    'readingItemId'?: string;
}
/**
 * 
 * @export
 * @interface ReadingItemGetFilterByAlphabetGet200Response
 */
export interface ReadingItemGetFilterByAlphabetGet200Response {
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetFilterByAlphabetGet200Response
     */
    'message'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReadingItemGetFilterByAlphabetGet200Response
     */
    'readingItems'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ReadingItemGetFilterByAlphabetGet400Response
 */
export interface ReadingItemGetFilterByAlphabetGet400Response {
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetFilterByAlphabetGet400Response
     */
    'message'?: string;
    /**
     * 
     * @type {ReadingItemGetFilterByAlphabetGet400ResponseError}
     * @memberof ReadingItemGetFilterByAlphabetGet400Response
     */
    'error'?: ReadingItemGetFilterByAlphabetGet400ResponseError;
}
/**
 * 
 * @export
 * @interface ReadingItemGetFilterByAlphabetGet400ResponseError
 */
export interface ReadingItemGetFilterByAlphabetGet400ResponseError {
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetFilterByAlphabetGet400ResponseError
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetFilterByAlphabetGet400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetFilterByAlphabetGet400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ReadingItemGetGetAllGet200Response
 */
export interface ReadingItemGetGetAllGet200Response {
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetAllGet200Response
     */
    'message'?: string;
    /**
     * 
     * @type {Array<ReadingItemGetGetAllGet200ResponseReadingItemsInner>}
     * @memberof ReadingItemGetGetAllGet200Response
     */
    'readingItems'?: Array<ReadingItemGetGetAllGet200ResponseReadingItemsInner>;
}
/**
 * 
 * @export
 * @interface ReadingItemGetGetAllGet200ResponseReadingItemsInner
 */
export interface ReadingItemGetGetAllGet200ResponseReadingItemsInner {
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetAllGet200ResponseReadingItemsInner
     */
    '_id': string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetAllGet200ResponseReadingItemsInner
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetAllGet200ResponseReadingItemsInner
     */
    'subtitle': string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetAllGet200ResponseReadingItemsInner
     */
    'source': string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetAllGet200ResponseReadingItemsInner
     */
    'author': string;
    /**
     * 
     * @type {Array<ReadingItemGetGetGet200ResponseReadingItemTextInner>}
     * @memberof ReadingItemGetGetAllGet200ResponseReadingItemsInner
     */
    'text': Array<ReadingItemGetGetGet200ResponseReadingItemTextInner>;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetAllGet200ResponseReadingItemsInner
     */
    'dictionary': string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetAllGet200ResponseReadingItemsInner
     */
    'creator': string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetAllGet200ResponseReadingItemsInner
     */
    'studyLanguage': string;
    /**
     * 
     * @type {number}
     * @memberof ReadingItemGetGetAllGet200ResponseReadingItemsInner
     */
    '__v': number;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetAllGet200ResponseReadingItemsInner
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetAllGet200ResponseReadingItemsInner
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface ReadingItemGetGetGet200Response
 */
export interface ReadingItemGetGetGet200Response {
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetGet200Response
     */
    'message'?: string;
    /**
     * 
     * @type {ReadingItemGetGetGet200ResponseReadingItem}
     * @memberof ReadingItemGetGetGet200Response
     */
    'readingItem'?: ReadingItemGetGetGet200ResponseReadingItem;
}
/**
 * 
 * @export
 * @interface ReadingItemGetGetGet200ResponseReadingItem
 */
export interface ReadingItemGetGetGet200ResponseReadingItem {
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetGet200ResponseReadingItem
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetGet200ResponseReadingItem
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetGet200ResponseReadingItem
     */
    'subtitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetGet200ResponseReadingItem
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetGet200ResponseReadingItem
     */
    'author'?: string;
    /**
     * 
     * @type {Array<ReadingItemGetGetGet200ResponseReadingItemTextInner>}
     * @memberof ReadingItemGetGetGet200ResponseReadingItem
     */
    'text'?: Array<ReadingItemGetGetGet200ResponseReadingItemTextInner>;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetGet200ResponseReadingItem
     */
    'dictionary'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetGet200ResponseReadingItem
     */
    'creator'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetGet200ResponseReadingItem
     */
    'studyLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetGet200ResponseReadingItem
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReadingItemGetGetGet200ResponseReadingItem
     */
    '__v'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetGet200ResponseReadingItem
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface ReadingItemGetGetGet200ResponseReadingItemTextInner
 */
export interface ReadingItemGetGetGet200ResponseReadingItemTextInner {
    /**
     * 
     * @type {number}
     * @memberof ReadingItemGetGetGet200ResponseReadingItemTextInner
     */
    'headingLevel': number;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetGet200ResponseReadingItemTextInner
     */
    'heading': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReadingItemGetGetGet200ResponseReadingItemTextInner
     */
    'content': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetGet200ResponseReadingItemTextInner
     */
    '_id': string;
}
/**
 * 
 * @export
 * @interface ReadingItemGetGetGet400Response
 */
export interface ReadingItemGetGetGet400Response {
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetGet400Response
     */
    'message'?: string;
    /**
     * 
     * @type {ReadingItemGetGetGet400ResponseError}
     * @memberof ReadingItemGetGetGet400Response
     */
    'error'?: ReadingItemGetGetGet400ResponseError;
}
/**
 * 
 * @export
 * @interface ReadingItemGetGetGet400ResponseError
 */
export interface ReadingItemGetGetGet400ResponseError {
    /**
     * 
     * @type {number}
     * @memberof ReadingItemGetGetGet400ResponseError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetGet400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetGet400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ReadingItemGetGetSomeGet200Response
 */
export interface ReadingItemGetGetSomeGet200Response {
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetSomeGet200Response
     */
    'message'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof ReadingItemGetGetSomeGet200Response
     */
    'readingItems'?: Array<any>;
}
/**
 * 
 * @export
 * @interface ReadingItemGetGetSomeGet400Response
 */
export interface ReadingItemGetGetSomeGet400Response {
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetSomeGet400Response
     */
    'message'?: string;
    /**
     * 
     * @type {ReadingItemGetGetSomeGet400ResponseError}
     * @memberof ReadingItemGetGetSomeGet400Response
     */
    'error'?: ReadingItemGetGetSomeGet400ResponseError;
}
/**
 * 
 * @export
 * @interface ReadingItemGetGetSomeGet400ResponseError
 */
export interface ReadingItemGetGetSomeGet400ResponseError {
    /**
     * 
     * @type {number}
     * @memberof ReadingItemGetGetSomeGet400ResponseError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetSomeGet400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetSomeGet400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ReadingItemGetGetSomeGetRequest
 */
export interface ReadingItemGetGetSomeGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetSomeGetRequest
     */
    'readingItemIds'?: string;
}
/**
 * 
 * @export
 * @interface ReadingItemGetGetSomePost200Response
 */
export interface ReadingItemGetGetSomePost200Response {
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetSomePost200Response
     */
    'message'?: string;
    /**
     * 
     * @type {Array<ReadingItemGetGetSomePost200ResponseReadingItemsInner>}
     * @memberof ReadingItemGetGetSomePost200Response
     */
    'readingItems'?: Array<ReadingItemGetGetSomePost200ResponseReadingItemsInner>;
}
/**
 * 
 * @export
 * @interface ReadingItemGetGetSomePost200ResponseReadingItemsInner
 */
export interface ReadingItemGetGetSomePost200ResponseReadingItemsInner {
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetSomePost200ResponseReadingItemsInner
     */
    '_id': string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetSomePost200ResponseReadingItemsInner
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetSomePost200ResponseReadingItemsInner
     */
    'subtitle': string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetSomePost200ResponseReadingItemsInner
     */
    'source': string;
    /**
     * 
     * @type {Array<ReadingItemGetGetSomePost200ResponseReadingItemsInnerTextInner>}
     * @memberof ReadingItemGetGetSomePost200ResponseReadingItemsInner
     */
    'text': Array<ReadingItemGetGetSomePost200ResponseReadingItemsInnerTextInner>;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetSomePost200ResponseReadingItemsInner
     */
    'studyLanguage': string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetSomePost200ResponseReadingItemsInner
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetSomePost200ResponseReadingItemsInner
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface ReadingItemGetGetSomePost200ResponseReadingItemsInnerTextInner
 */
export interface ReadingItemGetGetSomePost200ResponseReadingItemsInnerTextInner {
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetSomePost200ResponseReadingItemsInnerTextInner
     */
    '_id': string;
    /**
     * 
     * @type {number}
     * @memberof ReadingItemGetGetSomePost200ResponseReadingItemsInnerTextInner
     */
    'headingLevel': number;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetSomePost200ResponseReadingItemsInnerTextInner
     */
    'heading': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReadingItemGetGetSomePost200ResponseReadingItemsInnerTextInner
     */
    'content': Array<string>;
}
/**
 * 
 * @export
 * @interface ReadingItemGetGetSomePost400Response
 */
export interface ReadingItemGetGetSomePost400Response {
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetSomePost400Response
     */
    'message'?: string;
    /**
     * 
     * @type {ReadingItemGetGetSomePost400ResponseError}
     * @memberof ReadingItemGetGetSomePost400Response
     */
    'error'?: ReadingItemGetGetSomePost400ResponseError;
}
/**
 * 
 * @export
 * @interface ReadingItemGetGetSomePost400ResponseError
 */
export interface ReadingItemGetGetSomePost400ResponseError {
    /**
     * 
     * @type {number}
     * @memberof ReadingItemGetGetSomePost400ResponseError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetSomePost400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetGetSomePost400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ReadingItemGetSearchGet200Response
 */
export interface ReadingItemGetSearchGet200Response {
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetSearchGet200Response
     */
    'message'?: string;
    /**
     * 
     * @type {Array<ReadingItemGetGetSomePost200ResponseReadingItemsInner>}
     * @memberof ReadingItemGetSearchGet200Response
     */
    'readingItems'?: Array<ReadingItemGetGetSomePost200ResponseReadingItemsInner>;
}
/**
 * 
 * @export
 * @interface ReadingItemGetSearchGet400Response
 */
export interface ReadingItemGetSearchGet400Response {
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetSearchGet400Response
     */
    'message'?: string;
    /**
     * 
     * @type {ReadingItemGetSearchGet400ResponseError}
     * @memberof ReadingItemGetSearchGet400Response
     */
    'error'?: ReadingItemGetSearchGet400ResponseError;
}
/**
 * 
 * @export
 * @interface ReadingItemGetSearchGet400ResponseError
 */
export interface ReadingItemGetSearchGet400ResponseError {
    /**
     * 
     * @type {number}
     * @memberof ReadingItemGetSearchGet400ResponseError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetSearchGet400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemGetSearchGet400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ReadingItemPostAddPost200Response
 */
export interface ReadingItemPostAddPost200Response {
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostAddPost200Response
     */
    'message'?: string;
    /**
     * 
     * @type {ReadingItemPostAddPost200ResponseReadingItem}
     * @memberof ReadingItemPostAddPost200Response
     */
    'readingItem'?: ReadingItemPostAddPost200ResponseReadingItem;
}
/**
 * 
 * @export
 * @interface ReadingItemPostAddPost200ResponseReadingItem
 */
export interface ReadingItemPostAddPost200ResponseReadingItem {
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostAddPost200ResponseReadingItem
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostAddPost200ResponseReadingItem
     */
    'subtitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostAddPost200ResponseReadingItem
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostAddPost200ResponseReadingItem
     */
    'author'?: string;
    /**
     * 
     * @type {Array<ReadingItemGetGetGet200ResponseReadingItemTextInner>}
     * @memberof ReadingItemPostAddPost200ResponseReadingItem
     */
    'text'?: Array<ReadingItemGetGetGet200ResponseReadingItemTextInner>;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostAddPost200ResponseReadingItem
     */
    'dictionary'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostAddPost200ResponseReadingItem
     */
    'creator'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostAddPost200ResponseReadingItem
     */
    'studyLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostAddPost200ResponseReadingItem
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostAddPost200ResponseReadingItem
     */
    '_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReadingItemPostAddPost200ResponseReadingItem
     */
    '__v'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostAddPost200ResponseReadingItem
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface ReadingItemPostAddPostRequest
 */
export interface ReadingItemPostAddPostRequest {
    /**
     * 
     * @type {Array<ReadingItemPostAddPostRequestTextInner>}
     * @memberof ReadingItemPostAddPostRequest
     */
    'text'?: Array<ReadingItemPostAddPostRequestTextInner>;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostAddPostRequest
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostAddPostRequest
     */
    'subtitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostAddPostRequest
     */
    'author'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostAddPostRequest
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostAddPostRequest
     */
    'studyLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostAddPostRequest
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface ReadingItemPostAddPostRequestTextInner
 */
export interface ReadingItemPostAddPostRequestTextInner {
    /**
     * 
     * @type {number}
     * @memberof ReadingItemPostAddPostRequestTextInner
     */
    'headingLevel': number;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostAddPostRequestTextInner
     */
    'heading': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReadingItemPostAddPostRequestTextInner
     */
    'content': Array<string>;
}
/**
 * 
 * @export
 * @interface ReadingItemPostCopyPost200Response
 */
export interface ReadingItemPostCopyPost200Response {
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostCopyPost200Response
     */
    'message'?: string;
    /**
     * 
     * @type {ReadingItemPostCopyPost200ResponseReadingItem}
     * @memberof ReadingItemPostCopyPost200Response
     */
    'readingItem'?: ReadingItemPostCopyPost200ResponseReadingItem;
}
/**
 * 
 * @export
 * @interface ReadingItemPostCopyPost200ResponseReadingItem
 */
export interface ReadingItemPostCopyPost200ResponseReadingItem {
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostCopyPost200ResponseReadingItem
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostCopyPost200ResponseReadingItem
     */
    'subtitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostCopyPost200ResponseReadingItem
     */
    'source'?: string;
    /**
     * 
     * @type {Array<ReadingItemGetGetGet200ResponseReadingItemTextInner>}
     * @memberof ReadingItemPostCopyPost200ResponseReadingItem
     */
    'text'?: Array<ReadingItemGetGetGet200ResponseReadingItemTextInner>;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostCopyPost200ResponseReadingItem
     */
    'dictionary'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostCopyPost200ResponseReadingItem
     */
    'creator'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostCopyPost200ResponseReadingItem
     */
    'studyLanguage'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostCopyPost200ResponseReadingItem
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostCopyPost200ResponseReadingItem
     */
    '_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ReadingItemPostCopyPost200ResponseReadingItem
     */
    '__v'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostCopyPost200ResponseReadingItem
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface ReadingItemPostCopyPost400Response
 */
export interface ReadingItemPostCopyPost400Response {
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostCopyPost400Response
     */
    'message'?: string;
    /**
     * 
     * @type {ReadingItemPostCopyPost400ResponseError}
     * @memberof ReadingItemPostCopyPost400Response
     */
    'error'?: ReadingItemPostCopyPost400ResponseError;
}
/**
 * 
 * @export
 * @interface ReadingItemPostCopyPost400ResponseError
 */
export interface ReadingItemPostCopyPost400ResponseError {
    /**
     * 
     * @type {number}
     * @memberof ReadingItemPostCopyPost400ResponseError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostCopyPost400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostCopyPost400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ReadingItemPostCopyPostRequest
 */
export interface ReadingItemPostCopyPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPostCopyPostRequest
     */
    'readingItemId'?: string;
}
/**
 * 
 * @export
 * @interface ReadingItemPutAddWordMeaningPut200Response
 */
export interface ReadingItemPutAddWordMeaningPut200Response {
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPutAddWordMeaningPut200Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ReadingItemPutAddWordMeaningPut400Response
 */
export interface ReadingItemPutAddWordMeaningPut400Response {
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPutAddWordMeaningPut400Response
     */
    'message'?: string;
    /**
     * 
     * @type {ReadingItemPutAddWordMeaningPut400ResponseError}
     * @memberof ReadingItemPutAddWordMeaningPut400Response
     */
    'error'?: ReadingItemPutAddWordMeaningPut400ResponseError;
}
/**
 * 
 * @export
 * @interface ReadingItemPutAddWordMeaningPut400ResponseError
 */
export interface ReadingItemPutAddWordMeaningPut400ResponseError {
    /**
     * 
     * @type {number}
     * @memberof ReadingItemPutAddWordMeaningPut400ResponseError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPutAddWordMeaningPut400ResponseError
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPutAddWordMeaningPut400ResponseError
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ReadingItemPutAddWordMeaningPutRequest
 */
export interface ReadingItemPutAddWordMeaningPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPutAddWordMeaningPutRequest
     */
    'word'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPutAddWordMeaningPutRequest
     */
    'meaning'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemPutAddWordMeaningPutRequest
     */
    'readingItemId'?: string;
}
/**
 * 
 * @export
 * @interface ReadingItemTextInner
 */
export interface ReadingItemTextInner {
    /**
     * 
     * @type {number}
     * @memberof ReadingItemTextInner
     */
    'headingLevel'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemTextInner
     */
    'heading'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReadingItemTextInner
     */
    'content'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ReadingItemTextInner
     */
    '_id'?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary /categories/get/getAll
         * @param {string} [studyLanguage] 
         * @param {string} [knownLanguage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesGetGetAllGet: async (studyLanguage?: string, knownLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/categories/get/getAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (studyLanguage !== undefined) {
                localVarQueryParameter['studyLanguage'] = studyLanguage;
            }

            if (knownLanguage !== undefined) {
                localVarQueryParameter['knownLanguage'] = knownLanguage;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /categories/post/create
         * @param {CategoriesPostCreatePostRequest} categoriesPostCreatePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesPostCreatePost: async (categoriesPostCreatePostRequest: CategoriesPostCreatePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoriesPostCreatePostRequest' is not null or undefined
            assertParamExists('categoriesPostCreatePost', 'categoriesPostCreatePostRequest', categoriesPostCreatePostRequest)
            const localVarPath = `/categories/post/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(categoriesPostCreatePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary /categories/get/getAll
         * @param {string} [studyLanguage] 
         * @param {string} [knownLanguage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesGetGetAllGet(studyLanguage?: string, knownLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoriesGetGetAllGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesGetGetAllGet(studyLanguage, knownLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /categories/post/create
         * @param {CategoriesPostCreatePostRequest} categoriesPostCreatePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesPostCreatePost(categoriesPostCreatePostRequest: CategoriesPostCreatePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoriesPostCreatePost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesPostCreatePost(categoriesPostCreatePostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary /categories/get/getAll
         * @param {string} [studyLanguage] 
         * @param {string} [knownLanguage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesGetGetAllGet(studyLanguage?: string, knownLanguage?: string, options?: any): AxiosPromise<CategoriesGetGetAllGet200Response> {
            return localVarFp.categoriesGetGetAllGet(studyLanguage, knownLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /categories/post/create
         * @param {CategoriesPostCreatePostRequest} categoriesPostCreatePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesPostCreatePost(categoriesPostCreatePostRequest: CategoriesPostCreatePostRequest, options?: any): AxiosPromise<CategoriesPostCreatePost200Response> {
            return localVarFp.categoriesPostCreatePost(categoriesPostCreatePostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary /categories/get/getAll
     * @param {string} [studyLanguage] 
     * @param {string} [knownLanguage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public categoriesGetGetAllGet(studyLanguage?: string, knownLanguage?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).categoriesGetGetAllGet(studyLanguage, knownLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /categories/post/create
     * @param {CategoriesPostCreatePostRequest} categoriesPostCreatePostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public categoriesPostCreatePost(categoriesPostCreatePostRequest: CategoriesPostCreatePostRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).categoriesPostCreatePost(categoriesPostCreatePostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReadingItemApi - axios parameter creator
 * @export
 */
export const ReadingItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary /categories/put/addReadingItem
         * @param {CategoriesPutAddReadingItemPutRequest} categoriesPutAddReadingItemPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesPutAddReadingItemPut: async (categoriesPutAddReadingItemPutRequest: CategoriesPutAddReadingItemPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoriesPutAddReadingItemPutRequest' is not null or undefined
            assertParamExists('categoriesPutAddReadingItemPut', 'categoriesPutAddReadingItemPutRequest', categoriesPutAddReadingItemPutRequest)
            const localVarPath = `/categories/put/addReadingItem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(categoriesPutAddReadingItemPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /readingItem/delete/remove
         * @param {ReadingItemDeleteRemoveDeleteRequest} readingItemDeleteRemoveDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readingItemDeleteRemoveDelete: async (readingItemDeleteRemoveDeleteRequest: ReadingItemDeleteRemoveDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readingItemDeleteRemoveDeleteRequest' is not null or undefined
            assertParamExists('readingItemDeleteRemoveDelete', 'readingItemDeleteRemoveDeleteRequest', readingItemDeleteRemoveDeleteRequest)
            const localVarPath = `/readingItem/delete/remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(readingItemDeleteRemoveDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /readingItem/get/filterByAlphabet
         * @param {string} [alphabet] 
         * @param {string} [categoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readingItemGetFilterByAlphabetGet: async (alphabet?: string, categoryId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/readingItem/get/filterByAlphabet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (alphabet !== undefined) {
                localVarQueryParameter['alphabet'] = alphabet;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /readingItem/get/getAll
         * @param {string} [studyLanguage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readingItemGetGetAllGet: async (studyLanguage?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/readingItem/get/getAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (studyLanguage !== undefined) {
                localVarQueryParameter['studyLanguage'] = studyLanguage;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /readingItem/get/get
         * @param {string} [readingItemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readingItemGetGetGet: async (readingItemId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/readingItem/get/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (readingItemId !== undefined) {
                localVarQueryParameter['readingItemId'] = readingItemId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /readingItem/get/getSome
         * @param {ReadingItemGetGetSomeGetRequest} readingItemGetGetSomeGetRequest 
         * @param {string} [readingItemIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readingItemGetGetSomeGet: async (readingItemGetGetSomeGetRequest: ReadingItemGetGetSomeGetRequest, readingItemIds?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readingItemGetGetSomeGetRequest' is not null or undefined
            assertParamExists('readingItemGetGetSomeGet', 'readingItemGetGetSomeGetRequest', readingItemGetGetSomeGetRequest)
            const localVarPath = `/readingItem/get/getSome`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (readingItemIds !== undefined) {
                localVarQueryParameter['readingItemIds'] = readingItemIds;
            }



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(readingItemGetGetSomeGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /readingItem/get/getSome
         * @param {ReadingItemGetGetSomeGetRequest} readingItemGetGetSomeGetRequest 
         * @param {string} [readingItemIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readingItemGetGetSomePost: async (readingItemGetGetSomeGetRequest: ReadingItemGetGetSomeGetRequest, readingItemIds?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readingItemGetGetSomeGetRequest' is not null or undefined
            assertParamExists('readingItemGetGetSomePost', 'readingItemGetGetSomeGetRequest', readingItemGetGetSomeGetRequest)
            const localVarPath = `/readingItem/get/getSome`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (readingItemIds !== undefined) {
                localVarQueryParameter['readingItemIds'] = readingItemIds;
            }



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(readingItemGetGetSomeGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /readingItem/get/search
         * @param {string} [searchText] 
         * @param {string} [categoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readingItemGetSearchGet: async (searchText?: string, categoryId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/readingItem/get/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /readingItem/post/add
         * @param {ReadingItemPostAddPostRequest} readingItemPostAddPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readingItemPostAddPost: async (readingItemPostAddPostRequest: ReadingItemPostAddPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readingItemPostAddPostRequest' is not null or undefined
            assertParamExists('readingItemPostAddPost', 'readingItemPostAddPostRequest', readingItemPostAddPostRequest)
            const localVarPath = `/readingItem/post/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(readingItemPostAddPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /readingItem/post/copy
         * @param {ReadingItemPostCopyPostRequest} readingItemPostCopyPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readingItemPostCopyPost: async (readingItemPostCopyPostRequest: ReadingItemPostCopyPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readingItemPostCopyPostRequest' is not null or undefined
            assertParamExists('readingItemPostCopyPost', 'readingItemPostCopyPostRequest', readingItemPostCopyPostRequest)
            const localVarPath = `/readingItem/post/copy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(readingItemPostCopyPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary /readingItem/put/addWordMeaning
         * @param {ReadingItemPutAddWordMeaningPutRequest} readingItemPutAddWordMeaningPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readingItemPutAddWordMeaningPut: async (readingItemPutAddWordMeaningPutRequest: ReadingItemPutAddWordMeaningPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'readingItemPutAddWordMeaningPutRequest' is not null or undefined
            assertParamExists('readingItemPutAddWordMeaningPut', 'readingItemPutAddWordMeaningPutRequest', readingItemPutAddWordMeaningPutRequest)
            const localVarPath = `/readingItem/put/addWordMeaning`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication authorization required
            await setApiKeyToObject(localVarHeaderParameter, "authorization", configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(readingItemPutAddWordMeaningPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReadingItemApi - functional programming interface
 * @export
 */
export const ReadingItemApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = ReadingItemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary /categories/put/addReadingItem
         * @param {CategoriesPutAddReadingItemPutRequest} categoriesPutAddReadingItemPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesPutAddReadingItemPut(categoriesPutAddReadingItemPutRequest: CategoriesPutAddReadingItemPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategoriesPutAddReadingItemPut200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesPutAddReadingItemPut(categoriesPutAddReadingItemPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /readingItem/delete/remove
         * @param {ReadingItemDeleteRemoveDeleteRequest} readingItemDeleteRemoveDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readingItemDeleteRemoveDelete(readingItemDeleteRemoveDeleteRequest: ReadingItemDeleteRemoveDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReadingItemDeleteRemoveDelete200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readingItemDeleteRemoveDelete(readingItemDeleteRemoveDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /readingItem/get/filterByAlphabet
         * @param {string} [alphabet] 
         * @param {string} [categoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readingItemGetFilterByAlphabetGet(alphabet?: string, categoryId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReadingItemGetFilterByAlphabetGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readingItemGetFilterByAlphabetGet(alphabet, categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /readingItem/get/getAll
         * @param {string} [studyLanguage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readingItemGetGetAllGet(studyLanguage?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReadingItemGetGetAllGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readingItemGetGetAllGet(studyLanguage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /readingItem/get/get
         * @param {string} [readingItemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readingItemGetGetGet(readingItemId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReadingItemGetGetGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readingItemGetGetGet(readingItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /readingItem/get/getSome
         * @param {ReadingItemGetGetSomeGetRequest} readingItemGetGetSomeGetRequest 
         * @param {string} [readingItemIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readingItemGetGetSomeGet(readingItemGetGetSomeGetRequest: ReadingItemGetGetSomeGetRequest, readingItemIds?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReadingItemGetGetSomeGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readingItemGetGetSomeGet(readingItemGetGetSomeGetRequest, readingItemIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /readingItem/get/getSome
         * @param {ReadingItemGetGetSomeGetRequest} readingItemGetGetSomeGetRequest 
         * @param {string} [readingItemIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readingItemGetGetSomePost(readingItemGetGetSomeGetRequest: ReadingItemGetGetSomeGetRequest, readingItemIds?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReadingItemGetGetSomePost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readingItemGetGetSomePost(readingItemGetGetSomeGetRequest, readingItemIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /readingItem/get/search
         * @param {string} [searchText] 
         * @param {string} [categoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readingItemGetSearchGet(searchText?: string, categoryId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReadingItemGetSearchGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readingItemGetSearchGet(searchText, categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /readingItem/post/add
         * @param {ReadingItemPostAddPostRequest} readingItemPostAddPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readingItemPostAddPost(readingItemPostAddPostRequest: ReadingItemPostAddPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReadingItemPostAddPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readingItemPostAddPost(readingItemPostAddPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /readingItem/post/copy
         * @param {ReadingItemPostCopyPostRequest} readingItemPostCopyPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readingItemPostCopyPost(readingItemPostCopyPostRequest: ReadingItemPostCopyPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReadingItemPostCopyPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readingItemPostCopyPost(readingItemPostCopyPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary /readingItem/put/addWordMeaning
         * @param {ReadingItemPutAddWordMeaningPutRequest} readingItemPutAddWordMeaningPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readingItemPutAddWordMeaningPut(readingItemPutAddWordMeaningPutRequest: ReadingItemPutAddWordMeaningPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReadingItemPutAddWordMeaningPut200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readingItemPutAddWordMeaningPut(readingItemPutAddWordMeaningPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReadingItemApi - factory interface
 * @export
 */
export const ReadingItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReadingItemApiFp(configuration)
    return {
        /**
         * 
         * @summary /categories/put/addReadingItem
         * @param {CategoriesPutAddReadingItemPutRequest} categoriesPutAddReadingItemPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesPutAddReadingItemPut(categoriesPutAddReadingItemPutRequest: CategoriesPutAddReadingItemPutRequest, options?: any): AxiosPromise<CategoriesPutAddReadingItemPut200Response> {
            return localVarFp.categoriesPutAddReadingItemPut(categoriesPutAddReadingItemPutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /readingItem/delete/remove
         * @param {ReadingItemDeleteRemoveDeleteRequest} readingItemDeleteRemoveDeleteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readingItemDeleteRemoveDelete(readingItemDeleteRemoveDeleteRequest: ReadingItemDeleteRemoveDeleteRequest, options?: any): AxiosPromise<ReadingItemDeleteRemoveDelete200Response> {
            return localVarFp.readingItemDeleteRemoveDelete(readingItemDeleteRemoveDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /readingItem/get/filterByAlphabet
         * @param {string} [alphabet] 
         * @param {string} [categoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readingItemGetFilterByAlphabetGet(alphabet?: string, categoryId?: string, options?: any): AxiosPromise<ReadingItemGetFilterByAlphabetGet200Response> {
            return localVarFp.readingItemGetFilterByAlphabetGet(alphabet, categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /readingItem/get/getAll
         * @param {string} [studyLanguage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readingItemGetGetAllGet(studyLanguage?: string, options?: any): AxiosPromise<ReadingItemGetGetAllGet200Response> {
            return localVarFp.readingItemGetGetAllGet(studyLanguage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /readingItem/get/get
         * @param {string} [readingItemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readingItemGetGetGet(readingItemId?: string, options?: any): AxiosPromise<ReadingItemGetGetGet200Response> {
            return localVarFp.readingItemGetGetGet(readingItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /readingItem/get/getSome
         * @param {ReadingItemGetGetSomeGetRequest} readingItemGetGetSomeGetRequest 
         * @param {string} [readingItemIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readingItemGetGetSomeGet(readingItemGetGetSomeGetRequest: ReadingItemGetGetSomeGetRequest, readingItemIds?: string, options?: any): AxiosPromise<ReadingItemGetGetSomeGet200Response> {
            return localVarFp.readingItemGetGetSomeGet(readingItemGetGetSomeGetRequest, readingItemIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /readingItem/get/getSome
         * @param {ReadingItemGetGetSomeGetRequest} readingItemGetGetSomeGetRequest 
         * @param {string} [readingItemIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readingItemGetGetSomePost(readingItemGetGetSomeGetRequest: ReadingItemGetGetSomeGetRequest, readingItemIds?: string, options?: any): AxiosPromise<ReadingItemGetGetSomePost200Response> {
            return localVarFp.readingItemGetGetSomePost(readingItemGetGetSomeGetRequest, readingItemIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /readingItem/get/search
         * @param {string} [searchText] 
         * @param {string} [categoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readingItemGetSearchGet(searchText?: string, categoryId?: string, options?: any): AxiosPromise<ReadingItemGetSearchGet200Response> {
            return localVarFp.readingItemGetSearchGet(searchText, categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /readingItem/post/add
         * @param {ReadingItemPostAddPostRequest} readingItemPostAddPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readingItemPostAddPost(readingItemPostAddPostRequest: ReadingItemPostAddPostRequest, options?: any): AxiosPromise<ReadingItemPostAddPost200Response> {
            return localVarFp.readingItemPostAddPost(readingItemPostAddPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /readingItem/post/copy
         * @param {ReadingItemPostCopyPostRequest} readingItemPostCopyPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readingItemPostCopyPost(readingItemPostCopyPostRequest: ReadingItemPostCopyPostRequest, options?: any): AxiosPromise<ReadingItemPostCopyPost200Response> {
            return localVarFp.readingItemPostCopyPost(readingItemPostCopyPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary /readingItem/put/addWordMeaning
         * @param {ReadingItemPutAddWordMeaningPutRequest} readingItemPutAddWordMeaningPutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readingItemPutAddWordMeaningPut(readingItemPutAddWordMeaningPutRequest: ReadingItemPutAddWordMeaningPutRequest, options?: any): AxiosPromise<ReadingItemPutAddWordMeaningPut200Response> {
            return localVarFp.readingItemPutAddWordMeaningPut(readingItemPutAddWordMeaningPutRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReadingItemApi - object-oriented interface
 * @export
 * @class ReadingItemApi
 * @extends {BaseAPI}
 */
export class ReadingItemApi extends BaseAPI {
    /**
     * 
     * @summary /categories/put/addReadingItem
     * @param {CategoriesPutAddReadingItemPutRequest} categoriesPutAddReadingItemPutRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReadingItemApi
     */
    public categoriesPutAddReadingItemPut(categoriesPutAddReadingItemPutRequest: CategoriesPutAddReadingItemPutRequest, options?: AxiosRequestConfig) {
        return ReadingItemApiFp(this.configuration).categoriesPutAddReadingItemPut(categoriesPutAddReadingItemPutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /readingItem/delete/remove
     * @param {ReadingItemDeleteRemoveDeleteRequest} readingItemDeleteRemoveDeleteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReadingItemApi
     */
    public readingItemDeleteRemoveDelete(readingItemDeleteRemoveDeleteRequest: ReadingItemDeleteRemoveDeleteRequest, options?: AxiosRequestConfig) {
        return ReadingItemApiFp(this.configuration).readingItemDeleteRemoveDelete(readingItemDeleteRemoveDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /readingItem/get/filterByAlphabet
     * @param {string} [alphabet] 
     * @param {string} [categoryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReadingItemApi
     */
    public readingItemGetFilterByAlphabetGet(alphabet?: string, categoryId?: string, options?: AxiosRequestConfig) {
        return ReadingItemApiFp(this.configuration).readingItemGetFilterByAlphabetGet(alphabet, categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /readingItem/get/getAll
     * @param {string} [studyLanguage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReadingItemApi
     */
    public readingItemGetGetAllGet(studyLanguage?: string, options?: AxiosRequestConfig) {
        return ReadingItemApiFp(this.configuration).readingItemGetGetAllGet(studyLanguage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /readingItem/get/get
     * @param {string} [readingItemId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReadingItemApi
     */
    public readingItemGetGetGet(readingItemId?: string, options?: AxiosRequestConfig) {
        return ReadingItemApiFp(this.configuration).readingItemGetGetGet(readingItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /readingItem/get/getSome
     * @param {ReadingItemGetGetSomeGetRequest} readingItemGetGetSomeGetRequest 
     * @param {string} [readingItemIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReadingItemApi
     */
    public readingItemGetGetSomeGet(readingItemGetGetSomeGetRequest: ReadingItemGetGetSomeGetRequest, readingItemIds?: string, options?: AxiosRequestConfig) {
        return ReadingItemApiFp(this.configuration).readingItemGetGetSomeGet(readingItemGetGetSomeGetRequest, readingItemIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /readingItem/get/getSome
     * @param {ReadingItemGetGetSomeGetRequest} readingItemGetGetSomeGetRequest 
     * @param {string} [readingItemIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReadingItemApi
     */
    public readingItemGetGetSomePost(readingItemGetGetSomeGetRequest: ReadingItemGetGetSomeGetRequest, readingItemIds?: string, options?: AxiosRequestConfig) {
        return ReadingItemApiFp(this.configuration).readingItemGetGetSomePost(readingItemGetGetSomeGetRequest, readingItemIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /readingItem/get/search
     * @param {string} [searchText] 
     * @param {string} [categoryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReadingItemApi
     */
    public readingItemGetSearchGet(searchText?: string, categoryId?: string, options?: AxiosRequestConfig) {
        return ReadingItemApiFp(this.configuration).readingItemGetSearchGet(searchText, categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /readingItem/post/add
     * @param {ReadingItemPostAddPostRequest} readingItemPostAddPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReadingItemApi
     */
    public readingItemPostAddPost(readingItemPostAddPostRequest: ReadingItemPostAddPostRequest, options?: AxiosRequestConfig) {
        return ReadingItemApiFp(this.configuration).readingItemPostAddPost(readingItemPostAddPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /readingItem/post/copy
     * @param {ReadingItemPostCopyPostRequest} readingItemPostCopyPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReadingItemApi
     */
    public readingItemPostCopyPost(readingItemPostCopyPostRequest: ReadingItemPostCopyPostRequest, options?: AxiosRequestConfig) {
        return ReadingItemApiFp(this.configuration).readingItemPostCopyPost(readingItemPostCopyPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary /readingItem/put/addWordMeaning
     * @param {ReadingItemPutAddWordMeaningPutRequest} readingItemPutAddWordMeaningPutRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReadingItemApi
     */
    public readingItemPutAddWordMeaningPut(readingItemPutAddWordMeaningPutRequest: ReadingItemPutAddWordMeaningPutRequest, options?: AxiosRequestConfig) {
        return ReadingItemApiFp(this.configuration).readingItemPutAddWordMeaningPut(readingItemPutAddWordMeaningPutRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



